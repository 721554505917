import React, { useState, useEffect } from "react";

import PaginationPrevious from "@/components/Pagination/PaginationPrevious.js";
import PaginationNext from "@/components/Pagination/PaginationNext.js";
import PaginationTotal from "@/components/Pagination/PaginationTotal.js";

import styles from "@/components/Pagination/Pagination.module.sass";
import { useTranslation } from "react-i18next";

/**
 * Pagination
 * @memberof Components
 * @function Pagination
 */

const Pagination = ({ setPageByNum, page, totalPages }) => {
	const { t } = useTranslation();
	const [tmpPage, setTmpPage] = useState(page);

	const setCurrentPage = val => {
		setTmpPage(val);
		setPageByNum(val);
	};

	const setPreviousPage = () => {
		const p = tmpPage > 1 ? tmpPage - 1 : tmpPage;
		setTmpPage(p);
		setPageByNum(p);
	};

	const setNextPage = () => {
		const p = tmpPage < totalPages ? tmpPage + 1 : tmpPage;
		setTmpPage(p);
		setPageByNum(p);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (tmpPage) {
			setPageByNum(tmpPage);
		}
	};

	useEffect(() => {
		setTmpPage(page);
	}, [page]);

	const isPrev = page > 1 && page <= totalPages && totalPages !== 1;
	const isNext = page >= 1 && page < totalPages;

	return (
		<div className={styles["pagination"]}>
			{isPrev && <PaginationPrevious setPreviousPage={setPreviousPage} />}
			<div className={styles["pagination__pages"]}>
				<form className={styles["pagination__form"]} onSubmit={e => handleSubmit(e)} id="paginationForm">
					<label htmlFor="paginationInput" className={styles["pagination__input-label"]}>
						{t("pagination:input")}
					</label>
					{totalPages === 1 && (
						<button
							className={`${styles["pagination__item-button--active"]} 
								${styles["pagination__item-button"]}`}
							disabled
							title={t("pagination:currentPage")}
						>
							{tmpPage}
						</button>
					)}
					{totalPages !== 1 && (
						<>
							<input
								className={styles["pagination__input"]}
								pattern="[0-9]*"
								id="paginationInput"
								value={tmpPage}
								onChange={event => {
									const val = parseInt(event.target.value);
									if (event.target.validity.valid && val <= totalPages && (val > 0 || !val)) {
										setCurrentPage(val);
									}
								}}
								onBlur={event => {
									const val = +event.target.value;
									if (val) {
										setPageByNum(val);
									} else {
										setCurrentPage(page);
									}
								}}
							/>
							<button type="submit" className={styles["pagination__button"]}>
								{t("pagination:submit")}
							</button>
						</>
					)}
				</form>
				<PaginationTotal totalPages={totalPages} currentPage={page} setPageByNum={val => setCurrentPage(val)} />
			</div>
			{isNext && <PaginationNext setNextPage={setNextPage} />}
		</div>
	);
};

export default Pagination;
