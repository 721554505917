/**
 * debounce
 * @function
 */

const debounce = (fn, wait = 1) => {
	let timeout;

	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => fn.call(this, ...args), wait);
	};
};

export default debounce;
