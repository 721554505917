import React from "react";

import { useTranslation } from "react-i18next";

import { Select, MenuItem } from "@mui/material";

import styles from "./Sort.module.sass";

/**
 * sort select
 * @memberof Components
 * @function Sort
 */

const Sort = ({ searchSort, searchSortOrder, setSort }) => {
	const { t } = useTranslation();

	const val = `${searchSort}-${searchSortOrder}`;

	const onSortChange = event => {
		setSort(...event.target.value.split("-"));
	};

	return (
		<div className={styles["sort"]}>
			<div className={styles["sort__title"]}>
				<span>{t("sort.title")}:</span>
			</div>
			<label>
				<h5>{val}</h5>
				<Select onChange={onSortChange} value={val} variant="outlined">
					{t("sort.sortOptions", { returnObjects: true }).map((item, i) => (
						<MenuItem key={i} value={item.val}>
							{item.text}
						</MenuItem>
					))}
					;
				</Select>
			</label>
		</div>
	);
};

export default Sort;
