import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";

import api from "services/api";
import url from "router/urls";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import HelpIcon from "@/components/helpIcon/HelpIcon";
import SearchMain from "@/components/Search/SearchMain";

import { MyTopicsListItem } from "./MyTopicsListItem";
import { showNotification } from "@/actions";

import styles from "./MyTopicsList.module.sass";
import useToken from "@/components/hooks/useToken";

/**
 * My topics list (private route)
 * @memberof Containers
 * @function MyTopicsList
 */

const MyTopicsList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { bearer, userId } = useToken();
	const pageSize = 20;
	const [loading, setLoading] = useState(true);
	const [topicCount, setTopicCount] = useState(0);
	const [pagesCount, setPagesCount] = useState(0);
	const [page, setPage] = useState(1);
	const [topicsList, setTopicsList] = useState([]);
	const breadcrumbs = [
		{ name: t("header:account.myAccount"), url: url.account },
		{ name: t("header:menu.topics.items.myTopics") },
	];

	const getTopicsList = () => {
		if (bearer) {
			const config = {
				headers: {
					Authorization: "Bearer " + bearer,
				},
			};
			api
				.get(`/users/${userId}/dynamic-collections?page=${page - 1}&pageSize=${pageSize}`, config)
				.then(response => {
					setLoading(false);
					if (+response.status == 204) {
						setTopicCount(0);
						setPagesCount(0);
					} else {
						const pageCount =
							response.data.totalCount % pageSize === 0
								? Math.floor(response.data.totalCount / pageSize)
								: Math.floor(response.data.totalCount / pageSize) + 1;
						setTopicCount(response.data.totalCount);
						setPagesCount(pageCount);
						setPage(page);
						setTopicsList(response.data.collections);
					}
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	const cancelSubscription = itemId => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
				Accept: "application/json-patch+json",
				"Content-Type": "application/json-patch+json",
			},
		};
		api
			.patch(
				`/users/${userId}/dynamic-collections/${itemId}`,
				[
					{
						op: "remove",
						path: "/subscriptionPeriodicity",
					},
					{
						op: "replace",
						path: "/subscribed",
						value: "false",
					},
				],
				config
			)
			.then(() => {
				setTopicsList(
					topicsList.map(item => {
						if (item.id == itemId) {
							item.subscribed = false;
							item.subscriptionPeriodicity = "";
						}
						return item;
					})
				);

				dispatch(showNotification(t("topics:subscriptionCancelledNotification")));
			})
			.catch(() => {});
	};

	useEffect(() => {
		getTopicsList();
	}, [page, bearer]);

	if (loading) return <Loader />;

	const setPageByNum = page => {
		setPage(page);
	};

	return (
		<div className={styles["topicsList"]}>
			<SearchMain className={styles["topicsList__search"]} />
			<div className={`${styles["topicsList__container"]} container`}>
				<div className={styles["topicsList__breadcrumbs"]}>
					<Breadcrumbs path={breadcrumbs} />
				</div>

				<div className={styles["topicsList__info"]}>
					<h1 className={styles["topicsList__text--title"]}>
						<div>
							<span>{t("topics:myTopics")}</span>
							<span>({topicCount})</span>
						</div>
						<HelpIcon text={t("topics:myTopicsHelp")} />
					</h1>
					<p className={styles["topicsList__info--info"]}>{t("topics:myTopicsInfo")}</p>
				</div>

				{pagesCount > 0 && (
					<div className={styles["topicsList__pagination"]}>
						<Pagination setPageByNum={setPageByNum} page={page} totalPages={pagesCount} />
					</div>
				)}

				<div className={styles["topicsList__content"]}>
					{topicsList.length ? (
						<>
							{topicsList.map((item, i) => (
								<MyTopicsListItem
									id={item.id}
									queryString={item.queryString}
									creationTimestamp={item.creationTimestamp}
									subscribed={item.subscribed}
									subscriptionPeriodicity={item.subscriptionPeriodicity}
									cancelSubscription={cancelSubscription}
									key={i}
								/>
							))}
						</>
					) : (
						<>{t("details:collections.none")}</>
					)}
				</div>
				{pagesCount > 0 && (
					<div className={styles["topicsList__pagination"]}>
						<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
					</div>
				)}
			</div>
		</div>
	);
};

export default MyTopicsList;
