import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 768,
			md: 992,
			lg: 1200,
			xl: 1536,
		},
	},
});

const mainColor = "#005CA9";
const auxiliaryColor = "#FFFFFF";
const disabledColor = "#91B9DA";
const grayedColor = "#4a4a4a";
const blackColor = "#000000";
const blueColor = "#005CA9";

function createFontFamily(fontFamily) {
	return {
		h1: { fontFamily },
		h2: { fontFamily },
		h3: { fontFamily },
		h4: { fontFamily },
		h5: { fontFamily },
		h6: { fontFamily },
		subtitle1: { fontFamily },
		subtitle2: { fontFamily },
		body1: { fontFamily },
		body2: { fontFamily },
		button: { fontFamily },
		caption: { fontFamily },
		overline: { fontFamily },
	};
}

export const theme = createTheme(defaultTheme, {
	typography: createFontFamily("Readex Pro"),
	palette: {
		action: {
			disabled: disabledColor,
		},
		primary: {
			main: mainColor,
		},
	},
	props: {},
	components: {
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: "Readex Pro",
					padding: "10px 35px",
					textDecoration: "none",
					color: mainColor,

					a: {
						"&:hover, &:focus": {
							textDecoration: "none",
						},
					},

					"&.menu-item-label-first": {
						color: grayedColor,
						pointerEvents: "none",
					},

					"&.menu-item-label": {
						marginTop: "10px",
						color: grayedColor,
						pointerEvents: "none",
					},
					"&.menu-item-label.Mui-disabled": {
						opacity: 1,
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "Readex Pro",
					textTransform: "none",
					borderRadius: "0",
					border: "0",
					minWidth: "auto",
					fontWeight: "500",
					fontSize: "1.25rem",
					lineHeight: "1.25rem",
					textDecoration: "none",
				},

				primary: {
					background: mainColor,
					color: auxiliaryColor,
					padding: "15px 50px",
					fontSize: "1.0625rem",

					[defaultTheme.breakpoints.down("md")]: {
						fontSize: "1.0625rem",
						padding: "15px 30px",
					},
					"&:hover": {
						boxShadow: "inset 0px 0px 0px 2px #005CA9",
						background: auxiliaryColor,
						color: mainColor,
					},

					"&:focus": {
						boxShadow: "inset 0px 0px 0px 2px #005CA9, 0px 0px 20px -4px #005CA9",
					},

					"&:active": {
						background: mainColor,
						color: auxiliaryColor,
						boxShadow: "0px 0px 20px -4px #005CA9",
					},
				},

				primaryInv: {
					background: auxiliaryColor,
					color: mainColor,
					padding: "15px 50px",
					fontSize: "1.0625rem",

					"&:hover": {
						boxShadow: "inset 0px 0px 0px 2px #FFFFFF",
						background: mainColor,
						color: auxiliaryColor,
					},

					"&:focus": {
						boxShadow: "inset 0px 0px 0px 2px #FFFFFF, 0px 0px 20px -4px #FFFFFF",
					},

					"&:active": {
						background: "#FFFFF",
						color: mainColor,
						boxShadow: "0px 0px 20px -4px #FFFFF",
					},
				},

				primarySizeSmall: {
					fontSize: "1rem",
					fontWeight: "400",
					padding: "10px 50px",
				},

				secondary: {
					background: auxiliaryColor,
					color: mainColor,
					boxShadow: " inset 0px 0px 0px 2px #005CA9",
					padding: "15px 50px",
					fontSize: "1.0625rem",
					"&:hover": {
						background: mainColor,
						color: auxiliaryColor,
					},

					"&:focus": {
						boxShadow: "inset 0px 0px 0px 2px #005CA9, 0px 0px 20px -4px #005CA9",
					},

					"&:active": {
						background: auxiliaryColor,
						color: mainColor,
						boxShadow: "inset 0px 0px 0px 2px #005CA9, 0px 0px 20px -4px #005CA9",
					},
				},

				secondaryInv: {
					background: mainColor,
					color: auxiliaryColor,
					boxShadow: "inset 0px 0px 0px 2px #FFFFFF",
					padding: "15px 50px",
					fontSize: "1.0625rem",

					"&:hover": {
						background: auxiliaryColor,
						color: mainColor,
					},

					"&:focus": {
						boxShadow: "inset 0px 0px 0px 2px #FFFFFF, 0px 0px 20px -4px #FFFFFF",
					},

					"&:active": {
						background: mainColor,
						color: auxiliaryColor,
						boxShadow: "inset 0px 0px 0px 2px #FFFFFF, 0px 0px 20px -4px #FFFFFF",
					},
				},

				secondarySizeLarge: {
					padding: "0",
					minWidth: "310px",
				},

				nav: {
					color: mainColor,

					[defaultTheme.breakpoints.down("md")]: {
						fontSize: "1rem",
					},

					[defaultTheme.breakpoints.down("sm")]: {
						color: blackColor,
						width: "100%",
						textAlign: "left",
						display: "flex",
						justifyContent: "space-between",
					},

					"& > i": {
						marginRight: "-10px",

						[defaultTheme.breakpoints.down("sm")]: {
							marginRight: "10px",
						},
					},

					"@global": {
						contrast: {
							color: "white",
						},
					},
				},

				navSizeSmall: {
					fontSize: "1rem",
					fontWeight: "400",

					[defaultTheme.breakpoints.down("sm")]: {
						color: mainColor,
						display: "block",
					},
				},

				hamburger: {
					position: "relative",
					width: "45px",
					height: "45px",

					"& > span": {
						display: "block",
						width: "25px",
						height: "4px",
						background: mainColor,
						position: "absolute",
						left: "10px",
						// top: 'calc(50% - 2px)',
						opacity: 1,
						transition: "all 0.3s ease-in-out",

						"&:first-child": {
							top: "calc(50% - 10px)",
						},

						"&:last-child": {
							top: "calc(50% + 6px)",
						},
					},

					"&.active": {
						"& > span": {
							opacity: 0,

							"&:first-child": {
								opacity: 1,
								transform: "rotateY(0deg) rotate(45deg)",
								top: "50%",
							},

							"&:last-child": {
								opacity: 1,
								transform: "rotateY(0deg) rotate(-45deg)",
								top: "50%",
							},
						},
					},
				},

				search: {
					background: auxiliaryColor,
					color: mainColor,
					boxShadow: "inset 0px 0px 0px 2px #FFFFFF",
					padding: "17px 35px",
					fontSize: "1.0625rem",

					"&.Mui-disabled": {
						color: disabledColor,
					},

					"&:hover": {
						boxShadow: "inset 0px 0px 0px 2px #FFFFFF",
						background: mainColor,
						color: auxiliaryColor,
					},

					"&:focus": {
						boxShadow: "inset 0px 0px 0px 1px #005CA9, 0px 0px 20px -4px #005CA9",
					},

					"&:active": {
						background: mainColor,
						color: auxiliaryColor,
						boxShadow: "0px 0px 20px -4px #005CA9",
					},
					"& > i": {
						marginLeft: "10px",
						fontSize: "1.5rem",
					},
				},

				link: {
					background: "transparent",
					color: mainColor,
					fontSize: "1rem",
					padding: 0,
					margin: 0,

					"&:hover, &:focus": {
						background: "transparent",
						textDecoration: "underline !important",
					},
				},

				linkInv: {
					background: "transparent",
					color: auxiliaryColor,
					fontSize: "1.125rem",
					padding: 0,
					marginLeft: "auto",
					textDecoration: "underline",

					"&:hover, &:focus": {
						background: "transparent",
						textDecoration: "underline",
					},
				},

				buttonLogout: {
					marginLeft: "auto",
					marginRight: "35px",
					border: `3px solid ${blueColor}`,
					padding: "15px 25px",
					display: "block",
					color: blueColor,
					marginBottom: "25px",
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: mainColor,
					fontSize: "1.25rem",
					border: "0",

					".MuiOutlinedInput-notchedOutline": {
						border: "0"
					}

				},
				outlined: {
					padding: "5px 10px",
					margin: 0,
					border: "0",
					borderRadius: 0,
					borderBottom: "1px solid #000000",
					fontSize: "1rem",
					color: blackColor,

					"&[aria-expanded=true]": {
						color: blueColor,
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					"&:before": {
						borderBottom: "0",
					}
					
				},
				input: {
					fontFamily: "Readex Pro",
					fontSize: "1.25rem",
					
				},
				outlined: {
					fontFamily: "Readex Pro",
					textTransform: "none",
					borderRadius: "0",
					minWidth: "auto",
					fontWeight: "500",
					fontSize: "1rem",
					lineHeight: "1rem",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					color: mainColor,
					backgroundColor: auxiliaryColor,
					"&.Mui-selected": {
						color: auxiliaryColor,
						backgroundColor: mainColor,
					},
				},
			},
		},
	},
});
