import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { reverse } from "named-urls";
import { Link } from "react-router-dom";

import wordpressApi from "services/wordpress";
import url from "router/urls";

import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./Collections.module.sass";
import { formatDate } from "scripts/utils";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../Modules/carousel.sass";

/**
 * Collections item
 * @memberof Components
 * @function CollectionsItem
 */

const CollectionsItem = ({ item, t, language }) => {
	return (
		<Link to={reverse(url.collections.item, { id: item.id })} className={styles["collections__item"]}>
			<div className={styles["collections__item--bottom"]}>
				<h3 className={styles["collections__text--title"]}>
					{<>{item.acf[`${language}_title`] || item.title?.rendered}</>}
				</h3>
			</div>
			<div className={styles["collections__item--tile"]}>
				<div
					className={styles["collections__item--pic"]}
					// eslint-disable-next-line no-prototype-builtins
					{...(item?._embedded?.hasOwnProperty("wp:featuredmedia") && {
						style: {
							backgroundImage: `url(${item._embedded["wp:featuredmedia"][0].source_url})`,
						},
					})}
				>
					<div className={styles["collections__item--icon"]}>
						<i className="icon-info" />
					</div>
				</div>

				<div className={styles["collections__item--active"]}>
					<h4 className={styles["collections__text--collection-name"]}>
						{<>{item.acf[`${language}_title`] || item.title?.rendered}</>}
					</h4>
					<div className={styles["collections__item--info"]}>
						<p className={styles["collections__text--collection-val"]}>
							{t("home:collections.tile.date")}: {formatDate(item.date, language)}
						</p>
						<p className={styles["collections__text--collection-val"]}>
							{t("home:collections.tile.author")}: {item._embedded?.author[0].name}
						</p>
					</div>
					<div className={styles["collections__item--content"]}>
						{
							<>
								{ReactHtmlParser(
									item.acf[`${language}_excerpt`] || item.acf[`${language}_full`] || item.excerpt?.rendered
								)}
							</>
						}
					</div>
					<div className={styles["collections__item--more"]}>
						<span className={styles["collections__text--collection-more"]}>{t("home:collections.tile.more")}</span>
					</div>
				</div>
			</div>
		</Link>
	);
};

/**
 * Collections (homepage)
 * @memberof Components
 * @function Collections
 */

const Collections = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [collectionsList, setCollectionsList] = useState([]);

	const matches = useMediaQuery("(max-width: 991.98px)");

	const getCollectionsList = () => {
		wordpressApi
			.get(`wp/v2/collections?per_page=12&_embed`)
			.then(({ data }) => {
				setLoading(false);
				setCollectionsList(data);
			})
			.catch(() => {
				setLoading(false);
				setError(true);
			});
	};

	useEffect(() => {
		getCollectionsList();
	}, []);

	const sliderSettings = {
		arrows: true,
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: collectionsList.length,
		slidesToScroll: collectionsList.length,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 2,
				},
			},
		],
	};

	if (loading) return <Loader />;
	if (error) return <Error />;

	return (
		<div className={styles["collections"]}>
			<div className={` ${styles["collections__container"]} container`}>
				<div className={styles["collections__header"]}>
					<h2 className={styles["collections__text--header"]}>{t("home:collections.title")}</h2>
					<div className={styles["collections__description"]}>
						<p className={styles["collections__text--content"]}>
							<Trans i18nKey="home:collections.description">
								Przeglądaj kolekcje Ariadny i
								<Link to="/myCollections" className={styles["collections__text--link"]}>
									twórz prywatne kolekcje
								</Link>
								aby z łatwością wracać do ulubionych obiektów
							</Trans>
						</p>
					</div>
				</div>
				{collectionsList.length ? (
					<>
						{matches ? (
							<Slider className={styles["collections__list__slider"]} {...sliderSettings}>
								{collectionsList.map(item => (
									<CollectionsItem item={item} t={t} language={language} key={item.id} />
								))}
							</Slider>
						) : (
							<div className={styles["collections__list"]}>
								{collectionsList.map(item => (
									<CollectionsItem item={item} t={t} language={language} key={item.id} />
								))}
							</div>
						)}
					</>
				) : (
					<div className={styles["collections__empty"]}>{t("home:collections.empty")}</div>
				)}
				<div className={styles["collections__footer"]}>
					<Button variant="primary" component={Link} to={url.collections.list}>
						{t("home:collections.showAll")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Collections;
