const initialState = {
	isLoggedIn: null,
	token: null,
	keycloak: null,
	email: "",
};

/**
 * Authentication reducers
 *
 * @function authentication
 * @memberof Reducers
 *
 */

export default function authentication(state = initialState, action) {
	switch (action.type) {
		case "SET_AUTH_TOKEN":
			return {
				...state,
				token: action.token,
			};
		case "SET_AUTH_ADAPTER":
			return {
				...state,
				keycloak: action.keycloak,
			};
		case "SET_AUTH_ISLOGGEDIN":
			return {
				...state,
				isLoggedIn: action.isLoggedIn,
			};
		case "SET_BEARER_TOKEN":
			return {
				...state,
				bearer: action.bearer,
			};

		default:
			return state;
	}
}
