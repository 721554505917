import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LogIn, LogOut, Register } from "@/scripts/authService";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";

import { AppContext } from "@/contexts/AppContext";

import { Button, Menu, MenuItem } from "@mui/material";
import AccordionMenu from "./AccordionMenu";

import { isWpAdminRole, isWpMinAuthorEditorRole } from "@/scripts/getRole";

import MenuDropdown from "components/menu/MenuDropdown";

import useStorageState from "scripts/useStorageState";

import url from "router/urls";
import styles from "./Header.module.sass";

import logo from "@/assets/images/logo.svg";
import logo_contrast from "@/assets/images/logo_contrast.svg";
import european_union from "@/assets/icons/svg/european-union.svg";
import WordpressMenu from "components/menu/WordpressMenu";
import wordpressApi from "services/wordpress";
import menus from "router/menus";
import useToken from "@/components/hooks/useToken";

/**
 * Language switcher
 * @memberof Components
 * @function LanguageSwitcher
 */

const LanguageSwitcher = () => {
	const { t, i18n } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		handleClose();
	};

	const languageNames = {
		pl: "Polski",
		en: "English",
		de: "Deutch",
	};

	return (
		<>
			<Button
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant="nav"
				size="small"
			>
				{t(`header:languages.${i18n.language}`)}

				<i className={open ? "icon-arrow-up" : "icon-arrow-down"} />
			</Button>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				disableScrollLock={true}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{i18n.languages.map(lang => {
					return (
						<MenuItem key={lang} onClick={() => changeLanguage(lang)}>
							{lang == i18n.language && <i className={`fw-icon-accept ${styles["language--active"]}`}></i>}
							{languageNames[`${lang}`]}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

/**
 * Header (layout)
 * @memberof Components
 * @function Header
 */

const Header = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);
	const isContrast = useSelector(state => state.app.isContrast);
	const keycloakUrl = useSelector(state => state.app.keycloakUrl);
	const matches = useMediaQuery("(max-width: 991.98px)");
	const location = useLocation();

	const currentLogo = isContrast ? logo_contrast : logo;

	const { changeFontSize, changeContrast } = useContext(AppContext);

	const url_userManagement = keycloakUrl + url.userManagement;

	const { roles } = useToken(false);

	const isAdmin = isWpAdminRole(roles);
	const isMinAuthor = isWpMinAuthorEditorRole(roles);

	const [mobileMenu, setMobileMenu] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const [wordpressMenuInformations, setWordpressMenuInformations] = useStorageState(`${language}-${menus.contact}`, {});
	const [wordpressMenuAbout, setWordpressMenuAbout] = useStorageState(`${language}-${menus.about}`, {});

	function getWordpressMenus() {
		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.contact}`)
			.then(({ data }) => {
				setWordpressMenuInformations(data);
			})
			.catch(() => {
				setWordpressMenuInformations({});
			});

		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.about}`)
			.then(({ data }) => {
				setWordpressMenuAbout(data);
			})
			.catch(() => {
				setWordpressMenuAbout({});
			});
	}

	useEffect(() => {
		getWordpressMenus();
	}, [language]);

	useEffect(() => {
		setMobileMenu(false);
	}, [location]);

	const handleLogIn = e => {
		e.preventDefault();
		LogIn();
	};

	const handleRegister = () => {
		try {
			Register();
		} catch (e) {
			console.error("Failed to register Keycloak", e);
		}
	};

	const handleLogOut = () => {
		LogOut();
	};

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const url_module_1 = process.env.REACT_APP_MODULES_BAD;
	const url_module_2 = process.env.REACT_APP_MODULES_EDU;
	const url_module_3 = process.env.REACT_APP_MODULES_WOL;

	return (
		<header className={` ${styles["header"]} ${mobileMenu && styles["header--mobile"]} `}>
			<div className={` ${styles["header__container"]} container`}>
				<div className={styles["header__wrapper"]}>
					<div className={styles["header__logo"]}>
						<Link to={"/"}>
							<img src={currentLogo} alt="Logo Ariadna" className={styles["header__logo--img"]} />
						</Link>
					</div>
					<div className={styles["header__mobile__ue"]}>
						<p>{t("header:europeanUnion")}</p>
						<img src={european_union} alt="" />
					</div>
					<div className={styles["header__content"]}>
						<nav className={styles["header__upper"]}>
							<ul className={styles["header__upper--list"]}>
								<li className={` ${styles["header__upper--item"]} ${styles["header__upper--item-fontsize"]} `}>
									<Button
										variant="nav"
										size="small"
										onClick={() => {
											changeFontSize();
										}}
									>
										{t("header:fontSize")}
									</Button>
								</li>
								<li className={` ${styles["header__upper--item"]} ${styles["header__upper--item-contrast"]} `}>
									<Button
										variant="nav"
										size="small"
										onClick={() => {
											changeContrast();
										}}
									>
										{t("header:contrast")}
									</Button>
								</li>
								<li className={styles["header__upper--item"]}>
									<LanguageSwitcher />
								</li>
								<li className={styles["header__upper--item"]}>
									<p>{t("header:europeanUnion")}</p>
									<img src={european_union} alt="" />
								</li>
							</ul>
						</nav>
						<nav className={styles["header__menu"]}>
							<ul className={styles["header__menu--list"]}>
								<li className={styles["header__menu--item"]}>
									{matches ? (
										<AccordionMenu
											isExpanded={expanded === "collections"}
											onChange={handleChange("collections")}
											title={t("header:menu.collections.title")}
										>
											{isLoggedIn && (
												<MenuItem component={Link} to={url.collections.my}>
													{t("header:menu.collections.items.myCollections")}
												</MenuItem>
											)}
											<MenuItem component={Link} to={url.collections.list}>
												{t("header:menu.collections.items.allCollections")}
											</MenuItem>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:menu.collections.title")}>
											{isLoggedIn && (
												<MenuItem component={Link} to={url.collections.my}>
													{t("header:menu.collections.items.myCollections")}
												</MenuItem>
											)}
											<MenuItem component={Link} to={url.collections.list}>
												{t("header:menu.collections.items.allCollections")}
											</MenuItem>
										</MenuDropdown>
									)}
								</li>
								<li className={styles["header__menu--item"]}>
									{matches ? (
										<AccordionMenu
											isExpanded={expanded === "topics"}
											onChange={handleChange("topics")}
											title={t("header:menu.topics.title")}
										>
											{isLoggedIn && (
												<MenuItem component={Link} to={url.topics.my}>
													{t("header:menu.topics.items.myTopics")}
												</MenuItem>
											)}
											<MenuItem component={Link} to={url.topics.all}>
												{t("header:menu.topics.items.allTopics")}
											</MenuItem>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:menu.topics.title")}>
											{isLoggedIn && (
												<MenuItem component={Link} to={url.topics.my}>
													{t("header:menu.topics.items.myTopics")}
												</MenuItem>
											)}
											<MenuItem component={Link} to={url.topics.all}>
												{t("header:menu.topics.items.allTopics")}
											</MenuItem>
										</MenuDropdown>
									)}
								</li>
								<li className={styles["header__menu--item"]}>
									{matches ? (
										<AccordionMenu
											isExpanded={expanded === "info"}
											onChange={handleChange("info")}
											title={t("header:menu.info.title")}
										>
											<MenuItem component={Link} to={url.sitemap}>
												{t("header:menu.infoHelp.items.sitemap")}
											</MenuItem>
											<WordpressMenu menu={wordpressMenuInformations}></WordpressMenu>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:menu.info.title")}>
											<MenuItem component={Link} to={url.sitemap}>
												{t("header:menu.infoHelp.items.sitemap")}
											</MenuItem>
											<WordpressMenu menu={wordpressMenuInformations}></WordpressMenu>
										</MenuDropdown>
									)}
									{/*									<MenuDropdown>
										<MenuItem classes={{root: "menu-item-label-first"}}>
											{t('header:menu.info.items.contactLabel')}
										</MenuItem>
										<MenuItem component={Link} to={reverse(url.page, { slug: slugs.contact })}>
											{t('header:menu.info.items.contact')}
										</MenuItem>
										<MenuItem classes={{root: "menu-item-label"}}>
											{t('header:menu.infoHelp.title')}
										</MenuItem>
										<MenuItem component={Link} to={reverse(url.page, { slug: slugs.dictionary })}>
											{t('header:menu.infoHelp.items.dictionary')}
										</MenuItem>
										<MenuItem classes={{root: "menu-item-label"}}>
											{t('header:menu.infoLegal.title')}
										</MenuItem>
										<MenuItem component={Link} to={reverse(url.page, { slug: slugs.privacyPolicy })}>
											{t('header:menu.infoLegal.items.privacyPolicy')}
										</MenuItem>
										<MenuItem component={Link} to={reverse(url.page, { slug: slugs.accessibilityStatement })}>
											{t('header:menu.infoLegal.items.accessibilityStatement')}
										</MenuItem>
										<MenuItem component={Link} to={reverse(url.page, { slug: slugs.regulations })}>
											{t('header:menu.infoLegal.items.regulations')}
										</MenuItem>
									</MenuDropdown>*/}
								</li>
								<li className={styles["header__menu--item"]}>
									{matches ? (
										<AccordionMenu
											isExpanded={expanded === "about"}
											onChange={handleChange("about")}
											title={t("header:menu.about.title")}
										>
											<WordpressMenu menu={wordpressMenuAbout} isAbout={true}></WordpressMenu>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:menu.about.title")}>
											<WordpressMenu menu={wordpressMenuAbout} isAbout={true}></WordpressMenu>
										</MenuDropdown>
									)}
								</li>
								<li className={styles["header__menu--item"]}>
									<Link to={url.news.list}>
										<Button variant="nav">{t("header:menu.news.title")}</Button>
									</Link>
								</li>
								<li className={styles["header__menu--item"]}>
									{matches ? (
										<AccordionMenu
											isExpanded={expanded === "modules"}
											onChange={handleChange("modules")}
											title={t("header:menu.modules.title")}
										>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_1}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.ocr")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.ocr")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_2}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.edu")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.edu")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_3}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.geohistory")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.geohistory")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:menu.modules.title")}>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_1}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.ocr")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.ocr")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_2}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.edu")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.edu")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
											<MenuItem
												component="a"
												className={styles["header__menu--item--modules"]}
												href={url_module_3}
												target="_blank"
												sx={{ display: "block" }}
											>
												<strong>{t("header:menu.modules.items.geohistory")}</strong>
												<p className={styles["header__menu--item--modules--desc"]}>
													{t("header:menu.modules.description.geohistory")}
												</p>
												<p>{t("header:menu.modules.goToModule")}</p>
											</MenuItem>
										</MenuDropdown>
									)}
								</li>

								<li className={` ${styles["header__menu--divider"]} `}>
									{isLoggedIn ? (
										matches ? (
											<AccordionMenu
												isExpanded={expanded === "loggedIn"}
												onChange={handleChange("loggedIn")}
												title={t("header:account.myAccount")}
											>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.myAccount.main")}
												</MenuItem>
												<MenuItem component={Link} to={url.account}>
													{t("header:account.myAccount")}
												</MenuItem>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.myAccount.ariadna")}
												</MenuItem>
												<MenuItem component={Link} to={url.collections.my}>
													{t("header:menu.collections.items.myCollections")}
												</MenuItem>
												<MenuItem component={Link} to={url.topics.my}>
													{t("header:menu.topics.items.myTopics")}
												</MenuItem>
												{isAdmin && (
													<MenuItem component={"a"} href={url_userManagement} target="_blank">
														{t("account.userManagement")}
													</MenuItem>
												)}
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.ocr")}
												</MenuItem>
												<MenuItem component={"a"} href={url.bad.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												{isMinAuthor && (
													<MenuItem component={"a"} href={url.bad.limits} target="_blank">
														{t("account.limitManagement")}
													</MenuItem>
												)}
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.edu")}
												</MenuItem>
												<MenuItem component={"a"} href={url.edu.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.geohistory")}
												</MenuItem>
												<MenuItem component={"a"} href={url.wol.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												<div className={styles["header__menu--item-divider"]}></div>
											</AccordionMenu>
										) : (
											<MenuDropdown title={t("header:account.myAccount")}>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.myAccount.main")}
												</MenuItem>
												<MenuItem component={Link} to={url.account}>
													{t("header:account.myAccount")}
												</MenuItem>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.myAccount.ariadna")}
												</MenuItem>
												<MenuItem component={Link} to={url.collections.my}>
													{t("header:menu.collections.items.myCollections")}
												</MenuItem>
												<MenuItem component={Link} to={url.topics.my}>
													{t("header:menu.topics.items.myTopics")}
												</MenuItem>
												{isAdmin && (
													<MenuItem component={"a"} href={url_userManagement} target="_blank">
														{t("account.userManagement")}
													</MenuItem>
												)}
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.ocr")}
												</MenuItem>
												<MenuItem component={"a"} href={url.bad.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												{isMinAuthor && (
													<MenuItem component={"a"} href={url.bad.limits} target="_blank">
														{t("account.limitManagement")}
													</MenuItem>
												)}
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.edu")}
												</MenuItem>
												<MenuItem component={"a"} href={url.edu.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												<MenuItem disabled classes={{ root: "menu-item-label" }}>
													{t("header:menu.modules.items.geohistory")}
												</MenuItem>
												<MenuItem component={"a"} href={url.wol.myProjects} target="_blank">
													{t("header:menu.myAccount.myProjects")}
												</MenuItem>
												<div className={styles["header__menu--item-divider"]}></div>
												<Button variant="buttonLogout" onClick={handleLogOut}>
													{t("header:account.logout")}
												</Button>
											</MenuDropdown>
										)
									) : matches ? (
										<AccordionMenu
											isExpanded={expanded === "notLoggedIn"}
											onChange={handleChange("notLoggedIn")}
											title={t("header:account.myAccount")}
										>
											<div className={styles["header__menu--item-account"]}>
												<h4>{t("header:account.title")}</h4>
												<p>{t("header:account.desc1")}</p>
												<p>{t("header:account.desc2")}</p>
												<div className={styles["header__menu--item-account__buttons"]}>
													<Button
														variant="link"
														href="#"
														disableFocusRipple={true}
														disableRipple={true}
														onClick={handleRegister}
													>
														{t("header:account.register")}
													</Button>
													<Button
														variant="primary"
														href="#"
														disableFocusRipple={true}
														disableRipple={true}
														onClick={handleLogIn}
													>
														{t("header:account.login")}
													</Button>
												</div>
											</div>
										</AccordionMenu>
									) : (
										<MenuDropdown title={t("header:account.login")}>
											<div className={styles["header__menu--item-account"]}>
												<h4>{t("header:account.title")}</h4>
												<p>{t("header:account.desc1")}</p>
												<p>{t("header:account.desc2")}</p>
												<div className={styles["header__menu--item-account__buttons"]}>
													<Button
														variant="link"
														href="#"
														disableFocusRipple={true}
														disableRipple={true}
														onClick={handleRegister}
													>
														{t("header:account.register")}
													</Button>
													<Button
														variant="primary"
														href="#"
														disableFocusRipple={true}
														disableRipple={true}
														onClick={handleLogIn}
													>
														{t("header:account.login")}
													</Button>
												</div>
											</div>
										</MenuDropdown>
									)}
								</li>
							</ul>
							{matches && isLoggedIn && (
								<Button variant="buttonLogout" onClick={handleLogOut} sx={{ marginTop: "35px", marginRight: "37px" }}>
									{t("header:account.logout")}
								</Button>
							)}
						</nav>
					</div>
					<div className={styles["header__mobile"]}>
						<Button
							variant="hamburger"
							className={mobileMenu && "active"}
							onClick={() => {
								setMobileMenu(!mobileMenu);
							}}
						>
							<span></span>
							<span></span>
							<span></span>
							<span style={{ display: "none" }}>menu</span>
						</Button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
