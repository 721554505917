import React from "react";
import { useTranslation } from "react-i18next";

import styles from "@/components/Pagination/Pagination.module.sass";

/**
 * Pagination list item
 * @memberof Components
 * @function PaginationListItem
 */

const PaginationListItem = ({ pageItem, currentPage, setPageByNum }) => {
	const { t } = useTranslation();
	return (
		<li className={styles["pagination__list-item"]}>
			{typeof pageItem === "number" ? (
				<button
					onClick={() => setPageByNum(pageItem)}
					className={`${styles["pagination__item-button"]} ${
						currentPage === pageItem ? styles["pagination__item-button--active"] : ""
					}`}
					title={currentPage === pageItem ? t("pagination:currentPage") : t("pagination:pageNumber")}
					disabled={currentPage === pageItem}
				>
					{pageItem}
				</button>
			) : (
				<span className={styles["pagination__item-separator"]}>{pageItem}</span>
			)}
		</li>
	);
};

export default PaginationListItem;
