import React from "react";

import Categories from "@/containers/Results/ResultsSidebar/categories/Categories.js";
import Facets from "@/containers/Results/ResultsSidebar/facets/Facets.js";
import { useTranslation } from "react-i18next";

import styles from "@/containers/Results/ResultsSidebar/Sidebar.module.sass";
import { Button } from "@mui/material";

/**
 * Results sidebar component
 * @memberof Components
 * @function Sidebar
 */

const Sidebar = ({
	// eslint-disable-next-line no-unused-vars
	searchParams,
	searchFacets,
	searchCategory,
	category,
	facets,
	// eslint-disable-next-line no-unused-vars
	count,
	// eslint-disable-next-line no-unused-vars
	links,
	setFacet,
	setMissing,
	setCategory,
	resetCategory,
	resetFacet,
	resetAllFacets,
	// eslint-disable-next-line no-unused-vars
	isFetching,
	// eslint-disable-next-line no-unused-vars
	resetQuery,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className={styles["sidebar"]}>
				{category && category.value && (
					<Categories
						searchCategory={searchCategory}
						category={category}
						setCategory={setCategory}
						resetCategory={resetCategory}
					/>
				)}

				{!!facets.length && (
					<Facets
						searchCategory={searchCategory}
						searchFacets={searchFacets}
						facets={facets}
						resetAllFacets={resetAllFacets}
						setFacet={setFacet}
						setMissing={setMissing}
						resetFacet={resetFacet}
					/>
				)}
			</div>
			{searchFacets && searchFacets.length > 0 && (
				<div className={styles["sidebar__filters"]}>
					<p>{t("results:facets.selectedFacets")}</p>

					{searchFacets.map(el => (
						<div className={styles["sidebar__filters__item"]} key={`filter-${Object.keys(el)[0]}`}>
							<p>{t(`results:facets.fields.${Object.keys(el)[0].replace(/^f./, "")}`)}</p>
							{Object.values(el)[0].map(value => (
								<div className={styles["sidebar__filters__value"]} key={value}>
									<p className={styles["sidebar__filters__value--text"]}>{value}</p>
									<Button
										variant="text"
										sx={{ fontSize: "1rem", padding: 0, paddingLeft: "15px", verticalAlign: "baseline" }}
										onClick={() => resetFacet(Object.keys(el)[0].replace(/^f./, ""))}
									>
										{t("search:remove")}
									</Button>
								</div>
							))}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Sidebar;
