import React from "react";
import { Field, reduxForm } from "redux-form";

import { useTranslation } from "react-i18next";

import FacetSearchInput from "./FacetSearchInput";

import styles from "./FacetSearch.module.sass";

/**
 * Search facet component
 * @memberof Components
 * @function FacetSearch
 */

const FacetSearch = ({ facetItem, facetName, changeFieldValue }) => {
	const { t } = useTranslation();

	return (
		<form className={styles["search-form"]}>
			<div className={styles["search-form__field"]}>
				<Field
					type="text"
					name="key"
					component={FacetSearchInput}
					// label={t('results:facets.searchFacet.label')}
					placeholder={t("results:facets.searchFacet.label")}
					facetItems={facetItem}
					changeFieldValue={changeFieldValue}
					facetName={facetName}
				/>
			</div>
		</form>
	);
};

export default reduxForm({
	enableReinitialize: true,
})(FacetSearch);
