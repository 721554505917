/**
 * 	@module router/slugs
 *	@description
 * 	Router slugs declarations
 */

const slugs = {
	accessibilityStatement: "accessibilityStatement",
	contact: "contact",
	dictionary: "dictionary",
	financialInfo: "financialInfo",
	participants: "participants",
	privacyPolicy: "privacyPolicy",
	projectInfo: "projectInfo",
	regulations: "regulations",
	sitemap: "sitemap",
	sources: "sources",
};

export default slugs;
