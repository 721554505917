import fetch from "isomorphic-fetch";

let apiAddress = process.env.REACT_APP_API;
let wpApiAddress = process.env.REACT_APP_API_WP;

/**
 * @namespace Actions
 * @description Store actions
 */

/**
 *
 * set index api
 *
 * @function setIndexApiAddress
 * @memberof Actions
 *
 */

export const setIndexApiAddress = value => {
	apiAddress = value;
};

/**
 *
 * set index wordpress api
 *
 * @function setIndexApiAddress
 * @memberof Actions
 *
 */

export const setIndexWpApiAddress = value => {
	wpApiAddress = value;
};

/**
 *
 * get index api
 *
 * @function getIndexApiAddress
 * @memberof Actions
 *
 */

const getIndexApiAddress = () => {
	return apiAddress;
};

/**
 *
 * get index wordpress api
 *
 * @function getIndexWpApiAddress
 * @memberof Actions
 *
 */

const getIndexWpApiAddress = () => {
	return wpApiAddress;
};

/**
 *
 * set current lang
 *
 * @function setNewLang
 * @memberof Actions
 *
 */

const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";
export const setNewLang = lang => ({
	type: SET_CURRENT_LANGUAGE,
	lang,
});

/**
 *
 * request load app
 *
 * @function requestLoadApp
 * @memberof Actions
 *
 */

const REQUEST_LOAD_APP = "REQUEST_LOAD_APP";
export const requestLoadApp = () => ({
	type: REQUEST_LOAD_APP,
	isLoading: true,
});

/**
 *
 * app error
 *
 * @function setAppError
 * @memberof Actions
 *
 */

const SET_APP_ERROR = "SET_APP_ERROR";
export const setAppError = err => ({
	type: SET_APP_ERROR,
	isLoading: false,
	err,
});

/**
 *
 * app error - reset
 *
 * @function resetAppError
 * @memberof Actions
 *
 */

const RESET_APP_ERROR = "RESET_APP_ERROR";
export const resetAppError = () => ({
	type: RESET_APP_ERROR,
	isLoading: false,
});

// SEARCH

/**
 *
 * set search params
 *
 * @function setSearchParams
 * @memberof Actions
 *
 */

const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const setSearchParams = params => ({
	type: SET_SEARCH_PARAMS,
	params,
});

/**
 *
 * reset search params
 *
 * @function resetSearchParams
 * @memberof Actions
 *
 */

const RESET_SEARCH_PARAMS = "RESET_SEARCH_PARAMS";
export const resetSearchParams = () => ({
	type: RESET_SEARCH_PARAMS,
});

//RESULTS

/**
 *
 * request load results
 *
 * @function requestLoadResults
 * @memberof Actions
 *
 */

const REQUEST_LOAD_RESULTS = "REQUEST_LOAD_RESULTS";
export const requestLoadResults = () => ({
	type: REQUEST_LOAD_RESULTS,
	isLoading: true,
});

/**
 *
 * receive load results
 *
 * @function receiveLoadResults
 * @memberof Actions
 *
 */

const RECEIVE_LOAD_RESULTS = "RECEIVE_LOAD_RESULTS";
export const receiveLoadResults = data => ({
	type: RECEIVE_LOAD_RESULTS,
	isLoading: false,
	data,
});

/**
 *
 * load results
 *
 * @function loadResults
 * @memberof Actions
 *
 */

export const loadResults = (endpoint, queryStr) => {
	return (dispatch, getState) => {
		dispatch(requestLoadResults());

		fetch(`${getIndexApiAddress()}${endpoint}${queryStr}`, {
			accept: "application/json",
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadResults(json));
				dispatch(setSearchParams({ ...getState().search, count: json.count }));
			})
			.catch(err => {
				dispatch(receiveLoadResults({ count: 0, documents: [], facets: {} }));
				dispatch(setAppError(err));
			});
	};
};

//INDEXES

// const REQUEST_LOAD_INDEXES = "REQUEST_LOAD_INDEXES";
// export const requestLoadIndexes = () => ({
// 	type: REQUEST_LOAD_INDEXES,
// 	isLoading: true,
// });

// const RECEIVE_LOAD_INDEXES = "RECEIVE_LOAD_INDEXES";
// export const receiveLoadIndexes = data => ({
// 	type: RECEIVE_LOAD_INDEXES,
// 	isLoading: false,
// 	data,
// });

/**
 *
 * set query params
 *
 * @function setParams
 * @memberof Actions
 *
 */

const SET_PARAMS = "SET_PARAMS";
export const setParams = params => ({
	type: SET_PARAMS,
	params,
});

// export const loadIndexes = search => {
// 	return dispatch => {
// 		dispatch(requestLoadIndexes());
// 		fetch(`${getIndexApiAddress()}/api/indexes?${search}`, {
// 			accept: "application/json",
// 		})
// 			.then(response => {
// 				if (response.status >= 200 && response.status < 300) {
// 					return response.json();
// 				}
// 				throw new Error(response.status);
// 			})
// 			.then(json => {
// 				dispatch(receiveLoadIndexes(json));
// 				dispatch(setParams());
// 			})
// 			.catch(err => {
// 				dispatch(setAppError(err));
// 			});
// 	};
// };

/**
 *
 * menu from wordpress
 *
 * @function receiveHeaderMenu
 * @memberof Actions
 *
 */

const RECEIVE_HEADER_MENU = "RECEIVE_HEADER_MENU";
const receiveHeaderMenu = (data, err) => ({
	type: RECEIVE_HEADER_MENU,
	data,
	err,
});

/**
 *
 * get header menu
 *
 * @function getHeaderMenu
 * @memberof Actions
 *
 */

export const getHeaderMenu = currentLang => {
	const getLangNum = lang => {
		switch (lang) {
			case "pl":
			default:
				return "9";
			case "en":
				return "10";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		fetch(getIndexWpApiAddress() + `/wp-json/wp-api-menus/v2/menus/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveHeaderMenu(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

/**
 *
 * selected resources from wp - receive
 *
 * @function receiveSelectedResources
 * @memberof Actions
 *
 */

const RECEIVE_SELECTED_RESOURCES = "RECEIVE_SELECTED_RESOURCES";
const receiveSelectedResources = (data, err) => ({
	type: RECEIVE_SELECTED_RESOURCES,
	data,
	err,
});

/**
 *
 * selected resources from wp - request
 *
 * @function requestSelectedResources
 * @memberof Actions
 *
 */

const REQUEST_SELECTED_RESOURCES = "REQUEST_SELECTED_RESOURCES";
const requestSelectedResources = () => ({
	type: REQUEST_SELECTED_RESOURCES,
});

/**
 *
 * get selected resources page
 *
 * @function getSelectedResourcesPage
 * @memberof Actions
 *
 */

export const getSelectedResourcesPage = currentLang => {
	const getLangNum = currentLang => {
		switch (currentLang) {
			case "pl":
			default:
				return "96";
			case "en":
				return "98";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		dispatch(requestSelectedResources());
		fetch(getIndexWpApiAddress() + `/wp-json/wp/v2/pages/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveSelectedResources(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

/**
 *
 * questionnaire from wp
 *
 * @function receiveQuestionnaire
 * @memberof Actions
 *
 */

const RECEIVE_QUESTIONNAIRE = "RECEIVE_QUESTIONNAIRE";
const receiveQuestionnaire = (data, err) => ({
	type: RECEIVE_QUESTIONNAIRE,
	data,
	err,
});

/**
 *
 * questionnaire from wp
 *
 * @function requestQuestionnaire
 * @memberof Actions
 *
 */

const REQUEST_QUESTIONNAIRE = "REQUEST_QUESTIONNAIRE";
const requestQuestionnaire = () => ({
	type: REQUEST_QUESTIONNAIRE,
});

/**
 *
 * get questionnaire link
 *
 * @function getQuestionnaireLink
 * @memberof Actions
 *
 */

export const getQuestionnaireLink = currentLang => {
	const getLangNum = currentLang => {
		switch (currentLang) {
			case "pl":
			default:
				return "149";
			case "en":
				return "154";
		}
	};

	return dispatch => {
		dispatch(resetAppError());
		dispatch(requestQuestionnaire());
		fetch(getIndexWpApiAddress() + `/wp-json/wp/v2/pages/${getLangNum(currentLang)}`)
			.then(response => {
				return response.json();
			})
			.then(json => dispatch(receiveQuestionnaire(json)))
			.catch(err => dispatch(setAppError(err)));
	};
};

/**
 *
 * suggestions
 *
 * @function receiveAdvancedSearchSuggestions
 * @memberof Actions
 *
 */

const RECEIVE_ADVANCED_SEARCH_SUGGESTIONS = "RECEIVE_ADVANCED_SEARCH_SUGGESTIONS";

const receiveAdvancedSearchSuggestions = (data, index) => ({
	type: RECEIVE_ADVANCED_SEARCH_SUGGESTIONS,
	data,
	index,
});

/**
 *
 * suggestions
 *
 * @function receiveSearchSuggestions
 * @memberof Actions
 *
 */

const RECEIVE_SEARCH_SUGGESTIONS = "RECEIVE_SEARCH_SUGGESTIONS";
const receiveSearchSuggestions = data => ({
	type: RECEIVE_SEARCH_SUGGESTIONS,
	data,
});

/**
 *
 * suggestions
 *
 * @function requestSearchSuggestions
 * @memberof Actions
 *
 */

const REQUEST_SEARCH_SUGGESTIONS = "REQUEST_SEARCH_SUGGESTIONS";
const requestSearchSuggestions = () => ({
	type: REQUEST_SEARCH_SUGGESTIONS,
});

/**
 *
 * suggestions
 *
 * @function requestAdvancedSearchSuggestions
 * @memberof Actions
 *
 */

const REQUEST_ADVANCED_SEARCH_SUGGESTIONS = "REQUEST_ADVANCED_SEARCH_SUGGESTIONS";
const requestAdvancedSearchSuggestions = () => ({
	type: REQUEST_ADVANCED_SEARCH_SUGGESTIONS,
});

/**
 *
 * suggestions
 *
 * @function resetAdvancedSearchSuggestions
 * @memberof Actions
 *
 */

const RESET_ADVANCED_SEARCH_SUGGESTIONS = "RESET_ADVANCED_SEARCH_SUGGESTIONS";
export const resetAdvancedSearchSuggestions = index => ({
	type: RESET_ADVANCED_SEARCH_SUGGESTIONS,
	index,
});

/**
 *
 * suggestions
 *
 * @function resetSearchSuggestions
 * @memberof Actions
 *
 */

const RESET_SEARCH_SUGGESTIONS = "RESET_SEARCH_SUGGESTIONS";
export const resetSearchSuggestions = () => ({
	type: RESET_SEARCH_SUGGESTIONS,
});

const suggestionsLimit = process.env.REACT_APP_SUGGESTIONS_LIMIT;

/**
 *
 * suggestions
 *
 * @function getSuggestions
 * @memberof Actions
 *
 */

export const getSuggestions = (field, prefix, index) => {
	return dispatch => {
		if (field === "primary") {
			dispatch(requestSearchSuggestions());
		} else {
			dispatch(requestAdvancedSearchSuggestions());
		}

		fetch(
			`${getIndexApiAddress()}/api/suggestions?${
				field !== "primary" ? `field=${field}&` : ""
			}limit=${suggestionsLimit}&prefix=${prefix}`
		)
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				if (field === "primary" && typeof index === "undefined") {
					dispatch(receiveSearchSuggestions(json));
				} else {
					dispatch(receiveAdvancedSearchSuggestions(json, index));
				}
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

/**
 *
 * contrast
 *
 * @function setContrast
 * @memberof Actions
 *
 */
const SET_CONTRAST = "SET_CONTRAST";
export const setContrast = isContrast => ({
	type: SET_CONTRAST,
	isContrast,
});

/**
 *
 * font size
 *
 * @function setFontSize
 * @memberof Actions
 *
 */
const SET_FONT_SIZE = "SET_FONT_SIZE";
export const setFontSize = fontSize => ({
	type: SET_FONT_SIZE,
	fontSize,
});

/**
 *
 * map
 *
 * @function requestLoadPoints
 * @memberof Actions
 *
 */
const REQUEST_LOAD_POINTS = "REQUEST_LOAD_POINTS";
export const requestLoadPoints = () => ({
	type: REQUEST_LOAD_POINTS,
	isLoading: true,
});
/**
 *
 * map
 *
 * @function receiveLoadPoints
 * @memberof Actions
 *
 */
const RECEIVE_LOAD_POINTS = "RECEIVE_LOAD_POINTS";
export const receiveLoadPoints = data => ({
	type: RECEIVE_LOAD_POINTS,
	isLoading: false,
	data,
});

/**
 *
 * load points
 *
 * @function loadPoints
 * @memberof Actions
 *
 */

export const loadPoints = queryStr => {
	return dispatch => {
		dispatch(requestLoadPoints());

		fetch(`${getIndexApiAddress()}/api/points${queryStr}`, {
			accept: "application/json",
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadPoints(json));
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

const REQUEST_LOAD_LOCATIONS = "REQUEST_LOAD_LOCATIONS";
export const requestLoadLocations = () => ({
	type: REQUEST_LOAD_LOCATIONS,
	isLoading: true,
});

const RECEIVE_LOAD_LOCATIONS = "RECEIVE_LOAD_LOCATIONS";
export const receiveLoadLocations = data => ({
	type: RECEIVE_LOAD_LOCATIONS,
	isLoading: false,
	data,
});

/**
 *
 * load locations
 *
 * @function loadLocations
 * @memberof Actions
 *
 */

export const loadLocations = queryStr => {
	return dispatch => {
		dispatch(requestLoadLocations());

		fetch(`${getIndexApiAddress()}/api/locations${queryStr}`, {
			accept: "application/json",
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveLoadLocations(json));
			})
			.catch(err => {
				dispatch(setAppError(err));
			});
	};
};

/**
 *
 * auth - adapter keycloak
 *
 * @function setAuthAdapter
 * @memberof Actions
 *
 */

const SET_AUTH_ADAPTER = "SET_AUTH_ADAPTER";
export const setAuthAdapter = keycloak => {
	return {
		type: SET_AUTH_ADAPTER,
		keycloak,
	};
};

/**
 *
 * auth - bearer token
 *
 * @function setBearerToken
 * @memberof Actions
 *
 */

const SET_BEARER_TOKEN = "SET_BEARER_TOKEN";
export const setBearerToken = bearer => {
	return {
		type: SET_BEARER_TOKEN,
		bearer,
	};
};

/**
 *
 * auth - auth token
 *
 * @function setAuthToken
 * @memberof Actions
 *
 */

const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const setAuthToken = token => {
	return {
		type: SET_AUTH_TOKEN,
		token,
	};
};

/**
 *
 * auth - is logged in
 *
 * @function setAuthIsLoggedIn
 * @memberof Actions
 *
 */

const SET_AUTH_ISLOGGEDIN = "SET_AUTH_ISLOGGEDIN";
export const setAuthIsLoggedIn = isLoggedIn => {
	return {
		type: SET_AUTH_ISLOGGEDIN,
		isLoggedIn,
	};
};

/**
 *
 * app config
 *
 * @function setConfig
 * @memberof Actions
 *
 */

const SET_APP_CONFIG = "SET_APP_CONFIG";
export const setConfig = config => ({
	type: SET_APP_CONFIG,
	config,
});

/**
 *
 * keycloak url
 *
 * @function setKeycloakUrl
 * @memberof Actions
 *
 */

const SET_KEYCLOAK_URL = "SET_KEYCLOAK_URL";
export const setKeycloakUrl = keycloakUrl => ({
	type: SET_KEYCLOAK_URL,
	keycloakUrl,
});

/**
 *
 * show notification url
 *
 * @function showNotification
 * @memberof Actions
 *
 */
const SHOW_NOTIFICATION_URL = "SHOW_NOTIFICATION";
export const showNotification = data => ({
	type: SHOW_NOTIFICATION_URL,
	data,
});
/**
 *
 * hide notification url
 *
 * @function hideNotification
 * @memberof Actions
 *
 */
const HIDE_NOTIFICATION_URL = "HIDE_NOTIFICATION";
export const hideNotification = () => ({
	type: HIDE_NOTIFICATION_URL,
});
