import parseQuery from "@/scripts/parseQuery.js";
import storage from "scripts/storage";
const pageSize = () => storage.getInt("pageSize", 25);
const initialState = {
	params: {
		q: "",
		scope: "",
		page: 1,
		pageSize: pageSize(),
	},
	"f.category": "",
	sortBy: "score",
	sortOrder: "desc",
	facets: [],
	totalPages: 1,
	suggestions: [],
	isLoadingSuggestions: false,
	parseQuery: [],
};

/**
 * Search reducers
 *
 * @function search
 * @memberof Reducers
 *
 */

export default function search(state = initialState, action) {
	switch (action.type) {
		case "SET_SEARCH_PARAMS": {
			const { params, facets, count, europeana, missing, sortBy, sortOrder } = action.params;
			const category = action.params["f.category"];
			const totalPages = count ? Math.ceil(count / state.params.pageSize) : state.totalPages;

			return {
				params: {
					...state.params,
					...params,
				},
				"f.category": category,
				sortBy: sortBy || initialState.sortBy,
				sortOrder: sortOrder || initialState.sortOrder,
				facets: facets,
				totalPages: totalPages,
				europeana: europeana,
				missing: missing,
				parseQuery: parseQuery(action.params.params.q),
			};
		}

		case "RESET_SEARCH_PARAMS":
			initialState.params.pageSize = pageSize();
			return {
				...initialState,
			};
		case "REQUEST_SEARCH_SUGGESTIONS":
			return {
				...state,
				isLoadingSuggestions: true,
			};
		case "RECEIVE_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: action.data.suggestion.allFields,
				isLoadingSuggestions: false,
			};
		case "RESET_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: [],
			};
		default:
			return state;
	}
}
