import React from "react";

import PaginationPrevious from "@/components/Pagination/PaginationPrevious.js";
import PaginationNext from "@/components/Pagination/PaginationNext.js";
import PaginationTotal from "@/components/Pagination/PaginationTotal.js";
import PaginationListItem from "@/components/Pagination/PaginationListItem.js";

import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "@/components/Pagination/Pagination.module.sass";

/**
 * Pagination list
 * @memberof Components
 * @function PaginationList
 */

const PaginationList = ({ setPageByNum, searchParams: { page }, totalPages }) => {
	const keys = [...Array(totalPages + 1).keys()];
	const matches = useMediaQuery("(max-width: 767.98px)");

	const filteredNumbers = [...keys].reduce((arr, num) => {
		if (
			num > 0 &&
			num <= totalPages &&
			(num === totalPages ||
				num === 1 ||
				(page <= 3 && num <= 5) ||
				(num >= page - 2 && num <= page + 2 && page > 3 && page < totalPages - 1) ||
				(page >= totalPages - 1 && num >= totalPages - 4))
		) {
			if (num - arr[arr.length - 1] > 1) {
				return matches ? [arr[0], "...", page, "...", num] : [...arr, "...", num];
			}
			return [...arr, num];
		}
		return arr;
	}, []);

	const setPreviousPage = () => {
		if (page > 1) {
			setPageByNum(page - 1);
		}
	};

	const setNextPage = () => {
		if (page < totalPages) {
			setPageByNum(page + 1);
		}
	};

	const isPrev = page > 1 && page <= totalPages && totalPages !== 1;
	const isNext = page >= 1 && page < totalPages;

	return (
		<div className={styles["pagination"]}>
			{isPrev && <PaginationPrevious setPreviousPage={setPreviousPage} />}
			<div className={styles["pagination__pages"]}>
				<ul className={styles["pagination__list"]}>
					{filteredNumbers.map((pageItem, index) => (
						<PaginationListItem
							setPageByNum={setPageByNum}
							pageItem={pageItem}
							currentPage={page}
							key={pageItem + index}
						/>
					))}
				</ul>
				<PaginationTotal totalPages={totalPages} currentPage={page} setPageByNum={setPageByNum} />
			</div>
			{isNext && <PaginationNext setNextPage={setNextPage} />}
		</div>
	);
};

export default PaginationList;
