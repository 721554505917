import React from "react";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import url from "router/urls";

/**
 * Map - wordpress component
 * @memberof Components
 * @function WordpressMapListItem
 */

const WordpressMapListItem = ({ menu, title, isAbout = false }) => {
	function hasChildren(prev, current) {
		return prev || !!current.child_items;
	}
	const page = isAbout ? url.about : url.page;
	let nestedMenus = false;
	if (menu?.items) {
		nestedMenus = menu.items.reduce(hasChildren, false);
	}

	return (
		<>
			{nestedMenus &&
				menu?.items &&
				menu.items.map((item, i) => (
					<li key={i}>
						{item.child_items ? (
							<ul>
								{item.title}
								{item.child_items &&
									item.child_items.map((child, j) => (
										<li key={j}>
											<Link to={reverse(page, { slug: child.slug })}>{child.title}</Link>
										</li>
									))}
							</ul>
						) : (
							<ul>
								{item.title}
								<li>
									<Link to={reverse(page, { slug: item.slug })}>{item.title}</Link>
								</li>
							</ul>
						)}
					</li>
				))}
			{!nestedMenus && menu?.items && (
				<ul>
					{title}
					{menu.items &&
						menu.items.map((child, i) => (
							<li key={i}>
								<Link to={reverse(page, { slug: child.slug })}>{child.title}</Link>
							</li>
						))}
				</ul>
			)}
		</>
	);
};
export default WordpressMapListItem;
