import React, { useEffect, useState } from "react";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { initialValues, getCurrentFacet, getCurrentFacetValue, replacerReg } from "./utils/formsService";
import debounce from "@/scripts/debounce.js";

import FacetForm from "./FacetForm/FacetForm";
import FacetFormAdvence from "./FacetForm/FacetFormAdvence";
import FacetSearch from "./FacetSearch/FacetSearch.js";
import SortingDropDown from "./utils/sortingDropdown.js";

import styles from "./Facet.module.sass";
import { usePreviousProps } from "@mui/utils";

import { useTranslation, Trans } from "react-i18next";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import HelpIcon from "@/components/helpIcon/HelpIcon";

const FacetRegular = ({
	facet,
	value,
	setFacet,
	searchFacets,
	// eslint-disable-next-line no-unused-vars
	resetFacet,
	setChosenFacet,
	values,
	location,
	change,
	isAdvenceFacetOpen,
	setIsAdvenceFacetOpen,
}) => {
	const [currentFilter, setCurrentFilter] = useState("");
	const [active, setActive] = useState(true);

	const [sortingOption, setSortingOption] = useState("default");

	const { t } = useTranslation();

	const breakpoint = useMediaQuery("(max-width: 767.98px)");

	const prevLocation = usePreviousProps(location);

	useEffect(() => {
		if (!location) return;
		if (prevLocation.search !== location.search) {
			setCurrentFilter("");
			change(`${facet}Search`, "key", "");
		}
	}, [location]);

	const submitFacetForm = value => {
		const facetValue = Object.keys(value).reduce((arr, item) => {
			const regex = new RegExp(replacerReg, "g");
			const newItem = item.replace(regex, ".");
			return value[item] ? [...arr, newItem] : arr;
		}, []);

		setFacet({ [facet]: facetValue });
	};

	// const removeFacetForm = (facetValue, facetName) => {
	// 	const currentFacet = getCurrentFacet(searchFacets, facetName);

	// 	const filteredArr = currentFacet[`f.${facetName}`].filter(item => item !== facetValue);

	// 	if (filteredArr.length) {
	// 		setFacet({ [facet]: filteredArr });
	// 	} else {
	// 		resetFacet(facet);
	// 	}
	// };

	const toggleDropdown = () => {
		setActive(prevActive => !prevActive);
	};

	const setCurrentFilterFunc = debounce(value => {
		if (currentFilter !== value) {
			setCurrentFilter(value.key);
		}
	}, 500);

	const selectAll = () => {
		const currentFacetValue = getCurrentFacetValue(currentFilter, value);

		currentFacetValue.forEach(item => {
			change(facet, item.name, true);
		});
	};

	const changeFieldValue = (form, inputName, value) => {
		change(form, inputName, value);
	};

	const resetAll = () => {
		const currentFacetValue = getCurrentFacetValue(currentFilter, value);

		currentFacetValue.forEach(item => {
			change(facet, item.name, false);
		});
	};

	const handleClickIsAdvenceFacetOpen = () => {
		setIsAdvenceFacetOpen(prev => !prev);
		setChosenFacet(null);
	};

	const currentFacet = getCurrentFacet(searchFacets, facet);
	const currentFacetValue = getCurrentFacetValue(currentFilter, value);

	const isAllSelected = values && Object.keys(values).filter(item => values[item]).length === Object.keys(value).length;
	const isAllUnselected =
		!values || !Object.keys(values).length || !Object.keys(values).filter(item => values[item]).length;

	return (
		<div className={styles["facet"]}>
			{/*** Dropdown ***/}
			{!!currentFacetValue.length && (
				<div className={styles["facet__dropdown"]}>
					{/*** <Dropdown Toggle ***/}
					{isAdvenceFacetOpen && (
						<Button
							onClick={handleClickIsAdvenceFacetOpen}
							startIcon={<ArrowLeftIcon />}
							sx={[
								{
									width: 1,
									bgcolor: "#4A4A4A",
									boxShadow: "none",
									textAlign: "left",
									justifyContent: "start",
									paddingLeft: 2.5,
									paddingTop: 2.5,
									fontSize: 16,
									color: "#FFFFFF",
									textDecorationLine: "underline",
									"&:hover": {
										bgcolor: "#4A4A4A",
										boxShadow: "none",
									},
								},
								breakpoint && {
									paddingTop: 3,
									paddingBottom: 3,
									fontSize: 18,
									textDecoration: "none",
								},
							]}
						>
							{t("results:facets.back")}
						</Button>
					)}
					<Button
						className={styles["facet__toggle"]}
						variant="contained"
						disabled={!value || !value.length}
						id="facet"
						onClick={toggleDropdown}
						endIcon={!isAdvenceFacetOpen ? active ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> : null}
						sx={[
							{
								bgcolor: "#4A4A4A",
								boxShadow: "none",
								textAlign: "left",
								justifyContent: "space-between",
								paddingLeft: 2.5,
								paddingTop: 3,
								paddingBottom: 3,
								transition: 0.5,
								fontSize: 18,
								color: "#FFFFFF",
								"&:hover": {
									bgcolor: "#4A4A4A",
									boxShadow: "none",
								},
								"&.Mui-disabled": {
									color: "#7f7f7f",
									bgcolor: "#c0c0c0",
									borderColor: "#c0c0c0",
								},
							},
							isAdvenceFacetOpen && {
								paddingTop: 1,
							},
							breakpoint && {
								bgcolor: "#727272",
								paddingTop: 3,
							},
						]}
					>
						<span className={styles["facet__toggle__text"]}>
							<span>{t(`results:facets.fields.${facet}`)}</span>
							{facet === "techObjectAccessibility" && (
								<HelpIcon
									text={
										<div className={styles["facet__toggle__text__tooltip"]}>
											<Trans i18nKey={"results:access.open"} components={{ b: <b />, br: <br /> }} />
											<br />
											<br />
											<Trans i18nKey={"results:access.closed"} components={{ b: <b />, br: <br /> }} />
										</div>
									}
								/>
							)}
						</span>
					</Button>
					{/* Dropdown Items */}
					{active && (
						<div className={styles["facet__menu"]}>
							{/* {isAdvenceFacetOpen && <div onClick={handleClickIsAdvenceFacetOpen}>{t('results:facets.back')}</div>} */}
							{isAdvenceFacetOpen && value.length >= 20 && (
								<FacetSearch
									onSubmit={setCurrentFilterFunc}
									changeFieldValue={changeFieldValue}
									facetName={facet}
									facetItem={currentFacetValue}
									form={`${facet}Search`}
								/>
							)}
							{isAdvenceFacetOpen && (
								<SortingDropDown sortingOption={sortingOption} setSortingOption={setSortingOption} />
							)}
							{!!currentFacetValue.length &&
								(!isAdvenceFacetOpen ? (
									<FacetForm
										onSubmit={submitFacetForm}
										form={facet}
										facetName={facet}
										chosenFacet={currentFacet}
										initialValues={initialValues(currentFacet, facet)}
										selectAll={selectAll}
										resetAll={resetAll}
										facetItem={currentFacetValue}
										isAllSelected={isAllSelected}
										isAllUnselected={isAllUnselected}
										setIsAdvenceFacetOpen={setIsAdvenceFacetOpen}
										setChosenFacet={setChosenFacet}
										isFacetSet={currentFacet && currentFacet[`f.${facet}`] && currentFacet[`f.${facet}`].length}
									/>
								) : (
									<FacetFormAdvence
										onSubmit={submitFacetForm}
										form={facet}
										facetName={facet}
										chosenFacet={currentFacet}
										initialValues={initialValues(currentFacet, facet)}
										selectAll={selectAll}
										resetAll={resetAll}
										facetItem={currentFacetValue}
										isAllSelected={isAllSelected}
										isAllUnselected={isAllUnselected}
										setIsAdvenceFacetOpen={setIsAdvenceFacetOpen}
										changeFieldValue={changeFieldValue}
										sortingOption={sortingOption}
										isFacetSet={currentFacet && currentFacet[`f.${facet}`] && currentFacet[`f.${facet}`].length}
									/>
								))}
							{/* {!currentFacetValue.length && (
							<div className={styles["facet__no-facet"]}>{t("results:facets.noFacets")}</div>
						)} */}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	values: getFormValues(ownProps.facet)(state),
});

export default withRouter(connect(mapStateToProps, { change })(FacetRegular));
