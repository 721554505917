import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import wordpressApi from "services/wordpress";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error.js";
import Dictionary from "@/containers/Dictionary/Dictionary";
import SearchMain from "@/components/Search/SearchMain";
import ContactForm from "@/components/ContactForm/ContactForm";

import useSessionState from "scripts/useSessionState";

import styles from "./StaticPage.module.sass";
import wordpress from "./wordpress.scss";
import moment from "moment/moment";

const DATE_FORMAT = "DD.MM.YYYY";

/**
 * Static page
 * @memberof Containers
 * @function StaticPage
 */

const StaticPage = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { slug } = useParams();

	let currentLanguage = language;
	let currentSlug = slug;

	const parts = slug.split("-");
	if (parts.length > 1) {
		currentLanguage = parts[0];
		currentSlug = parts[1];
	}

	const [loading, setLoading] = useState(true);
	const [record, setRecord] = useSessionState(`${currentLanguage}-${currentSlug}`, {});
	const [error, setError] = useState(false);

	const getSourceHtml = s => {
		if (s) {
			const options = {
				replace: ({ name, attribs, children }) => {
					if (name === "div" && Object.keys(attribs).length < 1) {
						return <>{parse.domToReact(children, options)}</>;
					}

					if (name === "a") {
						attribs["data-noicon"] = true;
						attribs["title"] = `${t("home:sources.tooltip")}: ${attribs["href"]}`;
					}
				},
			};

			return parse(s, options);
		} else if (!s) {
			return null;
		}
	};

	const getStaticPage = () => {
		setLoading(true);

		wordpressApi
			.get(`wp/v2/pages?slug=${currentLanguage}-${currentSlug}`)
			.then(({ data }) => {
				setLoading(false);
				if (data.length > 0) {
					setRecord(data[0]);
				} else {
					setLoading(true);

					if (language !== "pl") {
						wordpressApi.get(`wp/v2/pages?slug=pl-${currentLanguage}`).then(({ data }) => {
							setLoading(false);
							if (data.length > 0) {
								setRecord(data[0]);
							} else {
								setError(true);
							}
						});
					} else {
						setLoading(false);
						setError(true);
					}
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getStaticPage();
	}, [language, slug]);

	if (loading) return <Loader />;

	if (error) return <Error />;

	if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	const path = [
		{
			name: record.title?.rendered,
		},
	];

	const withoutDate = ["dictionary"];

	return (
		<div className={styles["staticPage"]}>
			<SearchMain className={styles["staticPage__search"]} />

			<div className={`${styles["staticPage__container"]} container`}>
				<div className={styles["staticPage__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["staticPage__head"]}>
					<h1 className={styles["staticPage__text--title"]}>{record.title?.rendered}</h1>
					{record.modified && !withoutDate.includes(currentSlug) && (
						<p className={styles["staticPage__text--date"]}>
							{t("results:lastEdit")}: <strong>{moment(new Date(record.modified)).format(DATE_FORMAT)}</strong>
						</p>
					)}
				</div>

				{(() => {
					switch (currentSlug) {
						case "sources":
							return (
								<div className={styles["staticPage__content"]}>
									<div className={styles["staticPage__content--sources"]}>
										{getSourceHtml(record.content?.rendered)}
									</div>
								</div>
							);

						case "dictionary":
							return (
								<div className={styles["staticPage__content"]}>
									<Dictionary content={record.content?.rendered} />
								</div>
							);

						case "contact":
							return (
								<div className={styles["staticPage__contact"]}>
									<div className={styles["staticPage__contact--left"]}>
										<ContactForm />
									</div>
									<div className={styles["staticPage__contact--right"]}>
										<div className={"wordpress"}>{ReactHtmlParser(record.content?.rendered)}</div>
									</div>
								</div>
							);

						default:
							return (
								<div className={styles["staticPage__content"]}>
									<div className={"wordpress"}>{ReactHtmlParser(record.content?.rendered)}</div>
								</div>
							);
					}
				})()}
			</div>
		</div>
	);
};

export default StaticPage;
