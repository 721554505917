import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import styles from "./Dictionary.module.sass";

import { useTranslation } from "react-i18next";

import { TextField, InputAdornment } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

/**
 * Dictionary page
 * @memberof Containers
 * @function Dictionary
 */

const Dictionary = ({ content }) => {
	const { t } = useTranslation();
	const [allEntries, setAllEntries] = useState([]);
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		const words = getWords(content);
		const groups = groupWords(getWords(content));
		setAllEntries(words);
		setEntries(groups);
	}, [content]);

	const getWords = s => {
		const regGetRows = /<tbody>(.*?)<\/tbody>/;
		const rows = s.match(regGetRows);
		let instances = [];
		if (rows) {
			const regGroups = /<td>(?<word>(?:<[^>]+>|[^<])*?)<\/td>\s*<td>(?<description>(?:<[^>]+>|[^<])*?)<\/td>/g;
			const words = [...rows[1].matchAll(regGroups)];
			instances = words.map(match => {
				return { word: match.groups.word, description: match.groups.description };
			});
		}
		return instances;
	};

	const groupWords = words => {
		let groups = [];
		const firstLetters = new Set(words.map(el => el.word[0].toUpperCase()));
		if (firstLetters) {
			groups = [...firstLetters].sort().map(letter => {
				return { [letter]: words.filter(el => el.word.toUpperCase().startsWith(letter)) };
			});
		}
		return groups;
	};

	return (
		<div className={styles["dictionary"]}>
			<TextField
				placeholder={`${t("results:findWord")}...`}
				type="search"
				variant="standard"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchOutlinedIcon />
						</InputAdornment>
					),
				}}
				sx={{
					"& input": { border: "0 !important", outline: "0 !important" },
					"& input::placeholder": { color: "#6F6F6F" },
					"&:focus-within": {
						backgroundColor: "#FFFFFF !important",
					},
					"&:hover": {
						backgroundColor: "#EFEFEF",
					},
					".MuiInput-root::before": {
						borderBottom: "2px solid #6F6F6F",
					},
					"@media (max-width: 767.98px)": {
						width: "100%",
					},
					p: "3px 10px",
					width: "430px",
					mb: "70px",
				}}
				onChange={e => {
					if (e.target.value === "") {
						setEntries(groupWords(allEntries));
					} else {
						const searchRegex = new RegExp(e.target.value, "i");
						const matchingWords = allEntries.filter(
							el => searchRegex.test(el.word) || searchRegex.test(el.description)
						);
						setEntries(groupWords(matchingWords));
					}
				}}
			/>
			<div>
				{entries.map(el => (
					<div className={styles["dictionary__letter"]} key={Object.keys(el)[0]}>
						<div className={styles["dictionary__letter__block"]}>
							<h3 className={styles["dictionary__letter__header"]}>{Object.keys(el)[0]}</h3>
						</div>
						<div className={styles["dictionary__letter__line"]}></div>
						{Object.values(el)[0].map(entry => (
							<div className={styles["dictionary__entry"]} key={entry.word}>
								<p className={styles["dictionary__entry--word"]}>{entry.word}</p>
								<p className={styles["dictionary__entry--description"]}>
									{ReactHtmlParser(entry.description.replace(/<br>/g, ""))}
								</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default Dictionary;
