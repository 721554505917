import React, { useState, useEffect } from "react";
import styles from "@/components/Search/Search.module.sass";
import { useTranslation } from "react-i18next";
import FieldWithSuggester from "@/components/Search/FieldWithSuggester";

import { Button, Stack, MenuItem, FormControl, Divider, InputLabel } from "@mui/material";

import Select from "@mui/material/Select";
import AdvancedDateField from "@/components/Search/AdvancedDateField";
import { dataScope } from "@/scripts/utils";

/**
 * Advanced search item
 * @memberof Components
 * @function AdvancedSearchItem
 */

const AdvancedSearchItem = ({ item, menuScope, onRemove, updateQuery, getAutocompleteList, suggestionsAdvanced }) => {
	const { t } = useTranslation();
	const [scope, selectScope] = useState(item.type);
	const [operator, selectOperator] = useState(item.operator);
	const [text, setText] = useState(item.text);
	const [open, setOpen] = useState(false);

	const callUpdateQuery = () => {
		if (updateQuery && updateQuery instanceof Function)
			updateQuery({ ...item, type: scope, operator: operator, text: text });
	};

	useEffect(() => {
		callUpdateQuery();
	}, [scope, operator, text]);

	const handleRemove = () => {
		onRemove(item.idx);
	};

	const handleFieldChange = event => {
		selectScope(event.target.value);
	};

	const handleOperatorChange = event => {
		selectOperator(event.target.value);
	};

	const handelSearchTexChange = value => {
		setText(value.replace(/<[^>]*>/gi, ""));
	};

	useEffect(() => {
		getAutocompleteList(scope, text, item.idx);
	}, [text, scope]);

	return (
		<Stack direction={{ xs: "column-reverse", sm: "row" }} justifyContent="space-between">
			<InputLabel htmlFor={`dropdown-${item.idx}`} id="label" style={{ display: "none" }}>
				Wybierz lub/i
			</InputLabel>
			<InputLabel htmlFor={`dropdown-basic-${item.idx}`} id="label" style={{ display: "none" }}>
				Wybierz źródło
			</InputLabel>
			<Stack
				className={`${styles["search__adv-searcharea"]} js-advSearchItem`}
				direction={{ xs: "column", sm: "row" }}
				spacing={{ xs: 0, sm: 2 }}
				divider={<Divider orientation="vertical" flexItem />}
			>
				<Select
					className="js-advSearchOperator"
					variant="standard"
					id={`dropdown-${item.idx}`}
					inputProps={{ id: `dropdown-${item.idx}` }}
					displayEmpty
					value={item.operator}
					defaultValue="and"
					sx={{
						".MuiSvgIcon-root ": {
							fill: "#005CA9",
						},
						pt: { xs: "5px", sm: "0" },
						pb: { xs: "25px", sm: "0" },
					}}
					onChange={handleOperatorChange}
					label={`dropdown-${item.idx}`}
				>
					<MenuItem value="and">{t("search:operators.and")}</MenuItem>
					<MenuItem value="or">{t("search:operators.or")}</MenuItem>
				</Select>
				<Select
					className={`${styles["search__select"]} js-advSearchType`}
					variant="standard"
					value={item.type}
					defaultValue="allFields"
					sx={{
						".MuiSvgIcon-root ": {
							fill: "#005CA9",
						},
						borderTop: { xs: "1px solid #c0c0c0", sm: "none" },
						pt: { xs: "25px", sm: "0" },
						pb: { xs: "25px", sm: "0" },
					}}
					onChange={handleFieldChange}
					id={`dropdown-basic-${item.idx}`}
					inputProps={{ id: `dropdown-basic-${item.idx}` }}
					label={`dropdown-basic-${item.idx}`}
				>
					{menuScope.map((option, index) => (
						<MenuItem key={index} value={option.id}>
							{option.label}
						</MenuItem>
					))}
				</Select>
				{dataScope.includes(scope) ? (
					<AdvancedDateField handelSearchTexChange={handelSearchTexChange} valueDate={text} />
				) : (
					<FormControl fullWidth>
						<FieldWithSuggester
							suggestionsAdvanced={suggestionsAdvanced}
							id={item.idx}
							scope={scope}
							value={text}
							handelSearchTexChange={handelSearchTexChange}
							setOpen={setOpen}
							open={open}
						/>
					</FormControl>
				)}
			</Stack>
			<Button
				variant="linkInv"
				className={styles["search__button--remove"]}
				disableRipple={true}
				disableFocusRipple={true}
				sx={{ mb: { xs: "8px", sm: "0" } }}
				onClick={handleRemove}
			>
				{t("search:remove")}
			</Button>
		</Stack>
	);
};

export default AdvancedSearchItem;
