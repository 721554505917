import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Modules.module.sass";
import "./carousel.sass";

/**
 * Modules (homepage)
 * @memberof Components
 * @function Modules
 */

const Modules = () => {
	const { t } = useTranslation();
	const url_1 = process.env.REACT_APP_MODULES_BAD;
	const url_2 = process.env.REACT_APP_MODULES_EDU;
	const url_3 = process.env.REACT_APP_MODULES_WOL;

	const sliderSettings = {
		arrows: true,
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className={styles["modules"]}>
			<h2 className={styles["modules__text--header"]}>{t("home:modules.title")}</h2>
			<div className={styles["modules__description"]}>
				<p className={styles["modules__text--content"]}>{t("home:modules.description")}</p>
			</div>

			<Slider className={styles["modules__slider"]} {...sliderSettings}>
				<div className={styles["modules__tile"]}>
					<div className={`${styles["modules__tile--content"]} ${styles["modules__tile--content_1"]}`}>
						<h3 className={styles["modules__text--title"]}>{t("home:modules.tiles.1.title")}</h3>
						<hr className={styles["modules__hr"]} />
						<p className={styles["modules__text--content"]}>{t("home:modules.tiles.1.content")}</p>
						<div className={styles["modules__more"]}>
							<a href={url_1} target="_blank" className={styles["modules__text--more"]} rel="noreferrer">
								{t("home:modules.tiles.1.button")}
							</a>
						</div>
					</div>
				</div>
				<div className={styles["modules__tile"]}>
					<div className={`${styles["modules__tile--content"]} ${styles["modules__tile--content_2"]}`}>
						<h3 className={styles["modules__text--title"]}>{t("home:modules.tiles.2.title")}</h3>
						<hr className={styles["modules__hr"]} />
						<p className={styles["modules__text--content"]}>{t("home:modules.tiles.2.content")}</p>
						<div className={styles["modules__more"]}>
							<a href={url_2} target="_blank" className={styles["modules__text--more"]} rel="noreferrer">
								{t("home:modules.tiles.2.button")}
							</a>
						</div>
					</div>
				</div>
				<div className={styles["modules__tile"]}>
					<div className={`${styles["modules__tile--content"]} ${styles["modules__tile--content_3"]}`}>
						<h3 className={styles["modules__text--title"]}>{t("home:modules.tiles.3.title")}</h3>
						<hr className={styles["modules__hr"]} />
						<p className={styles["modules__text--content"]}>{t("home:modules.tiles.3.content")}</p>
						<div className={styles["modules__more"]}>
							<a href={url_3} target="_blank" className={styles["modules__text--more"]} rel="noreferrer">
								{t("home:modules.tiles.3.button")}
							</a>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	);
};

export default Modules;
