import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

/**
 * Accordion
 * @memberof Components
 * @function AccordionMenu
 */

const AccordionMenu = ({ isExpanded, onChange, children, title }) => {
	return (
		<Accordion expanded={isExpanded} onChange={onChange} sx={{ boxShadow: 0 }}>
			<AccordionSummary
				aria-controls={`panel1d-${title}`}
				expandIcon={<i className="icon-arrow-down" />}
				sx={{
					color: "#000000",
					fontWeight: 500,
				}}
			>
				{title}
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: 0,
					"& > *": {
						paddingLeft: "16px !important",
					},
					"& li": {
						paddingLeft: "0 !important",
						marginLeft: "16px",
						marginTop: "15px !important",
					},
				}}
			>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default AccordionMenu;
