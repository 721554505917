import React from "react";
import { useSelector } from "react-redux";

import styles from "./FacetForm.module.sass";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Container, Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

/**
 * Facet item form component
 * @memberof Components
 * @function FacetFormItem
 */

const FacetFormItem = ({ input, label, count, facetName }) => {
	const isContrast = useSelector(state => state.app.isContrast);
	const { t } = useTranslation();

	const metadataStaticValues = ["techCanIUseIt", "bsaSimpleType", "techObjectAccessibility", "techType"];

	return (
		<li className={styles["form__list-item"]}>
			<FormGroup
				sx={[
					{
						width: 1,
						paddingLeft: 2.5,
						pt: 0,
						pd: 0,
						mt: 0,
						md: 0,
						"&:hover": {
							bgcolor: isContrast ? "initial" : "#DFEAF4",
						},
					},
					input.checked && {
						fontWeight: 700,
					},
				]}
			>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
					<FormControlLabel
						{...input}
						control={
							<Checkbox
								size="small"
								sx={{
									marginLeft: 0,
									paddingLeft: 0,
								}}
							/>
						}
						label={
							<Container
								sx={[
									{
										// pr: 0
									},
									input.checked && {
										fontWeight: 700,
									},
								]}
							>
								{metadataStaticValues.includes(facetName) ? t(`results:facets.${facetName}.${label}`) : label}
							</Container>
						}
					/>
					<span style={{ color: isContrast ? "white" : "#4A4A4A" }}>{count}</span>
				</Stack>
			</FormGroup>
		</li>
	);
};

export default FacetFormItem;
