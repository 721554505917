import React, { createContext } from "react";

export const WordpressAcfContext = createContext(null);

/**
 *
 * Wordpress context
 *
 * @function WordpressAcfContextProvider
 * @memberof Context
 *
 */

const WordpressAcfContextProvider = props => {
	return <WordpressAcfContext.Provider value={{ ...props }}>{props.children}</WordpressAcfContext.Provider>;
};

export default WordpressAcfContextProvider;
