/**
 * is in role (keycloak)
 * @function
 */
export const isInRole = (roles, role) => {
	return roles && roles?.wpRoles?.includes(role);
};

/**
 * is wordpress admin role (keycloak - wordpress)
 * @function
 */
export const isWpAdminRole = roles => {
	return roles && roles?.wpRoles?.includes("administrator");
};
/**
 * is wordpress editor/author role (keycloak - wordpress)
 * @function
 */
export const isWpEditorAuthorRole = roles => {
	return roles && (roles?.wpRoles?.includes("editor") || roles?.wpRoles?.includes("author"));
};
/**
 * is wordpress editor/author/contributor role (keycloak - wordpress)
 * @function
 */
export const isWpEditorAuthorContributorRole = roles => {
	return (
		roles &&
		(roles?.wpRoles?.includes("editor") ||
			roles?.wpRoles?.includes("author") ||
			roles?.wpRoles?.includes("contributor"))
	);
};
/**
 * is wordpress editor/author role - minimum (keycloak - wordpress)
 * @function
 */
export const isWpMinAuthorEditorRole = roles => {
	return isWpEditorAuthorContributorRole(roles) || isWpAdminRole(roles);
};
