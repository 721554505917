import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { showNotification } from "@/actions";
import url from "router/urls";
import storage from "scripts/storage";
import Loader from "@/components/Loader/Loader";
import api from "services/api";
import { reverse } from "named-urls";
import ReactHtmlParser from "react-html-parser";
import ConfirmModal from "@/components/modals/ConfirmModal";
import EditCollectionModal from "@/components/modals/EditCollectionModal";
import HelpIcon from "@/components/helpIcon/HelpIcon";
import moment from "moment/moment";

import useToken from "@/components/hooks/useToken";

import Results from "../Results/Results";
import * as Actions from "@/actions";

import styles from "./CollectionPages.module.sass";

import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

/**
 * My collection results page
 * @memberof Containers
 * @function MyCollectionResults
 */

const MyCollectionResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { bearer, userId } = useToken();

	const endpoint = "/api/list";

	const [loading, setLoading] = useState(true);
	const [record, setRecord] = useState({});
	const [itemsList, setItemsList] = useState([]);
	const [rawItemsList, setRawItemsList] = useState([]);
	const [isShared, setIsShared] = useState(false);
	const [modalConfirmIsOpen, setConfirmIsOpen] = useState(false);
	const [link, setLink] = useState("");
	const [errorVisible, setErrorVisible] = useState(false);

	const getCollectionsItem = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.get(`/users/${userId}/static-collections/${id}`, config)
			.then(({ data }) => {
				setRecord(data);
				setIsShared(data.shared);
				setLink(createSharedLink(data.shareLinkId));
				api
					.get(`/users/${userId}/static-collections/${id}/items`, config)
					.then(({ data }) => {
						setLoading(false);
						setRawItemsList(data.items);
						if (data.items && data.items.length) {
							const objectIds = data.items.map(x => x.value);
							setItemsList(objectIds);
							const from = window.location.pathname + window.location.search;
							storage.saveObject(from, objectIds);
						}
					})
					.catch(() => {
						setLoading(false);
					});
			})
			.catch(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		if (bearer) {
			getCollectionsItem();
		}
	}, [bearer]);

	useEffect(() => {
		loadResults(location.search, searchParams, actions, endpoint);
		return () => actions.resetSearchParams();
	}, [itemsList]);

	useEffect(() => {
		if (!location) return;
		loadResults(location.search, searchParams, actions, endpoint);
	}, [location]);

	const loadResults = (searchLocation, searchParams, actions, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams({
			...newSearchParams,
			ids: itemsList.join(","),
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1,
			},
		});

		actions.loadResults(endpoint, searchUrl);
	};

	const openDeleteConfirm = () => {
		setConfirmIsOpen(true);
	};

	const handleEdit = () => {
		getCollectionsItem();
	};

	const closeConfirmModal = () => {
		setConfirmIsOpen(false);
	};

	const shareCollection = (id, toggle = true) => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
				Accept: "application/json-patch+json",
				"Content-Type": "application/json-patch+json",
			},
		};

		api
			.patch(
				`/users/${userId}/static-collections/${id}`,
				[
					{
						op: "replace",
						path: "/shared",
						value: toggle,
					},
				],
				config
			)
			.then(res => {
				if (toggle) {
					const shareLinkId = res.data?.shareLinkId;
					const link = createSharedLink(shareLinkId);
					setLink(link);
					navigator.clipboard.writeText(link);
					setIsShared(true);
					dispatch(showNotification(t("details:collections.linkShared")));
				} else {
					setLink(null);
					setIsShared(false);
					dispatch(showNotification(t("details:collections.unshared")));
				}
			})
			.catch(() => {
				setErrorVisible(true);
			});
	};

	const handleDelete = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.delete(`/users/${userId}/static-collections/${id}`, config)
			.then(() => {
				history.push(url.collections.my);
			})
			.catch(() => {});
	};

	const deleteFormCollection = itemId => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.delete(`/users/${userId}/static-collections/${id}/items/${itemId}`, config)
			.then(() => {
				getCollectionsItem();
			})
			.catch(() => {});
	};

	function createSharedLink(shareLinkId) {
		return window.location.origin + reverse(url.collections.sharedItem, { id: shareLinkId });
	}

	if (loading) return <Loader />;

	if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	const path = [
		{
			name: t("header:account.myAccount"),
			url: url.account,
		},
		{
			name: t("header:menu.collections.items.myCollections"),
			url: url.collections.my,
		},
		{
			name: record?.name,
		},
	];

	return (
		<Results
			title={record?.name}
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching}
			location={location}
			history={history}
			removeFunction={deleteFormCollection}
			itemsList={rawItemsList}
			path={path}
		>
			<ConfirmModal
				isOpen={modalConfirmIsOpen}
				onClose={closeConfirmModal}
				message={t("details:collections.areyousure")}
				header={t("details:collections.delete")}
				action={handleDelete}
			/>

			<h1 className={`${styles["collectionsList__text--title--main"]} ${styles["collectionsList__text--title--bold"]}`}>
				{record.name}
			</h1>

			<div className={styles["collectionsItem__head--buttons"]}>
				{errorVisible && (
					<div className={styles["collectionsItem__alert"]}>
						<span>{t("details:collections.addObjectError")} </span>
						<a onClick={() => setErrorVisible(false)}>
							<i className="icon-cancel"></i>
						</a>
					</div>
				)}
				<EditCollectionModal
					uid={userId}
					id={id}
					collName={record.name}
					collDesc={record.description}
					onOk={handleEdit}
				/>
				<Button variant="link" onClick={() => openDeleteConfirm(id)}>
					{t("details:collections.delete")}
				</Button>
				{isShared && (
					<>
						<Button variant="primary" onClick={() => shareCollection(id, false)}>
							{t("details:collections.unshareCollection")}
						</Button>
					</>
				)}
				{!isShared && (
					<>
						<Button variant="secondary" onClick={() => shareCollection(id, true)}>
							{t("details:collections.shareCollection")}
						</Button>
						<HelpIcon text={ReactHtmlParser(t("details:collections:shareInfo"))} />
					</>
				)}
			</div>

			<div className={styles["collectionsItem--desc"]}>
				<div className={styles["collectionsItem--header"]}>
					<span className={styles["collectionsItem--counter"]}>
						{t("details:collections.objects")} <strong>{record.itemsCount}</strong>
					</span>
					<h3 className={styles["collectionsItem--head"]}>{record.name}</h3>
					<p className={styles["collectionsItem__text--date"]}>
						{t("home:news.item.date")}:{" "}
						<strong>{moment(record.creationTimestamp).format(moment.localeData(language).longDateFormat("L"))}</strong>
					</p>
				</div>

				<div className={styles["collectionsItem--content"]}>{record.description}</div>
				{isShared && (
					<div className={styles["collectionsItem--content"]}>
						<div>
							Udostępniona:{" "}
							<ContentPasteIcon
								sx={{ verticalAlign: "bottom", cursor: "pointer" }}
								titleAccess={"Skopiuj url do schowka"}
								onClick={() => {
									navigator.clipboard.writeText(link);
									dispatch(showNotification(t("details:collections.linkShared")));
								}}
							/>
							{/* <Button
								className={styles["collectionsItem--content--link"]}
								variant="link"
								href={link}
								target="_blank"
								rel="noreferrer"
							>
								{link}
							</Button> */}
						</div>
					</div>
				)}
			</div>
		</Results>
	);
};

MyCollectionResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		results: state.results.results,
		facets: state.results.facets,
		category: state.results.category,
		count: state.results.count,
		links: state.results.links,
		isFetching: state.results.isFetching,
		searchParams: state.search.params,
		searchCategory: state.search["f.category"],
		searchSort: state.search.sortBy,
		searchSortOrder: state.search.sortOrder,
		searchFacets: state.search.facets,
		totalPages: state.search.totalPages,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCollectionResults));
