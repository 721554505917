import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Switch, useLocation, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import Keycloak from "keycloak-js";
import * as Actions from "@/actions";
import { CheckLoggedIn } from "@/scripts/authService";

import AppContextProvider from "@/contexts/AppContext";
import WordpressAcfContextProvider from "@/contexts/WordpressAcfContext";
import { ThemeContext } from "contexts/ThemeContext";
import routes from "@/router/routes";

import Header from "@/components/Layout/Header/Header";
import Footer from "@/components/Layout/Footer/Footer";

import styles from "./App.module.sass";
import { setConfig } from "../../actions";

import { setApiAddress } from "services/api";
import { setWordpressApiAddress } from "services/wordpress";
import { setIndexApiAddress, setIndexWpApiAddress, setContrast } from "actions/index";

import wordpressApi from "services/wordpress";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import storage from "scripts/storage";
import ScrollToTop from "@/components/hooks/scrollToTop";

import Notification from "@/components/notification/Notification";

import url from "router/urls";

const googleAnalyticsID = process.env.REACT_APP_GA_TAG_ID;
const googleAnalyticsID2 = process.env.REACT_APP_GA_TAG_ID2;
const googleAnalyticsID3 = process.env.REACT_APP_GA_TAG_ID3;

const setGlobalContrast = setContrast;

/**
 * Application route
 * @memberof Containers
 * @function AppRoute
 */

const AppRoute = ({ component: Component, ...rest }) => {
	return <Route {...rest} render={props => <Component {...props} />} />;
};

/**
 * Application router
 * @memberof Containers
 * @function AppRouter
 */

const AppRouter = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Header />
			<Switch>
				{<Route key={"all"} path={url.allObjects} render={() => <Redirect to={url.allResults} />} />}
				{<Route key={"last"} path={url.last} render={() => <Redirect to={url.lastAdded} />} />}
				{routes.map(route => (
					<AppRoute key={route.id} {...route} />
				))}
			</Switch>
			<Footer />
			<Notification />
		</BrowserRouter>
	);
};

/**
 * normalize language
 * @param {string} lang - language
 * @function normalizeLanguage
 */

const normalizeLanguage = lang => {
	return lang.split("-")[0];
};

/**
 * Application main wrapper
 * @memberof Containers
 * @function App
 */

const App = () => {
	const { i18n } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const setIsContrastTheme = useContext(ThemeContext);
	const availableLanguages = i18n.options.fallbackLng;
	const discoveredLang = storage.getString(i18n.options.detection.lookupLocalStorage);
	let currentLang = discoveredLang;
	if (currentLang.length > 2) {
		currentLang = normalizeLanguage(currentLang);
	}
	if (availableLanguages.indexOf(currentLang) === -1) currentLang = "pl";
	if (discoveredLang !== currentLang) {
		storage.save(i18n.options.detection.lookupLocalStorage, currentLang);
		i18n.changeLanguage(currentLang);
	}
	const [isLoading, setIsLoading] = useState(false);
	const [fontSize, setFontSize] = useState(storage.getString("fontSize", "normal"));
	const [contrast, setContrast] = useState(storage.getBool("contrast", false));
	const [mourn, setMourn] = useState(storage.getTemporary("mourn", false));
	const [wordpressAcf, setWordpressAcf] = useState(null);

	const keycloak = new Keycloak("/config/keycloak.json");
	dispatch(setGlobalContrast(contrast));
	dispatch(Actions.setAuthAdapter(keycloak));

	const loadConfigFileSync = () => {
		let xmlhttp = new XMLHttpRequest();
		xmlhttp.open("GET", "/config/config.json", false);
		xmlhttp.send();
		if (xmlhttp.status === 200 && xmlhttp.readyState === 4) {
			return xmlhttp.responseText;
		} else {
			return null;
		}
	};

	const readConfig = () => {
		const config = JSON.parse(loadConfigFileSync());
		if (config) {
			dispatch(setConfig(config));
			setApiAddress(config.reactAppApi);
			setIndexApiAddress(config.reactAppApi);
			setWordpressApiAddress(config.reactAppApiWp);
			setIndexWpApiAddress(config.reactAppApiWp);
		}
	};

	readConfig();

	const loadKeycloakConfig = async () => {
		const response = await fetch("/config/keycloak.json", {
			method: "GET",
		});
		const keycloakConfig = await response.json();
		dispatch(Actions.setKeycloakUrl(keycloakConfig["auth-server-url"]));
	};

	useEffect(() => {
		loadKeycloakConfig();
	}, []);

	const changeFontSize = () => {
		let newFontSize = "normal";
		switch (fontSize) {
			case "normal":
				newFontSize = "big";
				break;
			case "big":
				newFontSize = "bigger";
				break;
			default:
				break;
		}
		setFontSize(newFontSize);
		storage.save("fontSize", newFontSize);
	};

	const changeContrast = () => {
		setContrast(!contrast);
		storage.save("contrast", !contrast);
		dispatch(setGlobalContrast(!contrast));
		setIsContrastTheme(!contrast);
	};

	const ReactAnalyticsInit = () => {
		ReactGA.initialize(
			[
				{
					trackingId: googleAnalyticsID,
					gaOptions: {
						name: "tracker1",
					},
				},
				{
					trackingId: googleAnalyticsID2,
					gaOptions: {
						name: "tracker2",
					},
				},
				{
					trackingId: googleAnalyticsID3,
					gaOptions: {
						name: "tracker3",
					},
				},
			],
			{ debug: false, alwaysSendToDefaultTracker: false }
		);
	};

	const ReactAnalyticsBeacon = () => {
		ReactGA.pageview(location.pathname + location.search, ["tracker1"]);
		ReactGA.pageview(location.pathname + location.search, ["tracker2"]);
		ReactGA.pageview(location.pathname + location.search, ["tracker3"]);
	};

	useEffect(() => {
		wordpressApi
			.get(`wp/v2/pages?slug=homepage`)
			.then(({ data }) => {
				setWordpressAcf(data[0].acf);
				const isMourn = data[0].acf?.isMourn;
				setMourn(isMourn);
				storage.saveTemporary("mourn", isMourn, 24, "hours");
			})
			.catch(() => {});
	}, []);

	useEffect(() => {
		ReactAnalyticsInit();
		ReactAnalyticsBeacon();
		CheckLoggedIn(keycloak, dispatch);
	}, []);

	useEffect(() => {
		ReactAnalyticsBeacon();
	}, [location]);

	useEffect(() => {
		const htmlTag = document.querySelector("html").classList;

		htmlTag.remove("normal");
		htmlTag.remove("big");
		htmlTag.remove("bigger");
		htmlTag.add(fontSize);
	}, [fontSize]);

	useEffect(() => {
		if (contrast) {
			document.body.classList.add(`contrast`);
		} else {
			document.body.classList.remove(`contrast`);
		}
	}, [contrast]);

	useEffect(() => {
		document.querySelector("html").classList[mourn ? "add" : "remove"]("mourn");
	}, [mourn]);

	return (
		<AppContextProvider
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			changeFontSize={changeFontSize}
			changeContrast={changeContrast}
			fontSize={fontSize}
			contrast={contrast}
			className={styles["app"]}
		>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<WordpressAcfContextProvider value={wordpressAcf}>
					<AppRouter />
				</WordpressAcfContextProvider>
			</LocalizationProvider>
		</AppContextProvider>
	);
};

export default App;

// class App extends Component {
// 	constructor(props) {
// 		super(props);
// 		const { cookies } = props;

// 		this.state = {
// 			isSetCookies: cookies.get("policy"),
// 			fontSize: ""
// 		};
// 	}

// 	static getDerivedStateFromProps(props, state) {
// 		const pathName = props.history.location.pathname;
// 		const query = props.history.location.search;

// 		if (pathName !== state.currentPath || query !== state.query) {
// 			return {
// 				currentPath: pathName,
// 				query: query
// 			};
// 		}

// 		return null;
// 	}

// 	componentDidMount() {
// 		const { actions, cookies, location } = this.props;

// 		const fontSize = cookies.get("fontSize");
// 		if (fontSize) {
// 			actions.setFontSize(fontSize);
// 		}

// 		const contrast = cookies.get("contrast") === "true";
// 		if (contrast) {
// 			actions.setContrast(contrast);
// 			document.querySelector("body").classList[contrast ? "add" : "remove"]("contrast");
// 		}

// 		ReactAnalyticsInit();
// 		ReactAnalyticsBeacon(location);
// 	}

// 	componentDidUpdate(prevProps) {
// 		const { fontSize, isContrast, cookies, location } = this.props;

// 		if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
// 			window.scrollTo(0, 0);
// 		}

// 		const HtmlSelectorClassList = document.querySelector("html").classList;

// 		if (prevProps.fontSize !== fontSize) {
// 			HtmlSelectorClassList.remove(prevProps.fontSize);
// 			HtmlSelectorClassList.add(fontSize);
// 			cookies.set("fontSize", fontSize, { path: "/" });
// 		}

// 		if (prevProps.isContrast !== isContrast) {
// 			document.querySelector("body").classList[isContrast ? "add" : "remove"]("contrast");
// 			cookies.set("contrast", isContrast, { path: "/" });
// 		}

// 		ReactAnalyticsBeacon(location);
// 	}

// 	setCookie = () => {
// 		const { cookies } = this.props;

// 		cookies.set("policy", true, { path: "/" });
// 		this.setState({ isSetCookies: true });
// 	};

// 	getComponent = (type, props, to, currentLang) => {
// 		const ItemComponent = type;

// 		return <ItemComponent {...props} to={to} currentLang={currentLang} />;
// 	};

// 	setLang = newLang => {
// 		const { actions } = this.props;

// 		actions.setNewLang(newLang);
// 	};

// 	setContrast = () => {
// 		const { actions, isContrast } = this.props;

// 		actions.setContrast(!isContrast);
// 	};

// 	setFontSize = () => {
// 		const { actions, fontSize } = this.props;

// 		switch (fontSize) {
// 			case "font-medium":
// 				return actions.setFontSize("font-big");
// 			case "font-big":
// 				return actions.setFontSize("font-normal");
// 			default:
// 				return actions.setFontSize("font-medium");
// 		}
// 	};

// 	render() {
// 		const { isSetCookies, currentPath } = this.state;
// 		const { langs, isContrast } = this.props;
// 		const currentLang = langs.filter(lang => lang.active)[0];

// 		return (
// 			<div className={styles.app} onScroll={this.handleScroll}>
// 					<Container fluid className={styles.app__container}>
// 						<LoadingBar
// 							showFastActions
// 							style={{
// 								backgroundColor: "#c3a558",
// 								height: "3px",
// 								position: "fixed",
// 								top: "0",
// 								left: "0"
// 							}}
// 							updateTime={100}
// 							maxProgress={100}
// 							progressIncrease={10}
// 						/>
// 						<Header
// 							type={`${currentPath === "/" ? "main" : "secondary"}`}
// 							langs={langs}
// 							setLang={this.setLang}
// 							setContrast={this.setContrast}
// 							currentLang={currentLang}
// 							isContrast={isContrast}
// 							setFontSize={this.setFontSize}
// 						/>
// 						<div className={styles.app__main}>
// 							{/* <Suspense fallback={<div>loading...</div>}> */}
// 								<Switch>
// 									{routes.map(route => (
// 										<Route
// 											key={route.id}
// 											exact={route.exact}
// 											path={route.path}
// 											render={props => this.getComponent(route.component, props, route.to)}
// 										/>
// 									))}
// 								</Switch>
// 							{/* </Suspense> */}
// 						</div>
// 						<Footer type={`${currentPath === "/" ? "main" : "secondary"}`} />

// 						{!isSetCookies && <CookiesBar setCookie={this.setCookie} />}
// 					</Container>
// 			</div>
// 		);
// 	}
// }

// App.propTypes = {
// 	routes: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			id: PropTypes.number.isRequired,
// 			path: PropTypes.string,
// 			exact: PropTypes.bool,
// 			component: PropTypes.object
// 		}).isRequired
// 	),
// 	cookies: instanceOf(Cookies).isRequired,
// 	langs: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			id: PropTypes.string.isRequired,
// 			name: PropTypes.string,
// 			active: PropTypes.bool
// 		})
// 	),
// 	currentLang: PropTypes.string,
// 	isContrast: PropTypes.bool.isRequired
// };

// const mapStateToProps = state => ({
// 	langs: state.app.langs,
// 	currentLang: state.app.currentLang,
// 	isContrast: state.app.isContrast,
// 	fontSize: state.app.fontSize
// });

// const mapDispatchToProps = dispatch => ({
// 	actions: bindActionCreators(Actions, dispatch)
// });

// export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(App)));
