import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

import Checkbox from "react-simple-checkbox";

import styles from "@/containers/Results/ResultsSidebar/facets/Facet/FacetForm/FacetFormDateFields/FacetFormDateFields.module.sass";

/**
 * Checkbox
 * @memberof Components
 * @function DateCheckbox
 */

const DateCheckbox = ({ input, label, actionChange, count, meta: { touched, error, submitFailed } }) => {
	const { t } = useTranslation();

	return (
		<div className={styles["checkbox"]}>
			<Checkbox
				{...input}
				id={label}
				color={{ backgroundColor: "#fff" }}
				checked={input.value}
				onChange={() => {
					actionChange(input.value);
					input.onChange(!input.value);
				}}
				className="checkbox--light"
				label={label}
				size="2"
			/>
			<Form.Label htmlFor={label} className={styles["checkbox__label"]} onClick={() => input.onChange(input.value)}>
				<span className={styles["checkbox__label--text"]}>{label}</span>
				<span className={styles["checkbox__label--count"]}>{count}</span>
			</Form.Label>
			{touched && error && submitFailed && (
				<span className={styles["date__error"]}>{t(`advanced_search:form.errors.${error}`)}</span>
			)}
		</div>
	);
};

export default DateCheckbox;
