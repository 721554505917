import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import url from "router/urls";
import styles from "./Footer.module.sass";

import logo from "@/assets/images/logo.svg";
import logo_contrast from "@/assets/images/logo_contrast.svg";
import { useSelector } from "react-redux";

import wordpressApi from "services/wordpress";
import menus from "router/menus";
import WordpressFooterBox from "./WordpressFooterBox";
import useStorageState from "scripts/useStorageState";

import CookiesBar from "@/components/cookies/Cookies";

/**
 * Footer (layout)
 * @memberof Components
 * @function Footer
 */

const Footer = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const isContrast = useSelector(state => state.app.isContrast);

	const currentLogo = isContrast ? logo_contrast : logo;

	const url_module_1 = process.env.REACT_APP_MODULES_BAD;
	const url_module_2 = process.env.REACT_APP_MODULES_EDU;
	const url_modele_3 = process.env.REACT_APP_MODULES_WOL;
	const clientName = process.env.REACT_APP_CLIENT_NAME;
	const clientUrl = process.env.REACT_APP_CLIENT_URL;

	const [wordpressMenuInformations, setWordpressMenuInformations] = useStorageState(`${language}-${menus.contact}`, {});
	const [wordpressMenuAbout, setWordpressMenuAbout] = useStorageState(`${language}-${menus.about}`, {});

	function getWordpressMenus() {
		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.contact}`)
			.then(({ data }) => {
				setWordpressMenuInformations(data);
			})
			.catch(() => {
				setWordpressMenuInformations({});
			});

		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.about}`)
			.then(({ data }) => {
				setWordpressMenuAbout(data);
			})
			.catch(() => {
				setWordpressMenuAbout({});
			});
	}

	useEffect(() => {
		getWordpressMenus();
	}, [language]);

	const actualYear = new Date().getFullYear();
	return (
		<>
			<CookiesBar />
			<footer className={styles["footer"]}>
				<div className={styles["footer__upper"]}>
					<div className={` ${styles["footer__container"]} container`}>
						<div className={styles["footer__logo"]}>
							<Link to={url.home}>
								<img src={currentLogo} alt="Logo Ariadna" className={styles["footer__logo--img"]} />
							</Link>
						</div>
						<div className={styles["footer__menu"]}>
							<div className={styles["footer__menu--col"]}>
								<div className={styles["footer__menu--box"]}>
									<h2 className={styles["footer__text--title"]}>{t("footer:menu.collections.title")}</h2>
									<ul className={styles["footer__menu--list"]}>
										<li className={styles["footer__menu--item"]}>
											<Link to={url.collections.my} className={styles["footer__text--menu"]}>
												{t("footer:menu.collections.items.myCollections")}
											</Link>
										</li>
										<li className={styles["footer__menu--item"]}>
											<Link to={url.collections.list} className={styles["footer__text--menu"]}>
												{t("footer:menu.collections.items.allCollections")}
											</Link>
										</li>
									</ul>
								</div>
								<div className={styles["footer__menu--box"]}>
									<h2 className={styles["footer__text--title"]}>{t("footer:menu.topics.title")}</h2>
									<ul className={styles["footer__menu--list"]}>
										<li className={styles["footer__menu--item"]}>
											<Link to={url.topics.my} className={styles["footer__text--menu"]}>
												{t("footer:menu.topics.items.myTopics")}
											</Link>
										</li>
										<li className={styles["footer__menu--item"]}>
											<Link to={url.topics.all} className={styles["footer__text--menu"]}>
												{t("footer:menu.topics.items.allTopics")}
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className={` ${styles["footer__menu--col"]} ${styles["footer__menu--col-info"]}`}>
								<ul className={styles["footer__menu--list"]}>
									<li className={styles["footer__menu--item"]}>
										<Link to={url.sitemap} className={styles["footer__text--menu"]}>
											{t("footer:menu.infoHelp.items.sitemap")}
										</Link>
									</li>
								</ul>
								<WordpressFooterBox
									menu={wordpressMenuInformations}
									title={t("footer:menu.about.title")}
								></WordpressFooterBox>
							</div>
							<div className={styles["footer__menu--col"]}>
								<div className={styles["footer__menu--box"]}>
									<h2 className={styles["footer__text--title"]}>{t("footer:menu.news.title")}</h2>
									<ul className={styles["footer__menu--list"]}>
										<li className={styles["footer__menu--item"]}>
											<Link to={url.news.list} className={styles["footer__text--menu"]}>
												{t("footer:menu.news.items.allNews")}
											</Link>
										</li>
									</ul>
								</div>
								<WordpressFooterBox
									menu={wordpressMenuAbout}
									title={t("footer:menu.about.title")}
									isAbout={true}
								></WordpressFooterBox>
							</div>
							<div className={styles["footer__menu--col"]}>
								<div className={styles["footer__menu--box"]}>
									<h2 className={styles["footer__text--title"]}>{t("footer:menu.modules.title")}</h2>
									<ul className={styles["footer__menu--list"]}>
										<li className={styles["footer__menu--item"]}>
											<a href={url_module_1} target="_blank" className={styles["footer__text--menu"]} rel="noreferrer">
												{t("footer:menu.modules.items.ocr")}
											</a>
										</li>
										<li className={styles["footer__menu--item"]}>
											<a href={url_module_2} target="_blank" className={styles["footer__text--menu"]} rel="noreferrer">
												{t("footer:menu.modules.items.edu")}
											</a>
										</li>
										<li className={styles["footer__menu--item"]}>
											<a href={url_modele_3} target="_blank" className={styles["footer__text--menu"]} rel="noreferrer">
												{t("footer:menu.modules.items.geohistory")}
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles["footer__lower"]}>
					<div className={` ${styles["footer__container"]} container`}>
						<p className={styles["footer__text--copyright"]}>
							<Trans
								i18nKey="footer:copyright"
								values={{ year: actualYear, client: clientName }}
								components={[
									<a href={clientUrl} target="_blank" key="firstLink" rel="noreferrer" />,
									<a href="https://www.pcss.pl/" target="_blank" key="secondLink" rel="noreferrer" />,
								]}
							/>
						</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
