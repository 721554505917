import React from "react";
import styles from "./HelpIcon.module.sass";

import Help from "assets/icons/svg/help.svg";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/**
 * Help icon
 * @memberof Components
 * @function HelpIcon
 */

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} onClick={e => e.stopPropagation()} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.contrast ? theme.palette.contrast.black : theme.palette.common.white,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.contrast ? theme.palette.contrast.black : "white",
		color: theme.palette.contrast ? theme.palette.contrast.white : "#4a4a4a",
		boxShadow: "0px 0px 15px black;",
		padding: "10px",
		borderRadius: 0,
		fontSize: "0.875em",
		lineHeight: "2em",
		margin: "5px",
	},
}));

const HelpIcon = ({ text, className }) => {
	return (
		<LightTooltip classes={{ popper: styles["help-tooltip"] }} title={text} arrow>
			<div className={`${styles["help-icon"]} ${className}`}>
				<img src={Help} alt="HELP" />
			</div>
		</LightTooltip>
	);
};

export default HelpIcon;
