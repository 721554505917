import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./Financing.module.sass";

import financial1ImgNormal from "@/assets/images/financial-1.svg";
import financial2ImgNormal from "@/assets//images/financial-2.svg";
import financial3ImgNormal from "@/assets//images/financial-3.svg";

import financial1ImgContrast from "@/assets/images/financial-1.kontrast.svg";
import financial2ImgContrast from "@/assets//images/financial-2.kontrast.svg";
import financial3ImgContrast from "@/assets//images/financial-3.kontrast.svg";

/**
 * Financing (homepage)
 * @memberof Components
 * @function Financing
 */

const Financing = () => {
	const { t } = useTranslation();
	const isContrast = useSelector(state => state.app.isContrast);

	const financial1Img = isContrast ? financial1ImgContrast : financial1ImgNormal;
	const financial2Img = isContrast ? financial2ImgContrast : financial2ImgNormal;
	const financial3Img = isContrast ? financial3ImgContrast : financial3ImgNormal;

	return (
		<div className={styles["financing"]}>
			<div className={` ${styles["financing__container"]} container`}>
				<div className={styles["financing__header"]}>
					<h2 className={styles["financing__text--header"]}>{t("home:financing.title")}</h2>
				</div>
				<div className={styles["financing__content"]}>
					<p className={styles["financing__text--content"]}>{t("home:financing.content")}</p>
				</div>
				<div className={styles["financing__footer"]}>
					<div>
						<img src={financial1Img} alt="Logo Fundusze Europejskie" />
					</div>
					<div>
						<img src={financial2Img} alt="Flaga Rzeczpospolita Polska" />
					</div>
					<div>
						<img src={financial3Img} alt="Flaga Unia Europejska" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Financing;
