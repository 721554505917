import queryString from "query-string";

const newFacet = facet =>
	Object.keys(facet).reduce((obj, key) => {
		return {
			...obj,
			[`f.${key}`]: facet[key],
		};
	}, {});

export const transformToObjArr = obj =>
	Object.keys(obj).map(facet => {
		return {
			label: facet,
			value: obj[`${facet}`],
		};
	});

/**
 * setting search params
 * @param {string} query - query
 * @param {string} searchParams - search params
 * @function
 */

export const setSearchParams = (query, searchParams) => {
	const queryObj = queryString.parse(query);
	let q = queryObj.q;
	let scope = queryObj.scope;
	const page = queryObj.page;
	const pageSize = queryObj.pageSize;
	const category = queryObj["f.category"];
	const europeana = queryObj.europeana;
	const missing = queryObj.missing;
	const sortBy = queryObj.sortBy;
	const sortOrder = queryObj.sortOrder;

	if (scope && q) {
		if (q.includes(scope + ":in:")) {
			q = q.replace(scope + ":in:", "");
		}
	}

	const facets = Object.keys(queryObj)
		.filter(item => item.startsWith("f.") && item !== "f.category")
		.reduce((arr, key) => {
			return [
				...arr,
				{
					[key]: Array.isArray(queryObj[key]) ? queryObj[key] : [queryObj[key]],
				},
			];
		}, []);

	const newParams = {
		params: {
			...searchParams,
			page: page ? parseInt(page, 10) : searchParams.page,
			pageSize: pageSize ? parseInt(pageSize, 10) : searchParams.pageSize,
			q: q,
			scope: queryObj.scope,
			europeana: queryObj.europeana,
		},
		"f.category": category ? category : "",
		facets: facets.length ? facets : [],
		europeana: europeana,
		missing: missing,
		sortBy: sortBy,
		sortOrder: sortOrder,
	};

	return newParams;
};

/**
 * listing search params
 * @param {string} query - query
 * @param {string} searchParams - search params
 * @function
 */

export const setListSearchParams = (query, searchParams) => {
	const queryObj = queryString.parse(query);
	const page = queryObj.page;
	const pageSize = queryObj.pageSize;
	const category = queryObj["f.category"];
	const europeana = queryObj.europeana;
	const missing = queryObj.missing;
	const sortBy = queryObj.sortBy;
	const sortOrder = queryObj.sortOrder;

	const facets = Object.keys(queryObj)
		.filter(item => item.startsWith("f.") && item !== "f.category")
		.reduce((arr, key) => {
			return [
				...arr,
				{
					[key]: Array.isArray(queryObj[key]) ? queryObj[key] : [queryObj[key]],
				},
			];
		}, []);

	const newParams = {
		params: {
			...searchParams,
			page: page ? parseInt(page, 10) : searchParams.page,
			pageSize: pageSize ? parseInt(pageSize, 10) : searchParams.pageSize,
			q: queryObj.q,
			scope: queryObj.scope,
			europeana: queryObj.europeana,
		},
		"f.category": category ? category : "",
		facets: facets.length ? facets : [],
		europeana: europeana,
		missing: missing,
		sortBy: sortBy,
		sortOrder: sortOrder,
	};

	return newParams;
};

/**
 * setting url from search params
 * @param {string} params - parameters
 * @param {boolean} isRequest - request flag
 * @function
 */

export const setUrlFromParams = (params, isRequest = false) => {
	let scope = params.params.scope;
	let clone = Object.assign({}, params.params);

	//eslint-disable-next-line
	if (clone.q && isRequest) clone.q = clone.q.replace(/(?<!in):+(?!(?![\(])|[^\s:]*in:)/g, ":in:");

	if (scope && scope.length > 0 && clone.q.startsWith(scope)) clone.q = `${scope}:${isRequest ? "in:" : ""}${clone.q}`;

	const idsParamsStr = params.ids;

	const queryParamsStr = queryString.stringify(clone, {
		arrayFormat: "delimiter",
	});

	const categoryParamsStr = params["f.category"]
		? queryString.stringify(
				{ "f.category": params["f.category"] },
				{
					arrayFormat: "delimiter",
				}
		  )
		: null;

	const facetsParamsStr = params.facets
		? params.facets
				.filter(str => !str["f.dctermsIssued"])
				.reduce((str, obj) => `${str}&${queryString.stringify(obj)}`, "")
		: null;

	const timeParams = params.facets.filter(obj => obj["f.dctermsIssued"]);

	const timeStr =
		timeParams[0] &&
		`&f.dctermsIssued=[${timeParams[0]["f.dctermsIssued"].from} TO ${timeParams[0]["f.dctermsIssued"].to}]`;

	const europeanaStr = params.europeana ? "&europeana=true" : null;
	const missingStr = params.missing ? `&missing=${params.missing}` : null;
	const sortStr = params.sortBy ? `&sortBy=${params.sortBy}` : null;
	const sortOrderStr = params.sortOrder ? `&sortOrder=${params.sortOrder}` : null;

	return `?${idsParamsStr ? `ids=${idsParamsStr}&` : ``}${queryParamsStr}${
		categoryParamsStr ? `&${categoryParamsStr}` : ""
	}${facetsParamsStr ? `${facetsParamsStr}` : ""}${europeanaStr ? europeanaStr : ""}${missingStr ? missingStr : ""}${
		sortStr ? sortStr : ""
	}${sortOrderStr ? sortOrderStr : ""}${timeStr ? timeStr : ""}`;
};

/**
 * setting page size
 * @param {number} size - size
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const setPageSize = (size, updateUrl, params) => {
	const { searchParams, searchCategory, searchFacets, searchSort, searchSortOrder } = params;

	if (searchParams.pageSize !== size) {
		updateUrl({
			params: {
				...searchParams,
				pageSize: size,
				page: 1,
			},
			"f.category": searchCategory,
			sortBy: searchSort,
			sortOrder: searchSortOrder,
			facets: searchFacets,
		});
	}
};

/**
 * setting page number
 * @param {number} num - number
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const setPageByNum = (num, updateUrl, params) => {
	const { searchParams, searchCategory, searchFacets, searchSort, searchSortOrder } = params;

	if (searchParams.page !== parseInt(num, 10)) {
		updateUrl({
			params: {
				...searchParams,
				page: parseInt(num, 10),
			},
			"f.category": searchCategory,
			sortBy: searchSort,
			sortOrder: searchSortOrder,
			facets: searchFacets,
		});
	}
};

/**
 * setting facet
 * @param {string} facet - facet
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @param {string} missing - missing
 * @function
 */

export const setFacet = (facet, updateUrl, params, missing) => {
	const { searchParams, searchFacets, searchCategory, searchSort, searchSortOrder } = params;

	const facetAttr = `f.${Object.keys(facet)[0]}`;
	const facetVal = facet[Object.keys(facet)[0]];

	const hasFacet = searchFacets && searchFacets.filter(item => Object.keys(item)[0] === facetAttr).length;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			// pageSize: 30
		},
		"f.category": searchCategory,
		sortBy: searchSort,
		sortOrder: searchSortOrder,
		facets: hasFacet
			? searchFacets.map(item => (Object.keys(item)[0] === facetAttr ? { [facetAttr]: facetVal } : item))
			: [...searchFacets, { ...newFacet(facet) }],
		...missing,
	});
};

/**
 * setting category
 * @param {string} obj - object
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const setCategory = (obj, updateUrl, params) => {
	const { searchParams, searchSort, searchSortOrder } = params;

	updateUrl({
		params: {
			...searchParams,
			// page: 1,
			// pageSize: 30
		},
		"f.category": obj.category,
		sortBy: searchSort,
		sortOrder: searchSortOrder,
		facets: [],
	});
};

/**
 * reset category
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const resetCategory = (updateUrl, params) => {
	const { searchParams } = params;

	updateUrl({
		params: {
			...searchParams,
			// page: 1,
			// pageSize: 30
		},
	});
};

/**
 * reset query
 * @param {string} updateUrl - url
 * @function
 */

export const resetQuery = updateUrl => {
	updateUrl({
		params: {
			q: "*",
			page: 1,
			pageSize: 25,
		},
	});
};

/**
 * reset facet
 * @param {string} facet - facet
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const resetFacet = (facet, updateUrl, params) => {
	const { searchFacets, searchCategory, searchParams, searchSort, searchSortOrder } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			// pageSize: 30
		},
		"f.category": searchCategory,
		sortBy: searchSort,
		sortOrder: searchSortOrder,
		facets: [...searchFacets.filter(item => Object.keys(item)[0] !== `f.${facet}`)],
	});
};

/**
 * reset facets
 * @param {string} updateUrl - url
 * @param {string} params - parameters
 * @function
 */

export const resetAllFacets = (updateUrl, params) => {
	const { searchParams, searchCategory, searchSort, searchSortOrder } = params;

	updateUrl({
		params: {
			...searchParams,
			page: 1,
			// pageSize: 30
		},
		"f.category": searchCategory,
		sortBy: searchSort,
		sortOrder: searchSortOrder,
		facets: [],
	});
};
