import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TruncateMarkup from "react-truncate-markup";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { TextField, InputAdornment, Autocomplete, InputLabel } from "@mui/material";

/**
 * input field with suggester
 * @memberof Components
 * @function FieldWithSuggester
 */

const FieldWithSuggester = ({ suggestionsAdvanced, id, scope, value, handelSearchTexChange, setOpen, open }) => {
	const { t } = useTranslation();
	const handleRenderOptionHighlight = (option, label) => {
		return (
			<div {...option}>
				{
					<TruncateMarkup lines={1} ellipsis={"..."}>
						<span>{parse(label)}</span>
					</TruncateMarkup>
				}
			</div>
		);
	};

	const getScope = () => {
		return scope && scope.includes("allFields") ? "allFields" : scope;
	};

	return (
		<Autocomplete
			freeSolo
			id={`searchTextInput[${id}]`}
			options={suggestionsAdvanced && suggestionsAdvanced?.[getScope()] ? suggestionsAdvanced?.[getScope()] : []}
			sx={{
				width: "100%",
				"& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
					fill: "#005CA9",
				},
			}}
			open={open && value.length > 2}
			getOptionLabel={option => option.replace(/<[^>]*>/gi, "")}
			onChange={(event, newValue) => {
				newValue ? handelSearchTexChange(newValue) : handelSearchTexChange("");
			}}
			onInputChange={(event, newInputValue) => {
				handelSearchTexChange(newInputValue);
			}}
			onFocus={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			inputValue={value}
			renderOption={handleRenderOptionHighlight}
			renderInput={params => (
				<TextField
					className="js-advSearchText"
					id={`text-adv-${id}`}
					variant="standard"
					autoFocus={false}
					label={id === 0 ? t("search:searchMainButton") : t("search:searchNextFieldMainButton")}
					placeholder={id === 0 ? t("search:searchMainButton") : t("search:searchNextFieldMainButton")}
					{...params}
					sx={{
						borderTop: { xs: "1px solid #c0c0c0", sm: "none" },
						pt: { xs: "25px", sm: "0" },
						pb: { xs: "25px", sm: "0" },
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlinedIcon />
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
};

export default FieldWithSuggester;
