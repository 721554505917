const initialState = {
	shown: false,
	text: "",
	lastChange: null,
};

/**
 * Notification reducers
 *
 * @function notification
 * @memberof Reducers
 *
 */

export default function notification(state = initialState, action) {
	switch (action.type) {
		case "SHOW_NOTIFICATION":
			return {
				...state,
				shown: true,
				text: action.data,
				lastChange: new Date(),
			};
		case "HIDE_NOTIFICATION":
			return {
				...state,
				shown: false,
				text: "",
				lastChange: null,
			};
		default:
			return state;
	}
}
