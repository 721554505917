import { useEffect } from "react";
import { useSelector } from "react-redux";
import { LogIn } from "@/scripts/authService";

/**
 * Use authorization token
 * @function useToken
 */

const useToken = (forceLogin = true) => {
	const bearer = useSelector(state => state.authentication.bearer);
	const token = useSelector(state => state.authentication.token);
	const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

	const resource_access_www = process.env.REACT_APP_RESOURCES_ACCESS_WWW;
	const resource_access_wp = process.env.REACT_APP_RESOURCES_ACCESS_WP;
	const resource_access_bad = process.env.REACT_APP_RESOURCES_ACCESS_BAD;

	const userId = token?.sub;
	const expirationDate = token ? new Date(+token.exp * 1000) : null;
	const userFullname = token?.name;
	const userName = token?.given_name;
	const userSurname = token?.family_name;
	const userEmail = token?.email;
	const userLogin = token?.preferred_username;

	const roles = {
		apiRoles: token?.resource_access[resource_access_www]?.roles,
		wpRoles: token?.resource_access[resource_access_wp]?.roles,
		badRoles: token?.resource_access[resource_access_bad]?.roles,
	};

	useEffect(() => {
		if (isLoggedIn != null) {
			if (!isLoggedIn && forceLogin) {
				LogIn();
			}
		}
	}, [isLoggedIn]);

	return {
		isLoggedIn,
		bearer,
		userId,
		expirationDate,
		userLogin,
		userEmail,
		userFullname,
		userName,
		userSurname,
		roles,
	};
};

export default useToken;
