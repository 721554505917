import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Cookies.module.sass";

import Cookies from "universal-cookie";

const cookies = new Cookies();

/**
 * Cookies bar
 * @memberof Components
 * @function CookiesBar
 */

const CookiesBar = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const [cookie, setCookie] = useState(false);

	const handleCookie = () => {
		cookies.set("policy", true, { path: "/" });
		setCookie(true);
	};

	useEffect(() => {
		setCookie(cookies.get("policy"));
	}, []);

	if (cookie) return false;

	return (
		<div className={styles["cookies"]}>
			<div className={` ${styles["cookies__container"]} container`}>
				<div>
					{t("cookies:desc")} <Link to={`/page/${language}-privacypolicy`}>{t("cookies:link1")}</Link>{" "}
					{t("cookies:and")} <Link to={`/page/${language}-privacypolicy`}>{t("cookies:link2")}</Link>
				</div>
				<div>
					<Button variant="primary" onClick={handleCookie}>
						{t("cookies:accept")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CookiesBar;
