import React from "react";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";

import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

const SortingDropDown = ({ sortingOptions, setSortingOption }) => {
	const { t } = useTranslation();

	const handleChange = e => {
		setSortingOption(e.target.value);
	};

	return (
		<FormControl spacing={2} sx={{ width: 1, pt: 2, pl: 2.5, pb: 2 }}>
			<Stack
				direction="row"
				spacing={1}
				sx={{
					alignItems: "center",
				}}
			>
				<FormLabel id="sorting-id">{t("results:facets.sortingDropdown.sort")}</FormLabel>
				<Select
					value={sortingOptions}
					onChange={handleChange}
					labelId={"sorting-id"}
					defaultValue={"default"}
					inputProps={{ "aria-label": `${t("results:facets.sortingDropdown.sort")}` }}
					inline={true}
				>
					<MenuItem value={"default"}>{t("results:facets.sortingDropdown.default")}</MenuItem>
					<MenuItem value={"alphabetic"}>{t("results:facets.sortingDropdown.alphabetic")}</MenuItem>
				</Select>
			</Stack>
		</FormControl>
	);
};

export default SortingDropDown;
