import React, { useEffect, useState } from "react";
import { SubmissionError } from "redux-form";
import { reduxForm, formValueSelector } from "redux-form";

import validate from "scripts/dateFacetFormValidation.js";

import FacetFormDateFields from "./FacetFormDateFields/FacetFormDateFields";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Slider from "@mui/material/Slider";
import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import moment from "moment";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";

const StyledSlider = styled(Slider)({
	color: "#4A4A4A",
});

const DATE_FORMAT = "YYYY-MM-DD";
const DATA_FORMAT_TO = "DD//MM/YYYY";

/**
 * Facet date form component
 * @memberof Components
 * @function FacetFormDate
 */

let FacetFormDate = ({
	handleSubmit,
	submitForm,
	error,
	missingValues,
	changeMissingTime,
	activeMissingTime,
	change,
	formFrom,
	formTo,
}) => {
	const today = moment();
	const oneYearAgo = today.clone().subtract(1, "year");
	const threeYearsAgo = today.clone().subtract(3, "year");
	const lastFiveYears = today.clone().subtract(5, "year");
	const currentYear = today.year();

	const startYear = 1;
	const endYear = currentYear;

	const { t } = useTranslation();

	const breakpoint = useMediaQuery("(max-width: 767.98px)");

	const isContrast = useSelector(state => state.app.isContrast);
	const fillColor = isContrast ? "yellow" : "#005CA9";

	const parseDate = string => {
		const splited = string.split("TO");

		const stringStartDate = splited[0].substring(1).startsWith("-")
			? splited[0].substring(2).trim()
			: splited[0].substring(1).trim();

		const stringEndDate = splited[1]
			.substring(1, splited[1].length - 1)
			.trim()
			.startsWith("-")
			? splited[1].substring(2, splited[1].length - 1).trim()
			: splited[1].substring(1, splited[1].length - 1).trim();

		const firstDate = stringStartDate === "*" ? "" : moment(stringStartDate, DATE_FORMAT).format(DATA_FORMAT_TO);
		const secondDate = stringEndDate === "*" ? "" : moment(stringEndDate, DATE_FORMAT).format(DATA_FORMAT_TO);

		const eraFrom = splited[0].substring(1).startsWith("-") ? "bc" : "ad";
		const eraTo = splited[1]
			.substring(1, splited[1].length - 1)
			.trim()
			.startsWith("-")
			? "bc"
			: "ad";

		// setYearRange([moment(firstDate).year(), moment(secondDate).year()]);

		change("from", firstDate);
		change("to", secondDate);
		change("toEra", eraTo);
		change("fromEra", eraFrom);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const timeParam = urlParams.getAll("f.dctermsIssued");

		const qParam = urlParams.getAll("q").toString();

		const searchDate = qParam.match(/dctermsIssued:\((.*?)\)/);

		if (timeParam && Array.isArray(timeParam) && timeParam.length > 0) {
			parseDate(timeParam[0]);
			return;
		} else if (searchDate && Array.isArray(searchDate) && searchDate.length > 0) {
			parseDate(searchDate[1]);
			return;
		}

		const firstDate = null;
		const secondDate = null;

		change("from", firstDate);
		change("to", secondDate);
		change("toEra", "ad");
		change("fromEra", "ad");
	}, [window.location]);

	// const handleSlider = (e, value) => {
	// 	setYearRange(value);
	// 	const from = moment([value[0], 0, 1]);
	// 	const to = moment([value[1], 11, 31]);
	// 	change("from", from);
	// 	change("to", to);
	// };

	// function AddOpacityToSlider() {
	// 	const sliderMarkers = document.getElementsByClassName("MuiSlider-valueLabelOpen");
	// 	if (sliderMarkers && sliderMarkers.length) {
	// 		const entries = Object.entries(sliderMarkers);
	// 		entries.forEach(([index, element]) => {
	// 			if (parseInt(index) % 2 === 0) {
	// 				element.style = "opacity: 0.9";
	// 			} else {
	// 				element.style = "opacity: 0.7";
	// 			}
	// 		});
	// 	}
	// }

	// useEffect(() => {
	// 	AddOpacityToSlider();
	// }, []);

	const dateFrom = formFrom ? moment(formFrom) : null;
	const dateTo = formTo ? moment(formTo) : null;

	const isOneYearAgo =
		dateFrom && dateTo
			? dateFrom.format(DATE_FORMAT) === oneYearAgo.format(DATE_FORMAT) &&
			  dateTo.format(DATE_FORMAT) === today.format(DATE_FORMAT)
			: false;
	const isThreeYearsAgo =
		dateFrom && dateTo
			? dateFrom.format(DATE_FORMAT) === threeYearsAgo.format(DATE_FORMAT) &&
			  dateTo.format(DATE_FORMAT) === today.format(DATE_FORMAT)
			: false;
	const isLastFiveYears =
		dateFrom && dateTo
			? dateFrom.format(DATE_FORMAT) === lastFiveYears.format(DATE_FORMAT) &&
			  dateTo.format(DATE_FORMAT) === today.format(DATE_FORMAT)
			: false;

	return (
		<form
			name="timeForm"
			onSubmit={handleSubmit(values => {
				return new Promise((resolve, reject) => {
					const errors = validate(values);
					if (Object.keys(errors).length) {
						reject(new SubmissionError(errors));
					} else {
						resolve(values);
					}
				}).then(val => {
					submitForm(val);
				});
			})}
		>
			<FormGroup
				sx={{
					marginTop: 2,
					width: 1,
					paddingLeft: 2.5,
				}}
			>
				{/* {
					<StyledSlider
						getAriaLabel={() => "years range"}
						value={yearRange}
						onChange={handleSlider}
						// getAriaValueText={valuetext}
						step={1}
						valueLabelDisplay="on"
						min={1}
						max={moment().year()}
						sx={{
							maxWidth: 365,
							paddingTop: 10,
							paddingBottom: 1,
						}}
						disableSwap
					/>
				} */}

				<FacetFormDateFields
					error={error}
					missingValues={missingValues}
					changeMissingTime={changeMissingTime}
					activeMissingTime={activeMissingTime}
				/>

				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					alignItems="flex-end"
					sx={{
						marginTop: 3,
						paddingBottom: 2,
					}}
				>
					{
						<FormControl>
							<RadioGroup
								name="time-chose-group"
								onChange={e => {
									const value = e.target.value;
									if (value === "lastYear") {
										change("from", oneYearAgo);
										change("to", today);
									} else if (value === "last3Years") {
										change("from", threeYearsAgo);
										change("to", today);
									} else if (value === "last5Years") {
										change("from", lastFiveYears);
										change("to", today);
									}
								}}
							>
								<FormControlLabel
									value="lastYear"
									control={<Radio checked={isOneYearAgo} />}
									label={t("results:facets.dateFacet.options.lastYear")}
								/>
								<FormControlLabel
									value="last3Years"
									control={<Radio checked={isThreeYearsAgo} />}
									label={t("results:facets.dateFacet.options.last3Years")}
								/>
								<FormControlLabel
									value="last5Years"
									control={<Radio checked={isLastFiveYears} />}
									label={t("results:facets.dateFacet.options.last5Years")}
								/>
							</RadioGroup>
						</FormControl>
					}
					<Button
						type="submit"
						variant="text"
						sx={[
							{
								color: fillColor,
								fontSize: 16,
								boxShadow: 0,
								marginRight: 3,
								paddingX: 2,
							},
							breakpoint && {
								marginRight: 4,
							},
						]}
					>
						{t("results:facets.apply")}
					</Button>
				</Stack>
			</FormGroup>
		</form>
	);
};

const localValidate = values => {
	const errors = {};
	if (values.from && values.to) {
		const from = moment(values.from);
		const to = moment(values.to);
		const fromEra = values.fromEra;
		const toEra = values.toEra;

		if (fromEra === "bc" && toEra === "ad") {
			return;
		} else if (fromEra === "ad" && toEra === "bc") {
			errors.to = "advanced_search:form.errors.to_exceeds_from";
		} else if (fromEra === "bc" && toEra === "bc") {
			if (from.isBefore(to)) {
				errors.to = "advanced_search:form.errors.to_exceeds_from";
			}
		} else if (from.isAfter(to)) {
			errors.to = "advanced_search:form.errors.to_exceeds_from";
		}
	}

	return errors;
};

FacetFormDate = reduxForm({
	form: "timeForm",
	validate: localValidate,
	enableReinitialize: true,
})(FacetFormDate);

const selector = formValueSelector("dctermsIssued");
FacetFormDate = connect(state => {
	const formTo = selector(state, "to");
	const formFrom = selector(state, "from");
	const fromEraForm = selector(state, "fromEra");
	const toEraForm = selector(state, "toEra");

	return {
		formFrom: formFrom,
		formTo: formTo,
		formFromEra: fromEraForm,
		formToEra: toEraForm,
	};
})(FacetFormDate);

export default FacetFormDate;
