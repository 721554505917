import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "react-html-parser";
import { reverse } from "named-urls";
import { Link } from "react-router-dom";

import wordpressApi from "services/wordpress";
import url from "router/urls";

import Loader from "@/components/Loader/Loader";

import { Button } from "@mui/material";

import styles from "./News.module.sass";
import { NewsItem } from "./NewsItem";

/**
 * News (homepage)
 * @memberof Components
 * @function News
 */

const News = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [loading, setLoading] = useState(true);
	const [newsList, setNewsList] = useState([]);

	const getNewsList = () => {
		wordpressApi
			.get(`wp/v2/posts?per_page=4&_embed`)
			.then(({ data }) => {
				setNewsList(data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getNewsList();
	}, []);

	if (loading) return <Loader />;

	return (
		<div className={styles["news"]}>
			<div className={` ${styles["news__container"]} container`}>
				<div className={styles["news__header"]}>
					<h2 className={styles["news__text--header"]}>{t("home:news.title")}</h2>
				</div>
				{newsList.length ? (
					<div className={styles["news__blocks"]}>
						<div className={styles["news__block"]}>
							<div className={` ${styles["news__item"]} ${styles["news__item--full"]} `}>
								<div className={styles["news__item--header"]}>
									<h3 className={styles["news__text--title"]}>
										{newsList[0].acf &&
										newsList[0].acf[`${language}_title`] &&
										newsList[0].acf[`${language}_title`] !== "" ? (
											<>{newsList[0].acf[`${language}_title`]}</>
										) : (
											<>{newsList[0].title?.rendered}</>
										)}
									</h3>
									<p className={styles["news__text--date"]}>
										{t("home:news.item.date")}:{" "}
										<strong>
											{new Date(newsList[0].date).toLocaleDateString(undefined, {
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											})}
										</strong>
									</p>
								</div>
								<div
									className={styles["news__item--pic"]}
									// eslint-disable-next-line no-prototype-builtins
									{...(newsList[0]._embedded.hasOwnProperty("wp:featuredmedia") && {
										style: {
											backgroundImage: `url(${newsList[0]._embedded["wp:featuredmedia"][0].source_url})`,
										},
									})}
								></div>
								<div className={styles["news__item--content"]}>
									{newsList[0].acf[`${language}_excerpt`] && newsList[0].acf[`${language}_excerpt`] !== "" ? (
										<p>{newsList[0].acf[`${language}_excerpt`]}</p>
									) : (
										<>
											{parse(newsList[0].excerpt?.rendered, {
												replace: ({ attribs }) => attribs && attribs.className === "read-more-link" && <></>,
											})}
										</>
									)}
								</div>
								<div className={styles["news__item--footer"]}>
									<Button variant="secondary" component={Link} to={reverse(url.news.item, { id: newsList[0].id })}>
										{t("home:news.item.more")}
									</Button>
								</div>
							</div>
						</div>
						<div className={styles["news__block"]}>
							<div className={styles["news__list"]}>
								{newsList.map((item, i) => {
									if (i > 0) return <NewsItem item={item} key={item.id} />;
								})}
							</div>
						</div>
					</div>
				) : (
					<div className={styles["news__empty"]}>{t("home:news.empty")}</div>
				)}

				<div className={styles["news__footer"]}>
					<Button variant="primary" component={Link} to={url.news.list}>
						{t("home:news.showAll")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default News;
