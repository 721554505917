import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";

import FacetFormItem from "./FacetFormItem";

import styles from "./FacetForm.module.sass";
import { useTranslation } from "react-i18next";

/**
 * Facet form component
 * @memberof Components
 * @function FacetForm
 */

const FacetForm = ({
	handleSubmit,
	facetItem,
	setChosenFacet,
	facetName,
	chosenFacet,
	setIsAdvenceFacetOpen,
	initialValues,
	initialize,
}) => {
	const [facetArr, setFacetArr] = useState([]);
	const [chosenList, setChosenList] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [maxFacets, setMaxFacets] = useState(5);

	const { t } = useTranslation();

	useEffect(() => {
		facetItem.forEach(item => {
			setFacetArr(prev => [...prev, item]);
		});
	}, []);

	useEffect(() => {
		if (!initialValues) return null;
		initialize(initialValues);
	}, []);

	useEffect(() => {
		if (!chosenFacet) return null;
		chosenFacet[`f.${facetName}`].map(value => {
			if (!facetArr) return null;
			facetArr.forEach(item => {
				if (item.name === value) {
					setChosenList(prev => [...prev, value]);
				}
			});
		});
	}, [chosenFacet, facetArr]);

	const handleClickForMore = () => {
		setIsAdvenceFacetOpen(prev => !prev);
		setChosenFacet(facetName);
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className={styles["form__content"]}>
				<ul className={styles["form__list"]}>
					{facetArr.map((item, index) =>
						index < maxFacets || chosenList.includes(item.name) ? (
							<Field
								type="checkbox"
								name={item.name}
								component={FacetFormItem}
								label={item.label}
								count={item.value}
								key={item.label}
								facetName={facetName}
								// checked={chosenList.includes(item.name) ? true : false}
							/>
						) : null
					)}
				</ul>
				{facetArr.length > 5 && facetArr.length !== maxFacets ? (
					<div className={styles["form__content--more"]} onClick={handleClickForMore}>
						{t("results:facets.more")}
					</div>
				) : null}
			</div>
		</form>
	);
};

export default reduxForm({
	form: "facetForm",
	enableReinitialize: true,
	onChange: (values, dispatch, props, previousValues) => {
		if (props.initialValues === null) {
			props.submit();
		} else if (Object.keys(previousValues).length === 0) {
			return null;
		} else {
			props.submit();
		}
	},
})(FacetForm);
