import { validateDateRange, isNotInRange, isNotProperFormat } from "./dateRangeValidation.js";

/**
 * form date validation util
 * @function
 */

export default function validate(values) {
	const errors = {};

	if (values.time) {
		if (!values.time.from && !values.time.to) {
			errors._error = "no_fields";
		} else {
			const fieldErrors = {};
			const { to, from, timeline } = values.time;

			if (!timeline) {
				if (to && isNotInRange(to)) {
					fieldErrors.to = isNotInRange(to);
				}
				if (to && isNotProperFormat(to)) {
					fieldErrors.to = isNotProperFormat(to);
				}
				if (from && isNotInRange(from)) {
					fieldErrors.from = isNotInRange(from);
				}
				if (from && isNotProperFormat(from)) {
					fieldErrors.from = isNotProperFormat(from);
				}
				if (to && from && validateDateRange(values.time)) {
					fieldErrors.from = validateDateRange(values.time);
				}
			}

			if (Object.keys(fieldErrors).length) {
				errors.time = fieldErrors;
			}
		}
	}
	return errors;
}
