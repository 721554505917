/**
 * 	@namespace Reducers
 * 	@description Store reducers
 */

const initialState = {
	suggestions: {},
	isLoadingSuggestions: false,
};

/**
 * Advanced search reducers
 *
 * @function advancedSearch
 * @memberof Reducers
 *
 */

export default function advancedSearch(state = initialState, action) {
	switch (action.type) {
		case "REQUEST_ADVANCED_SEARCH_SUGGESTIONS":
			return {
				...state,
				isLoadingSuggestions: true,
			};
		case "RECEIVE_ADVANCED_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: {
					...state.suggestions,
					[action.index]: action.data.suggestion,
				},
				isLoadingSuggestions: false,
			};
		case "RESET_ADVANCED_SEARCH_SUGGESTIONS":
			return {
				...state,
				suggestions: {
					...state.suggestions,
					[action.index]: {},
				},
			};
		default:
			return state;
	}
}
