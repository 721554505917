import { createTheme } from "@mui/material/styles";
import { theme } from "services/theme";

const $contrast_yellow = "#FFF500";
const $contrast_black = "#171514";
const $contrast_white = "#FFFFFF";
const $contrast_gray = "#808080";

function important(style) {
	return style + "!important";
}

export const contrastTheme = createTheme(theme, {
	palette: {
		action: {
			disabled: $contrast_gray,
		},
		primary: {
			main: $contrast_yellow,
		},
		contrast: {
			white: $contrast_white,
			yellow: $contrast_yellow,
			black: $contrast_black,
			gray: $contrast_gray,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					"&:hover": {
						//boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,
					},
				},

				primary: {
					background: $contrast_yellow,
					color: $contrast_black,

					"&:hover": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,
						background: $contrast_black,
						color: $contrast_yellow,
					},

					"&:focus": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},

					"&:active": {
						background: $contrast_black,
						color: $contrast_yellow,
						boxShadow: `0px 0px 20px -4px ${$contrast_yellow}`,
					},
				},

				primaryInv: {
					background: $contrast_black,
					color: $contrast_yellow,

					"&:hover": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,
						background: $contrast_yellow,
						color: $contrast_black,
					},

					"&:focus": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},

					"&:active": {
						background: $contrast_yellow,
						color: $contrast_black,
						boxShadow: `0px 0px 20px -4px ${$contrast_yellow}`,
					},
				},

				secondary: {
					background: $contrast_black,
					color: $contrast_yellow,
					boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,

					"&:hover": {
						background: $contrast_yellow,
						color: $contrast_black,
					},

					"&:focus": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},

					"&:active": {
						background: $contrast_yellow,
						color: $contrast_black,
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},
				},

				secondaryInv: {
					background: $contrast_yellow,
					color: $contrast_black,
					boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,

					"&:hover": {
						background: $contrast_black,
						color: $contrast_yellow,
					},

					"&:focus": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},

					"&:active": {
						background: $contrast_black,
						color: $contrast_yellow,
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},
				},

				nav: {
					color: $contrast_yellow,
				},

				search: {
					background: $contrast_yellow,
					color: $contrast_black,
					boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,

					"&.Mui-disabled": {
						color: $contrast_gray,
					},

					"&:hover": {
						boxShadow: `inset 0px 0px 0px 2px ${$contrast_yellow}`,
						background: $contrast_black,
						color: $contrast_yellow,
					},

					"&:focus": {
						boxShadow: `inset 0px 0px 0px 1px ${$contrast_yellow}, 0px 0px 20px -4px ${$contrast_yellow}`,
					},

					"&:active": {
						background: $contrast_black,
						color: $contrast_yellow,
						boxShadow: `0px 0px 20px -4px ${$contrast_yellow}`,
					},
				},

				link: {
					background: "transparent",
					color: $contrast_yellow,

					"&:hover, &:focus": {
						background: "transparent",
						textDecoration: "underline !important",
					},
				},

				linkInv: {
					background: "transparent",
					color: $contrast_yellow,
					textDecoration: "underline",

					"&:hover, &:focus": {
						background: "transparent",
						textDecoration: "underline",
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: $contrast_yellow,
					backgroundColor: $contrast_black,
					"&:hover": {
						color: important($contrast_black),
						backgroundColor: important($contrast_yellow),
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: $contrast_black,
					backgroundColor: $contrast_yellow,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				input: {
					color: $contrast_black,
					backgroundColor: $contrast_yellow,
				},
			},
		},

		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: $contrast_white,
					backgroundColor: $contrast_black,
				},
			},
		},
		MuiStack: {
			styleOverrides: {
				root: {
					color: $contrast_white,
					backgroundColor: $contrast_black,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					color: important($contrast_white),
					backgroundColor: important($contrast_black),
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					color: important($contrast_yellow),
					backgroundColor: important($contrast_black),
					"&:hover": {
						color: important($contrast_black),
						backgroundColor: important($contrast_yellow),
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				list: {
					border: "white",
					borderRadius: 0,
					borderStyle: "inset",
					borderWidth: "2px",
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: important($contrast_yellow),
					backgroundColor: important($contrast_black),

					"&:hover": {
						color: important($contrast_black),
						backgroundColor: important($contrast_yellow),
					},

					"&.menu-item-label-first": {
						color: important($contrast_white),
					},

					"&.menu-item-label": {
						color: important($contrast_white),
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: $contrast_white,
					backgroundColor: $contrast_black,
					"&.Mui-selected": {
						color: $contrast_black,
						backgroundColor: $contrast_yellow,
					},
				},
			},
		},
	},
});
