import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import url from "router/urls";
import { Link } from "react-router-dom";

import wordpressApi from "services/wordpress";
import menus from "router/menus";
import useStorageState from "scripts/useStorageState";
import WordpressMapListItem from "./WordpressMapListItem";
import storage from "scripts/storage";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";

import styles from "./Map.module.scss";

/**
 * Map
 * @memberof Components
 * @function Map
 */

const Map = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const keycloakUrl = useSelector(state => state.app.keycloakUrl);
	const wordpressUrl = useSelector(state => state.app.config.reactAppApiWp);

	const url_ariadnaBad = process.env.REACT_APP_MODULES_BAD;
	const url_ariadnaEdu = process.env.REACT_APP_MODULES_EDU;
	const url_ariadnaWol = process.env.REACT_APP_MODULES_WOL;

	const ariadnaWwwMySubjectsUrl = url.topics.my;
	const ariadnaWwwMyCollectionsUrl = url.collections.my;

	const ariadnaBadMyProjectsUrl = url.bad.myProjects;
	const ariadnaBadLimitsUrl = url.bad.limits;
	const ariadnaEduMyProjectsUrl = url.edu.myProjects;
	const ariadnaWolMyProjectsUrl = url.wol.myProjects;
	const url_userManagement = keycloakUrl + url.userManagement;
	const userProfile = keycloakUrl + url.userProfile;
	const wordpressPage = wordpressUrl + url.wordpressAdmin;

	const [wordpressMenuInformations, setWordpressMenuInformations] = useStorageState(`${language}-${menus.contact}`, {});
	const [wordpressMenuAbout, setWordpressMenuAbout] = useStorageState(`${language}-${menus.about}`, {});

	function getWordpressMenus() {
		setWordpressMenuInformations(storage.getObject(`${language}-${menus.contact}`, {}));
		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.contact}`)
			.then(({ data }) => {
				setWordpressMenuInformations(data);
			})
			.catch(() => {
				setWordpressMenuInformations({});
			});
		setWordpressMenuAbout(storage.getObject(`${language}-${menus.about}`, {}));
		wordpressApi
			.get(`menus/v1/menus/${language}-${menus.about}`)
			.then(({ data }) => {
				setWordpressMenuAbout(data);
			})
			.catch(() => {
				setWordpressMenuAbout({});
			});
	}

	useEffect(() => {
		getWordpressMenus();
	}, [language]);

	const breadcrumbsList = [{ name: t("default:breadcrumbs.home") }, { name: t("footer:menu.infoHelp.items.sitemap") }];

	return (
		<main className={`${styles["map"]} first-block`}>
			<Container>
				<Breadcrumbs path={breadcrumbsList} />
				<h1>{t("footer:menu.infoHelp.items.sitemap")}</h1>
				<section className={styles["tree"]}>
					<ul>
						{t("home:home")}
						<li>
							<ul>
								{t("home:collections.title")}
								<li>
									<Link to={{ pathname: url.collections.my, state: { isProtected: true } }}>
										{t("footer:menu.collections.items.myCollections")}
									</Link>
								</li>
								<li>
									<Link to={{ pathname: url.collections.list, state: { isProtected: false } }}>
										{t("footer:menu.collections.items.allCollections")}
									</Link>
								</li>
							</ul>
						</li>
						<li>
							<ul>
								{t("footer:menu.topics.title")}
								<li>
									<Link to={{ pathname: url.topics.my, state: { isProtected: true } }}>
										{t("footer:menu.topics.items.myTopics")}
									</Link>
								</li>
								<li>
									<Link to={{ pathname: url.topics.all, state: { isProtected: false } }}>
										{t("footer:menu.topics.items.allTopics")}
									</Link>
								</li>
							</ul>
						</li>
						<li>
							<ul>
								{t("header:menu.info.title")}
								<li>
									<a className={"link"}>{t("header:menu.infoHelp.items.sitemap")}</a>
								</li>
								<WordpressMapListItem menu={wordpressMenuInformations}></WordpressMapListItem>
							</ul>
						</li>
						<li>
							<WordpressMapListItem
								menu={wordpressMenuAbout}
								title={t("footer:menu.about.title")}
								isAbout={true}
							></WordpressMapListItem>
						</li>
						<li>
							<ul>
								{t("footer:menu.modules.title")}
								<li>
									<a href={url_ariadnaBad} target="_blank" rel="noreferrer noopener">
										{t("default:modules.bad")}
									</a>
								</li>
								<li>
									<a href={url_ariadnaEdu} target="_blank" rel="noreferrer noopener">
										{t("footer:menu.modules.items.edu")}
									</a>
								</li>
								<li>
									<a href={url_ariadnaWol} target="_blank" rel="noreferrer noopener">
										{t("default:modules.vol")}
									</a>
								</li>
							</ul>
						</li>
						<li>
							<ul>
								{t("default:account.my")}
								<li>
									<ul>
										{t("default:account.main")}
										<li>
											<Link to={{ pathname: url.account, state: { isProtected: true } }}>
												{t("default:account.my")}
											</Link>
										</li>
										<li>
											<a href={userProfile} target="_blank" rel="noreferrer">
												{t("default:account.profile")}
											</a>
										</li>
									</ul>
								</li>
								<li>
									<ul>
										{t("default:modules.www")}
										<li>
											<Link to={ariadnaWwwMyCollectionsUrl}>{t("footer:menu.collections.items.myCollections")}</Link>
										</li>
										<li>
											<Link to={ariadnaWwwMySubjectsUrl}>{t("footer:menu.topics.items.myTopics")}</Link>
										</li>
										<li>
											<a target="_blank" href={url_userManagement} rel="noreferrer">
												{t("default:account.userManagement")}
											</a>
										</li>
										<li>
											<a href={wordpressPage} target="_blank" rel="noreferrer">
												{t("default:account.serviceManagement")}
											</a>
										</li>
									</ul>
								</li>

								<li>
									<ul>
										{t("modules.bad")}
										<li>
											<a href={ariadnaBadMyProjectsUrl} target="_blank" rel="noreferrer">
												{t("default:account.myProjects")}
											</a>
										</li>
										<li>
											<a href={ariadnaBadLimitsUrl} target="_blank" rel="noreferrer">
												{t("default:account.management")}
											</a>
										</li>
									</ul>
								</li>
								<li>
									<ul>
										{t("modules.agr")}
										<li>
											<a href={ariadnaEduMyProjectsUrl} target="_blank" rel="noreferrer">
												{t("default:account.myProjects")}
											</a>
										</li>
									</ul>
								</li>
								<li>
									<ul>
										{t("modules.vol")}
										<li>
											<a href={ariadnaWolMyProjectsUrl} target="_blank" rel="noreferrer">
												{t("default:account.myProjects")}
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</section>
			</Container>
		</main>
	);
};

export default Map;
