import React from "react";
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import url from "../../router/urls";

/**
 * Wordpress menu
 * @memberof Components
 * @function WordpressMenu
 */

const WordpressMenu = ({ menu, isAbout = false }) => {
	const page = isAbout ? url.about : url.page;

	return (
		<>
			{menu?.items &&
				menu.items.map(item => (
					<>
						{item.child_items ? (
							<MenuItem key={item.title} classes={{ root: "menu-item-label" }}>
								{item.title}
							</MenuItem>
						) : (
							<MenuItem key={item.title} component={Link} to={reverse(page, { slug: item.slug.slice(3) })}>
								{item.title}
							</MenuItem>
						)}
						{item.child_items &&
							item.child_items.map((child, i) => (
								<MenuItem key={i} component={Link} to={reverse(page, { slug: child.slug.slice(3) })}>
									{child.title}
								</MenuItem>
							))}
					</>
				))}
		</>
	);
};
export default WordpressMenu;
