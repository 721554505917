import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import api from "services/api";
import storage from "scripts/storage";
import Loader from "@/components/Loader/Loader";

import styles from "./ResultsPages.module.sass";

import Results from "../Results/Results";
import WordpressContent from "@/components/WordpressContent/WordpressContent";
import * as Actions from "@/actions";
import moment from "moment/moment";

import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

/**
 * Shared collections results page
 * @memberof Containers
 * @function SharedCollectionResults
 */

const SharedCollectionResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { id } = useParams();

	const endpoint = "/api/list";

	const [loading, setLoading] = useState(true);
	const [record, setRecord] = useState({});
	const [itemsList, setItemsList] = useState([]);

	const getTimestamp = timestamp => {
		return moment(new Date(timestamp)).format(moment.localeData(language).longDateFormat("L"));
	};

	const getCollectionsItem = () => {
		api
			.get(`/shared-collections/${id}`)
			.then(({ data }) => {
				setLoading(false);
				setRecord(data);
				api
					.get(`/shared-collections/${id}/items`)
					.then(({ data }) => {
						setLoading(false);
						if (data.items && data.items.length) {
							const objectIds = data.items.map(x => x.value);
							setItemsList(objectIds);
							const from = window.location.pathname + window.location.search;
							storage.saveObject(from, objectIds);
						}
					})
					.catch(() => {
						setLoading(false);
					});
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getCollectionsItem();
	}, []);

	useEffect(() => {
		loadResults(location.search, searchParams, actions, endpoint);
		return () => actions.resetSearchParams();
	}, [itemsList]);

	useEffect(() => {
		if (!location) return;
		loadResults(location.search, searchParams, actions, endpoint);
	}, [location]);

	const loadResults = (searchLocation, searchParams, actions, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams({
			...newSearchParams,
			ids: itemsList.join(","),
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1,
			},
		});

		actions.loadResults(endpoint, searchUrl);
	};

	if (loading) return <Loader />;

	if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	const title = record.name;

	const path = [
		{
			name: t("default:breadcrumbs.collections"),
		},
		{
			name: title,
		},
	];

	return (
		<Results
			title={title}
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching}
			location={location}
			history={history}
			path={path}
		>
			<div className={styles["recomendedList__head"]}>
				<h1 className={styles["recomendedList__text--title"]}>{`${record.name}`}</h1>
				<em>{t("results:sharedCollection")}</em>
			</div>
			<div className={styles["recomendedList__banner"]}>
				<div className={styles["recomendedList__banner__content"]}>
					<div className={styles["recomendedList__banner__content__title"]}>
						<p className={styles["recomendedList__text--subtitle"]}>{record.name}</p>
						<p className={styles["recomendedList__text--aside"]}>
							{t("details:topics.objects")}:{" "}
							<span className={styles["recomendedList__text--dark"]}>{itemsList.length}</span>
						</p>
					</div>
					<p>
						{t("details:topics.creationDate")}: <strong>{getTimestamp(record.creationTimestamp)}</strong>
					</p>
					<div className={styles["recomendedList__banner__content__description"]}>{record.description}</div>
				</div>
			</div>
		</Results>
	);
};

SharedCollectionResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		results: state.results.results,
		facets: state.results.facets,
		category: state.results.category,
		count: state.results.count,
		links: state.results.links,
		isFetching: state.results.isFetching,
		searchParams: state.search.params,
		searchCategory: state.search["f.category"],
		searchSort: state.search.sortBy,
		searchSortOrder: state.search.sortOrder,
		searchFacets: state.search.facets,
		totalPages: state.search.totalPages,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedCollectionResults));
