import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { reverse } from "named-urls";
import { Link } from "react-router-dom";
import url from "router/urls";
import moment from "moment";

import { Button, Stack, Box, Paper } from "@mui/material";

import styles from "./NewsList.module.sass";

const DATE_FORMAT = "DD.MM.YYYY";

/**
 * News list item
 * @memberof Containers
 * @function NewsList
 */

export const NewsListItem = ({ item }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();

	let obrazek = null;
	// eslint-disable-next-line no-prototype-builtins
	if (item?._embedded?.hasOwnProperty("wp:featuredmedia") && Array.isArray(item._embedded["wp:featuredmedia"])) {
		if (item._embedded["wp:featuredmedia"].length > 0) {
			obrazek = item._embedded["wp:featuredmedia"][0]?.source_url;
		}
	}

	return (
		<Paper
			key={item.id}
			elevation={0}
			// className={styles['newsList__item']}
			sx={{
				padding: 4,
				backgroundColor: "#F9F9F9",
				marginBottom: 2,
			}}
		>
			<Stack
				alignItems={"flex-end"}
				justifyContent="space-between"
				sx={{
					flexDirection: "row",
					"@media (max-width: 767.98px)": {
						flexDirection: "column",
						alignItems: "flex-start",
					},
				}}
			>
				<Box
					sx={{
						"@media (max-width: 767.98px)": {
							width: "100%",
						},
					}}
				>
					<Stack
						alignItems={"flex-start"}
						spacing={2}
						sx={{
							flexDirection: "row",
							"@media (max-width: 767.98px)": {
								flexDirection: "column",
								alignItems: "center",
							},
						}}
					>
						<div className={styles["newsList__item--image"]}>
							{<img className={styles["newsList__item--image"]} src={obrazek} />}
						</div>

						<Stack
							direction={"column"}
							sx={{
								"@media (max-width: 767.98px)": {
									width: "100%",
									alignSelf: "flex-start",
								},
							}}
						>
							<div className={styles["newsList__item--content"]}>
								<h3 className={styles["newsList__text--head"]}>
									{item.acf[`${language}_title`] && item.acf[`${language}_title`] !== "" ? (
										<>{item.acf[`${language}_title`]}</>
									) : (
										<>{item.title?.rendered}</>
									)}
								</h3>
								<p className={styles["newsList__text--date-prefix"]}>
									{t("home:news.item.date")}:
									<span className={styles["newsList__text--date"]}>
										{moment(new Date(item.date)).format(DATE_FORMAT)}
									</span>
								</p>
								<div className={styles["newsList__item--excerpt"]}>
									{item.acf[`${language}_excerpt`] && item.acf[`${language}_excerpt`] !== "" ? (
										<p>{item.acf[`${language}_excerpt`]}</p>
									) : (
										<>{ReactHtmlParser(item.excerpt?.rendered)}</>
									)}
								</div>
							</div>
						</Stack>
					</Stack>
				</Box>
				<div className={styles["newsList__item--footer"]}>
					<Button
						variant="primary"
						component={Link}
						to={reverse(url.news.item, { id: item.id })}
						sx={{
							"@media (max-width: 767.98px)": { mt: "25px", width: "230px" },
						}}
					>
						{t("home:news.item.details")}
					</Button>
				</div>
			</Stack>
		</Paper>
	);
};
