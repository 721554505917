import React from "react";
import PropTypes from "prop-types";

import {
	setUrlFromParams,
	setPageSize,
	setPageByNum,
	setFacet,
	setCategory,
	resetCategory,
	resetFacet,
	resetAllFacets,
	resetQuery,
} from "@/scripts/resultsService.js";

import ResultsInfo from "@/containers/Results/parts/ResultsInfo/ResultsInfo";
import ResultsList from "@/containers/Results/parts/ResultsList/ResultsList";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import ResultsSidebar from "@/containers/Results/ResultsSidebar/Sidebar.js";
import SearchMain from "@/components/Search/SearchMain";

import Loader from "@/components/Loader/Loader";

import loadingImg from "@/assets/images/loading.svg";

import styles from "@/containers/Results/Results.module.sass";

import { useTranslation } from "react-i18next";

/**
 * Results page
 * @memberof Containers
 * @function Results
 */

const Results = ({
	title,
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	location,
	history,
	path,
	highlights,
	removeFunction,
	itemsList,
	children,
}) => {
	const { t, i18n } = useTranslation();

	const updateUrl = newParams => {
		const searchUrl = setUrlFromParams(newParams);

		history.push({
			pathname: location.pathname,
			search: `${searchUrl}`,
		});
	};

	const setPageSizeFunc = size => {
		setPageSize(size, updateUrl, {
			searchParams,
			searchCategory,
			searchFacets,
			searchSort,
			searchSortOrder,
		});
	};

	const setPageByNumFunc = num => {
		setPageByNum(num, updateUrl, {
			searchParams,
			searchCategory,
			searchFacets,
			searchSort,
			searchSortOrder,
		});
	};

	const setFacetFunc = (facet, missing) => {
		setFacet(
			facet,
			updateUrl,
			{
				searchParams,
				searchCategory,
				searchFacets,
				searchSort,
				searchSortOrder,
			},
			missing
		);
	};

	const resetFacetFunc = facet => {
		resetFacet(facet, updateUrl, {
			searchParams,
			searchCategory,
			searchFacets,
			searchSort,
			searchSortOrder,
		});
	};

	const resetAllFacetsFunc = () => {
		resetAllFacets(updateUrl, {
			searchParams,
			searchCategory,
			searchSort,
			searchSortOrder,
		});
	};

	const setCategoryFunc = obj => {
		setCategory(obj, updateUrl, {
			searchParams,
			searchSort,
			searchSortOrder,
		});
	};

	const setSort = (sort, order) => {
		updateUrl({
			params: {
				...searchParams,
				page: 1,
				// pageSize: 30
			},
			sortBy: sort,
			sortOrder: order,
			"f.category": searchCategory,
			facets: searchFacets,
		});
	};

	const resetCategoryFunc = () => {
		resetCategory(updateUrl, {
			searchParams,
			searchSort,
			searchSortOrder,
		});
	};

	const resetQueryFunc = () => {
		resetQuery(updateUrl);
	};

	const getQueryInfoSearch = () => {
		let res = "";

		if (searchParams.q) {
			const metadataRegexp = /(?<field>[a-zA-Z]+):\((?<txt>[\S\s]+)\)/g;
			const scopeRegexp = /(?<field>[a-z_A-Z]+):(?<txt>[\S\s]+)/g;
			const result = searchParams.q.split(/\s(AND|OR)\s/);
			result.forEach(el => {
				if (el.match(metadataRegexp)) {
					const results = el.matchAll(metadataRegexp);
					for (let result of results) {
						const { field, txt } = result.groups;
						let fieldName = t("metadata:fields." + field);
						res += `${fieldName}(${txt})`;
					}
				} else if (el.match(scopeRegexp)) {
					const results = el.matchAll(scopeRegexp);
					for (let result of results) {
						const { field, txt } = result.groups;

						if (i18n.exists("search:scope." + field)) {
							let fieldName = t("search:scope." + field);
							res += `${fieldName}(${txt})`;
						} else {
							res += `${field}:${txt}`;
						}

						
					}
				} else {
					res += " " + el + " ";
				}
			});
		}
		return res;
	};

	if (isFetching) return <Loader />;

	return (
		<div className={`${styles["results"]} first-block`}>
			<SearchMain isOpen={true} />
			<div className={`${styles["results__container"]} container`}>
				<div className={styles["results__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>
				{children ? (
					children
				) : (
					<div className={styles["results__info"]}>
						<ResultsInfo
							queryInfo={{
								querySearch: getQueryInfoSearch(),
								queryCount: count.results,
							}}
						/>
					</div>
				)}
				<div className={styles["results__blocks"]}>
					<div className={styles["results__block"]}>
						<ResultsSidebar
							searchParams={searchParams}
							searchCategory={searchCategory}
							searchFacets={searchFacets}
							facets={facets}
							category={category}
							count={count}
							links={links}
							setFacet={setFacetFunc}
							setCategory={setCategoryFunc}
							resetFacet={resetFacetFunc}
							resetCategory={resetCategoryFunc}
							resetAllFacets={resetAllFacetsFunc}
							isFetching={isFetching}
							resetQuery={resetQueryFunc}
						/>
					</div>
					<div className={styles["results__block"]}>
						<ResultsList
							title={title}
							results={results}
							totalResultNum={count.results}
							highlights={highlights}
							setPageByNum={setPageByNumFunc}
							setPageSize={setPageSizeFunc}
							searchParams={searchParams}
							totalPages={totalPages}
							searchSort={searchSort}
							searchSortOrder={searchSortOrder}
							setSort={setSort}
							searchFacets={searchFacets}
							removeFunction={removeFunction}
							itemsList={itemsList}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

Results.propTypes = {
	title: PropTypes.string,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

export default Results;
