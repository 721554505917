import React from "react";

import styles from "./Loader.module.scss";

/**
 * Loading circle
 * @memberof Components
 * @function Loading
 */

const Loader = () => {
	return (
		<div className={styles["loader"]}>
			<div className={styles["loader__circle"]}>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

export default Loader;
