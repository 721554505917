import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { reducer as formReducer } from "redux-form";
import app from "./app";
import search from "./search";
import authentication from "./authentication";
import results from "./results";
import mainPage from "./mainPage";
import indexes from "./indexes";
import advancedSearch from "./advancedSearch";
import map from "./map";
import notification from "./notification";

/**
 * Combine reducers
 *
 * @function combineReducers
 * @memberof Reducers
 *
 */

export default combineReducers({
	app,
	search,
	results,
	form: formReducer,
	mainPage,
	indexes,
	advancedSearch,
	map,
	authentication,
	notification,
	loadingBar: loadingBarReducer,
});
