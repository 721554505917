import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";

import CategoriesFormField from "./CategoriesFormField";

import styles from "./Categories.module.sass";

const CategoriesForm = ({ handleSubmit, category }) => {
	const { t } = useTranslation();

	return (
		<form onSubmit={handleSubmit}>
			<fieldset>
				<legend className="d-none">{t("results:facets.filters")}</legend>
				<ul className={styles["categories__catalogue-list"]}>
					{category.value.map(facet => (
						<li key={facet.label} className={styles["categories__catalogue-item"]}>
							<Field
								name="category"
								component={CategoriesFormField}
								value={facet.label}
								type="radio"
								count={facet.value}
								label={t(`results:facets.fields.${facet.label}`)}
							/>
						</li>
					))}
				</ul>
			</fieldset>
		</form>
	);
};

export default reduxForm({
	form: "categories",
	enableReinitialize: true,

	onChange: (values, action, props) => {
		if (values.category !== props.initialValues.category) {
			props.setCategory(values);
		}
	},
})(CategoriesForm);
