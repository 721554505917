import React, { useContext } from "react";

import styles from "./Home.module.sass";

import SearchMain from "@/components/Search/SearchMain";
import Hero from "@/components/home-page/Hero/Hero";
import Modules from "@/components/home-page/Modules/Modules";
import Collections from "@/components/home-page/Collections/Collections";
import Publications from "@/components/home-page/Publications/Publications";
import News from "@/components/home-page/News/News";
import Sources from "@/components/home-page/Sources/Sources";
import Financing from "@/components/home-page/Financing/Financing";

import promotionImg from "@/assets/images/promotion.jpg";
import promotionMobileImg from "@/assets/images/promotion_mobile.jpg";
import { WordpressAcfContext } from "@/contexts/WordpressAcfContext";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Main page
 * @memberof Containers
 * @function Main
 */

const Main = () => {
	const wordpressAcf = useContext(WordpressAcfContext);

	const matches = useMediaQuery("(max-width: 991.98px)");

	return (
		<div className={`${styles["home"]} first-block`}>
			<SearchMain />
			<Hero />
			<Modules />
			{!wordpressAcf?.value?.hideCollections && <Collections />}
			<Publications />

			<div className={` ${styles["home__container"]} ${styles["home--promotion"]} container`}>
				<img src={matches ? promotionMobileImg : promotionImg} alt="Obrazek promocja regionu" />
			</div>

			{!wordpressAcf?.value?.hideNews && <News />}
			<Sources />
			<Financing />
		</div>
	);
};

export default Main;
