import React from "react";

import FacetDate from "./FacetDate";
import FacetRegular from "./FacetRegular";

import styles from "./Facet.module.sass";

/**
 * facet component
 * @memberof Components
 * @function Facet
 */

const Facet = ({
	facet,
	value,
	searchFacets,
	setFacet,
	setMissing,
	resetFacet,
	setChosenFacet,
	isAdvenceFacetOpen,
	setIsAdvenceFacetOpen,
}) => {
	if (facet === "dctermsIssued") {
		return (
			<div className={styles["facet"]}>
				<FacetDate
					facet={facet}
					value={value}
					resetFacet={resetFacet}
					searchFacets={searchFacets}
					setFacet={setFacet}
					setMissing={setMissing}
				/>
			</div>
		);
	}

	return (
		<div className={styles["facet"]}>
			<FacetRegular
				facet={facet}
				resetFacet={resetFacet}
				setFacet={setFacet}
				value={value}
				searchFacets={searchFacets}
				setChosenFacet={setChosenFacet}
				isAdvenceFacetOpen={isAdvenceFacetOpen}
				setIsAdvenceFacetOpen={setIsAdvenceFacetOpen}
			/>
		</div>
	);
};

export default Facet;
