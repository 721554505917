import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { reverse } from "named-urls";
import { Link } from "react-router-dom";
import url from "router/urls";

import { Button } from "@mui/material";

import styles from "./News.module.sass";

/**
 * NewsItem (homepage)
 * @memberof Components
 * @function NewsItem
 */

export const NewsItem = ({ item }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();

	return (
		<div key={item.id} className={` ${styles["news__item"]} ${styles["news__item--short"]} `}>
			<div className={styles["news__item--header"]}>
				<h3 className={styles["news__text--title"]}>
					{item.acf[`${language}_title`] && item.acf[`${language}_title`] !== "" ? (
						<>{item.acf[`${language}_title`]}</>
					) : (
						<>{item.title?.rendered}</>
					)}
				</h3>
				<p className={styles["news__text--date"]}>
					{t("home:news.item.date")}:{" "}
					<strong>
						{new Date(item.date).toLocaleDateString(undefined, {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						})}
					</strong>
				</p>
			</div>
			<div className={styles["news__item--content"]}>
				{item.acf[`${language}_excerpt`] && item.acf[`${language}_excerpt`] !== "" ? (
					<p>{item.acf[`${language}_excerpt`]}</p>
				) : (
					<>{ReactHtmlParser(item.excerpt?.rendered)}</>
				)}
			</div>
			<div className={styles["news__item--footer"]}>
				<Button variant="secondary" component={Link} to={reverse(url.news.item, { id: item.id })}>
					{t("home:news.item.more")}
				</Button>
			</div>
		</div>
	);
};
