import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";

import wordpressApi from "services/wordpress";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import SearchMain from "@/components/Search/SearchMain";

import topicsImg from "@/assets/images/topics.svg";
import styles from "./TopicsList.module.sass";
import { TopicsListItem } from "./TopicsListItem";
import { changeUrlToRequest } from "@/scripts/parseUrl";
import { useMediaQuery } from "@mui/material";

import api from "services/api";

/**
 * Topic list page
 * @memberof Containers
 * @function TopicsList
 */

const TopicsList = () => {
	const { t } = useTranslation();
	const pageSize = 20;
	const [loading, setLoading] = useState(true);
	const [topicCount, setTopicCount] = useState(0);
	const [pagesCount, setPagesCount] = useState(0);
	const [page, setPage] = useState(1);
	const [topicsList, setTopicsList] = useState([]);
	const matches = useMediaQuery("(max-width: 991.98px)");

	const [expanded, setExpanded] = useState(false);

	async function getAllCounts(promises) {
		let counts = Array(topicCount).fill({ count: 0 });
		await Promise.all(promises)
			.then(result =>
				result.map(el => ({
					count: el.data.count,
				}))
			)
			.then(data => (counts = data));
		return counts;
	}

	const getTopicsList = () => {
		wordpressApi
			.get(`wp/v2/topics?page=${page}&per_page=${pageSize}&_embed`)
			.then(response => {
				setTopicCount(+response.headers["x-wp-total"]);
				setPagesCount(+response.headers["x-wp-totalpages"]);

				return response.data;
			})
			.then(response => {
				const promises = response.map(el => api.get(`/count?${changeUrlToRequest(el.acf.ids.replace(/^\?+/, ""))}`));
				const counts = getAllCounts(promises);
				counts
					.then(data => data.map((el, index) => ({ ...response[index], ...el })))
					.then(data => {
						setTopicsList(data);
						setLoading(false);
					});
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getTopicsList();
	}, [page]);

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	if (loading) return <Loader />;

	const path = [
		{
			name: t("header:menu.topics.title"),
		},
	];

	const setPageByNum = page => {
		setPage(page);
	};

	return (
		<div className={styles["topicsList"]}>
			<SearchMain className={styles["topicsList__search"]} />

			<div className={`${styles["topicsList__container"]} container`}>
				<div className={styles["topicsList__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["topicsList__head"]}>
					<h1 className={styles["topicsList__text--title"]}>
						<img src={topicsImg} alt="" />
						<div>
							<p>
								<span className={styles["topicsList__text--title--bold"]}>{t("header:menu.topics.title")}</span>
								<span>{` (${topicCount})`}</span>
							</p>
							<p className={styles["topicsList__text--info"]}>{t("topics:info")}</p>
						</div>
					</h1>
				</div>

				{topicsList.length > 0 && (
					<div className={styles["topicsList__pagination"]}>
						{matches ? (
							<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
						) : (
							<Pagination setPageByNum={setPageByNum} page={page} totalPages={pagesCount} />
						)}
					</div>
				)}

				<div className={styles["topicsList__content"]}>
					{topicsList.length ? (
						<>
							{topicsList.map((item, i) => (
								<TopicsListItem
									isExpanded={expanded === `panel${i}`}
									onChange={handleChange(`panel${i}`)}
									data={item}
									key={i}
								/>
							))}
						</>
					) : (
						<>{t("details:collections.none")}</>
					)}
				</div>
				{topicsList.length > 0 && (
					<div className={styles["topicsList__pagination"]}>
						<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
					</div>
				)}
			</div>
		</div>
	);
};

export default TopicsList;
