import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { showNotification } from "@/actions";
import { useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import api from "services/api";
import url from "router/urls";

import { Button } from "@mui/material";
import useToken from "@/components/hooks/useToken";
import HelpIcon from "@/components/helpIcon/HelpIcon";

import styles from "@/containers/Results/parts/ResultsInfo/ResultsInfo.module.sass";

const ResultsInfo = ({ queryInfo }) => {
	const { t } = useTranslation();
	const { querySearch, queryCount } = queryInfo;
	const location = useLocation();
	const dispatch = useDispatch();
	const { isLoggedIn, bearer, userId } = useToken(false);
	const [isAddTopicButtonBlocked, setAddTopicButtonBlocked] = useState(false);

	const addDynamicCollection = () => {
		setAddTopicButtonBlocked(true);

		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		const data = {
			name: window.location.href,
			type: "dynamic",
			queryString: window.location.href,
		};

		api
			.post(`/users/${userId}/dynamic-collections`, data, config)
			.then(() => {
				dispatch(showNotification(t("results:info.successNotification")));
				setAddTopicButtonBlocked(false);
			})
			.catch(() => {
				setAddTopicButtonBlocked(false);
			});
	};

	return (
		<div className={styles["results-info"]}>
			<div className={styles["results-info__title"]}>
				<h1 className={styles["results-info__text--title"]}>
					{location.state && location.state.latest ? (
						<>{t("home:publications.title.lastAdded")}</>
					) : (
						<>
							{querySearch.trim() === "*" ? (
								<>
									{t("results:info.allObjects")}: ({queryCount})
								</>
							) : (
								<>
									{t("results:info.heading")}: <strong>{querySearch}</strong> ({queryCount})
								</>
							)}
						</>
					)}
				</h1>
			</div>
			{isLoggedIn && (
				<div className={styles["results-info__buttons"]}>
					<Button
						disabled={isAddTopicButtonBlocked}
						variant="secondary"
						onClick={() => {
							addDynamicCollection();
						}}
						sx={{
							mr: "20px",
							"@media (max-width: 767.98px)": {
								paddingX: 3,
								mr: 0,
								width: "100%",
							},
						}}
					>
						{t("results:info.button")}
					</Button>
					<HelpIcon
						className={styles["results-info__buttons__help"]}
						text={ReactHtmlParser(t("results:info.buttonHelp", { myTopicsLink: url.topics.my }))}
					/>
				</div>
			)}
		</div>
	);
};

ResultsInfo.propTypes = {
	queryInfo: PropTypes.any,
};

export default ResultsInfo;
