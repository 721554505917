import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import url from "router/urls";
import storage from "scripts/storage";
import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error";

import wordpressApi from "services/wordpress";
import WordpressContent from "@/components/WordpressContent/WordpressContent";

import Results from "../Results/Results";
import * as Actions from "@/actions";

import collectionsImg from "@/assets/images/collections.svg";

import styles from "./CollectionPages.module.sass";
import resultsStyles from "./ResultsPages.module.sass";

import { formatDate } from "scripts/utils";

import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

/**
 * Collection results page
 * @memberof Containers
 * @function CollectionResults
 */

const CollectionResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { id } = useParams();

	const endpoint = "/api/list";

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [record, setRecord] = useState({});
	const [itemsList, setItemsList] = useState([]);
	const [resultsCount, setResultsCount] = useState(0);

	const getCollectionsItem = () => {
		wordpressApi
			.get(`wp/v2/collections/${id}?_embed`)
			.then(({ data }) => {
				setLoading(false);
				setRecord(data);
				const ids = data?.acf?.ids?.endsWith(",") ? data?.acf?.ids?.slice(0, -1) : data?.acf?.ids;
				const objectIds = ids.split(",") || [];
				setItemsList(objectIds);
				setResultsCount(objectIds == "" ? 0 : objectIds.length);
				const from = window.location.pathname + window.location.search;
				storage.saveObject(from, objectIds);
			})
			.catch(() => {
				setLoading(false);
				setError(true);
			});
	};

	useEffect(() => {
		getCollectionsItem();
	}, []);

	useEffect(() => {
		loadResults(location.search, searchParams, actions, endpoint);
		return () => actions.resetSearchParams();
	}, [itemsList]);

	useEffect(() => {
		if (!location) return;
		loadResults(location.search, searchParams, actions, endpoint);
	}, [location]);

	const loadResults = (searchLocation, searchParams, actions, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams({
			...newSearchParams,
			ids: itemsList.join(","),
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1,
			},
		});

		actions.loadResults(endpoint, searchUrl);
	};

	if (loading) return <Loader />;

	if (error) return <Error />;

	if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	const title =
		record.acf[`${language}_title`] && record.acf[`${language}_title`] !== ""
			? record.acf[`${language}_title`]
			: record.title?.rendered;

	const path = [
		{
			name: t("breadcrumbs.collections"),
			url: url.collections.list,
		},
		{
			name: title,
		},
	];

	return (
		<Results
			title={title}
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching}
			location={location}
			history={history}
			path={path}
		>
			<div className={styles["collectionsList"]}>
				<div className={styles["collectionsList__head"]}>
					<h1 className={styles["collectionsList__text--title"]}>
						<img src={collectionsImg} alt="collections" />
						<div>
							<span className={styles["collectionsList__text--title--bold"]}>{`${title}`}</span>
							<p>{t("details:collections.endorsementObjects")}</p>
						</div>
					</h1>
				</div>
				<div className={resultsStyles["recomendedList__banner"]}>
					{record._embedded["wp:featuredmedia"] && (
						<img
							className={resultsStyles["recomendedList__banner__logo"]}
							src={record._embedded["wp:featuredmedia"][0].source_url}
							alt=""
						/>
					)}
					<div className={resultsStyles["recomendedList__banner__content"]}>
						<div className={resultsStyles["recomendedList__banner__content__title"]}>
							<p className={resultsStyles["recomendedList__text--subtitle"]}>
								{language === "pl" ? record.title.rendered : record.acf[`${language}_title`] || record.title.rendered}
							</p>
							<p className={resultsStyles["recomendedList__text--aside"]}>
								{t("details:topics.objects")}:{" "}
								<span className={resultsStyles["recomendedList__text--dark"]}>{resultsCount}</span>
							</p>
						</div>
						<p>
							{t("details:topics.creationDate")}: {formatDate(record.date, language)}
							<span className={resultsStyles["recomendedList__text--separator"]}>|</span> {t("details:topics.author")}:{" "}
							{record._embedded.author[0].description}
						</p>
						<div className={resultsStyles["recomendedList__banner__content__description"]}>
							<WordpressContent
								content={
									language === "pl"
										? record.content.rendered || record.excerpt.rendered
										: record.acf[`${language}_full`] || record.acf[`${language}_excerpt`]
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</Results>
	);
};

CollectionResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		results: state.results.results,
		facets: state.results.facets,
		category: state.results.category,
		count: state.results.count,
		links: state.results.links,
		isFetching: state.results.isFetching,
		searchParams: state.search.params,
		searchCategory: state.search["f.category"],
		searchSort: state.search.sortBy,
		searchSortOrder: state.search.sortOrder,
		searchFacets: state.search.facets,
		totalPages: state.search.totalPages,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionResults));
