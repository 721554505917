import React from "react";
import { useTranslation } from "react-i18next";

import styles from "@/components/Pagination/Pagination.module.sass";

/**
 * Pagination previous button
 * @memberof Components
 * @function PaginationPrevious
 */

const PaginationPrevious = ({ setPreviousPage }) => {
	const { t } = useTranslation();
	return (
		<button
			className={styles["pagination__previous"]}
			onClick={() => setPreviousPage()}
			title={t("pagination:prevTitle")}
		>
			<span>{t("pagination:prev")}</span>
		</button>
	);
};

export default PaginationPrevious;
