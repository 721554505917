/**
 * change url to request
 * @param {string} url - url
 * @function
 */

export const changeUrlToRequest = url => {
	const queryParams = url.split("&");

	let queryScope = queryParams.filter(el => el.startsWith("scope="));
	if (queryScope.length > 0) queryScope = queryScope[0].match(/scope=(.*)/)[1];

	let q = queryParams.filter(el => el.startsWith("q="));
	if (q.length > 0) q = q[0].match(/q=(.*)/)[1];
	else return url;

	q = q.replace(/%20/g, " ");
	q = q.replace(/%3A/g, ":");
	//eslint-disable-next-line
	q = q.replace(/(?<!in):+(?!(?![\(])|[^\s:]*in:)/g, ":in:");
	//eslint-disable-next-line
	q = q.replace(/(?<!in):+(?!(?!["%28"])|[^\s:]*in:)/g, ":in:");
	q = q.split(/\s(AND|OR)\s/);
	if (queryScope.length > 1) q[0] = `${queryScope}:in:(${q[0]})`;
	q = q.join(" ");
	q = "q=" + q;

	return queryParams
		.map(el => {
			if (el.startsWith("q=")) return q;
			return el;
		})
		.join("&");
};
