import React, { useState } from "react";

import CategoriesForm from "./CategoriesForm.js";
import CategoriesHeader from "./CategoriesHeader.js";
import CategoriesInfo from "./CategoriesInfo.js";

import styles from "./Categories.module.sass";

const Categories = ({ setCategory, resetCategory, searchCategory, category }) => {
	const [active, setActive] = useState(false);

	// const submitFiltersCategoriesForm = e => {
	// 	e.preventDefault();
	// 	setCategory();
	// };

	const toggleActive = () => {
		setActive(prev => !prev);
	};

	return (
		<div>
			<CategoriesHeader isActive={active} toggleActive={toggleActive} />
			<div
				className={`${styles["categories__catalogue"]} ${active ? `${styles["categories__catalogue--hidden"]}` : ""}`}
			>
				<CategoriesInfo isActive={active} searchCategory={searchCategory} resetCategory={resetCategory} />
				{category && category.value && category.value.length && (
					<CategoriesForm
						onSubmit={setCategory}
						setCategory={setCategory}
						initialValues={{ category: searchCategory }}
						category={category}
					/>
				)}
			</div>
		</div>
	);
};

export default Categories;
