import React, { useEffect, useState } from "react";

import { initialValuesDate, getCurrentFacet, getDateValues } from "./utils/formsService";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";

import FacetFormDate from "./FacetForm/FacetFormDate";

import styles from "./Facet.module.sass";

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

/**
 * Date facet component
 * @memberof Components
 * @function FacetDate
 */

const FacetDate = ({
	facet,
	value,
	searchFacets,
	// eslint-disable-next-line no-unused-vars
	resetFacet,
	setFacet,
	missing,
	missingValues,
	values,
	// eslint-disable-next-line no-unused-vars
	fieldList,
}) => {
	const [active, setActive] = useState(true);
	const [activeMissingTime, setActiveMissingTime] = useState(true);

	const { t } = useTranslation();

	const breakpoint = useMediaQuery("(max-width: 767.98px)");

	const submitDateFacetForm = value => {
		setFacet({ [facet]: getDateValues(value, facet, value.timeline) }, { [`missing`]: value.timeline ? "time" : "" });
		setActive(prev => !prev);
	};

	// const removeFacetForm = (facetValue, facetName, prefix) => {

	// 	const currentFacet = getCurrentFacet(searchFacets, facetName);

	// 	const filteredArr = currentFacet[`f.${facetName}`];

	// 	if (prefix === "from") {
	// 		filteredArr[0] = "*";
	// 	}

	// 	if (prefix === "to") {
	// 		filteredArr[1] = "*";
	// 	}

	// 	if (filteredArr.filter(item => item !== "*").length) {
	// 		setFacet({ [facet]: filteredArr });
	// 	} else {
	// 		resetFacet(facet);
	// 	}
	// };

	const toggleDropdown = () => {
		setActive(prev => !prev);
	};

	const resetAll = () => {
		change(facet, "from", "");
		change(facet, "fromEra", "ad");
		change(facet, "to", "");
		change(facet, "toEra", "ad");
		change(facet, "timeline", false);
	};

	useEffect(() => {
		changeMissingTime(missing !== "time");
	}, []);

	const changeMissingTime = value => {
		setActiveMissingTime(value);
	};

	const currentFacet = getCurrentFacet(searchFacets, facet);
	const missingTime = missing === "time";
	const isSelected = values && (!!values.from || !!values.to || values.timeline !== missingTime);

	return (
		<div className={styles["facet"]}>
			<Button
				className={styles["facet__toggle"]}
				variant="contained"
				// disabled={!value || !value.length}
				id="facet"
				onClick={toggleDropdown}
				endIcon={active ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
				sx={[
					{
						bgcolor: "#4A4A4A",
						boxShadow: "none",
						textAlign: "left",
						justifyContent: "space-between",
						paddingLeft: 2.5,
						paddingTop: 3,
						paddingBottom: 3,
						transition: 0.5,
						fontSize: 18,
						color: "#FFFFFF",
						"&:hover": {
							bgcolor: "#4A4A4A",
							boxShadow: "none",
						},
						"&.Mui-disabled": {
							color: "#7f7f7f",
							bgcolor: "#c0c0c0",
							borderColor: "#c0c0c0",
						},
					},
					breakpoint && {
						bgcolor: "#727272",
						paddingTop: 3,
					},
				]}
			>
				{t(`results:facets.fields.${facet}`)}
			</Button>
			{active && (
				<div className={styles["facet__menu"]}>
					<FacetFormDate
						onSubmit={submitDateFacetForm}
						submitForm={submitDateFacetForm}
						initialValues={initialValuesDate(currentFacet, facet, missingTime)}
						form={facet}
						resetAll={resetAll}
						isSelected={isSelected}
						facetValue={value}
						missingValues={missingValues}
						changeMissingTime={changeMissingTime}
						activeMissingTime={activeMissingTime}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	values: getFormValues(ownProps.facet)(state),
	missingValues: state.results.missingValues,
	missing: state.search.missing,
});

export default connect(mapStateToProps, { change })(FacetDate);
