import { reduxForm } from "redux-form";
import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import AdvancedSearchItem from "@/components/Search/AdvancedSearchItem.js";
import FieldWithSuggester from "@/components/Search/FieldWithSuggester";
import styles from "@/components/Search/Search.module.sass";
import { useTranslation } from "react-i18next";
import wordpressApi from "services/wordpress";
import { Link } from "react-router-dom";
import api from "services/api";
import { changeUrlToRequest } from "@/scripts/parseUrl";

import { Button, Stack, MenuItem, FormControl, Divider, Hidden, InputLabel } from "@mui/material";

import Select from "@mui/material/Select";

/**
 * search form
 * @memberof Components
 * @function SearchForm
 */

function SearchForm({
	handleSubmit,
	params,
	// eslint-disable-next-line no-unused-vars
	valid,
	// eslint-disable-next-line no-unused-vars
	pristine,
	// eslint-disable-next-line no-unused-vars
	submitting,
	// eslint-disable-next-line no-unused-vars
	getAutocompleteList,
	// eslint-disable-next-line no-unused-vars
	suggestions,
	// eslint-disable-next-line no-unused-vars
	resetAdvancedSearchSuggestions,
	suggestionsAdvanced,
	variantClass,
	change,
}) {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const TOPICS_LIMIT = 9;
	const TOPICS_CHARACTER_LIMIT = 20;

	const menuScope = [];
	for (let f in t("search:scope", { returnObjects: true })) menuScope.push({ id: f, label: t("search:scope." + f) });

	const advSearchTypes = [];
	advSearchTypes.push({ id: "allFields", label: t("search:allFields") });
	for (let f in t("metadata:fields", { returnObjects: true }))
		advSearchTypes.push({ id: f, label: t("metadata:fields." + f) });

	const parseQuery = (q, scope) => {
		let query = null;
		let advItem = [];
		if (params.q) {
			const dateRegexp = /(?<field>[a-zA-Z]+):\((?<txt>[\S\s]+)\)/g;
			const result = q.split(/\s(AND|OR)\s/);

			let item = null;
			result.forEach(el => {
				if (el.match(dateRegexp)) {
					if (!item) {
						item = { idx: 1, operator: "and", type: null, text: null };
						advItem.push(item);
					}
					const results = el.matchAll(dateRegexp);
					for (let result of results) {
						let { field, txt } = result.groups;
						if (item) {
							item.type = field;
							item.text = txt;
						}
					}
				} else if (el == "AND" || el == "OR") {
					item = { idx: advItem.length + 1, operator: el.toLowerCase(), type: "", text: "" };
					advItem.push(item);
				} else {
					if (!query) query = el;
					else if (item) {
						item.text = el;
						item.type = advSearchTypes[0].id;
					}
				}
			});
		}

		if (!scope) scope = "allFields";

		selectScope(scope);
		query && setText(query);

		return { query: query, advItem: advItem, scope: scope };
	};

	const [scope, selectScope] = useState("allFields");
	const [text, setText] = useState("");
	const [normalMode, setNormalMode] = useState(true);
	const [disabledSubmit, setDisabledSubmit] = useState(true);
	const [advItems, setAdvItems] = useState([]);
	const [topics, setTopics] = useState(null);
	const initValues = useMemo(() => parseQuery(params.q, params.scope), []);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		let searchDisabled = false;
		let newQuery = text;
		searchDisabled = newQuery.length == 0 && advItems.length == 0;
		advItems.forEach(element => {
			const operatorVal = element.operator;
			const typeVal = element.type;
			const textVal = element.text;
			if (newQuery.length > 0) newQuery += ` ${operatorVal.toUpperCase()} `;

			if (typeVal === "allFields") newQuery += `${textVal}`;
			else newQuery += `${typeVal}:(${textVal})`;

			searchDisabled = searchDisabled || textVal.length == 0;
		});

		setDisabledSubmit(searchDisabled);

		let v = scope;
		if (v == "allFields") v = "";

		change("scope", v);
		change("q", newQuery);
	}, [advItems, text, scope]);

	useEffect(() => {
		getAutocompleteList(scope, text, 0);
	}, [text]);

	const getAllCounts = async promises => {
		let counts = Array(TOPICS_LIMIT).fill({ count: 0 });
		await Promise.allSettled(promises)
			.then(result => {
				return result.map(el => {
					return (
						el.status === "fulfilled" && {
							count: el.value.data.count,
						}
					);
				});
			})
			.then(data => (counts = data));
		return counts;
	};

	useEffect(() => {
		if (topics) return;
		const controller = new AbortController();

		wordpressApi
			.get(`wp/v2/topics?page=1&per_page=${TOPICS_LIMIT}`, { signal: controller.signal })
			.then(response => response.data)
			.then(topicList => {
				const promises = topicList.map(el => api.get(`/count?${changeUrlToRequest(el.acf.ids.replace(/^\?+/, ""))}`));
				const counts = getAllCounts(promises);
				counts
					.then(data => data.map((el, index) => el?.count != undefined && { ...topicList[index], ...el }))
					.then(data => setTopics(data.filter(e => e)));
			})
			.catch(err => console.error(err));

		return () => {
			controller.abort();
		};
	}, [topics]);

	useEffect(() => {
		if (initValues && initValues.advItem.length > 0) setAdvItems([...initValues.advItem]);
	}, [initValues]);

	const updateAdvItems = item => {
		if (item) {
			let newItems = advItems;
			const index = newItems.map(e => e.idx).indexOf(item.idx);
			newItems[index] = item;
			setAdvItems([...newItems]);
		}
	};

	const addNewItem = () => {
		const idx = advItems.length;
		setAdvItems([...advItems, { idx: idx + 1, operator: "and", type: advSearchTypes[0].id, text: "" }]);
	};

	const removeItem = idx => {
		let filtered = advItems.filter(value => value.idx != idx);
		setAdvItems(filtered);
	};

	const handleChange = event => {
		let v = event.target.value;
		selectScope(v);

		if (v == "allFields") v = "";
		change("scope", v);
	};

	const handelSearchTexChange = value => {
		setText(value.replace(/<[^>]*>/gi, ""));
	};

	const searchAllClick = () => {
		change("q", "*");
		change("scope", "");
	};

	const clearAll = () => {
		setText("");
		setAdvItems([]);
	};

	function limitText(text, limit) {
		if (text.length <= limit) return text;

		return text.substring(0, limit) + "...";
	}

	return (
		<form
			id="searchForm"
			onSubmit={handleSubmit}
			className={`${styles["search__form"]} ${styles[variantClass]}`}
			autoComplete="off"
		>
			<Button type="submit" style={{ display: "none" }}>
				Submit
			</Button>
			<div className={`${styles["search__form-wrapper"]} ${styles[variantClass]}`}>
				<Stack direction={{ xs: "column-reverse", sm: "row" }} justifyContent="space-between">
					<div className={styles["search__form-tabs"]}>
						<Button
							variant={normalMode ? "primaryInv" : "secondaryInv"}
							sx={{
								minWidth: "200px",
								"@media (max-width: 991.98px)": {
									padding: "15px 16px",
									fontSize: "16px",
									minWidth: "auto",
								},
							}}
							disableFocusRipple={true}
							disableRipple={true}
							onClick={() => setNormalMode(true)}
						>
							{t("search:tabs.search")}
						</Button>
						<Button
							variant={normalMode ? "secondaryInv" : "primaryInv"}
							sx={{
								minWidth: "200px",
								"@media (max-width: 991.98px)": {
									padding: "15px 16px",
									fontSize: "16px",
									minWidth: "auto",
								},
							}}
							disableFocusRipple={true}
							disableRipple={true}
							onClick={() => setNormalMode(false)}
						>
							{t("search:tabs.themes")}
						</Button>
					</div>
					{normalMode && (
						<Button
							type="submit"
							variant="linkInv"
							disableFocusRipple={true}
							onClick={searchAllClick}
							sx={{
								"@media (max-width: 991.98px)": {
									fontSize: "16px",
									fontWeight: "normal",
								},
							}}
						>
							{t("search:searchAll")}
						</Button>
					)}
				</Stack>
				{normalMode ? (
					<Stack spacing={2.5}>
						<div className={styles["search__searcharea--wrapper"]}>
							<InputLabel htmlFor="dropdown-basic-id" id="label" style={{ display: "none" }}>
								Wybierz źródło
							</InputLabel>
							<Stack
								className={styles["search__searcharea"]}
								alignItems="center"
								direction={{ xs: "column", sm: "row" }}
								spacing={2}
								divider={
									<Hidden only="xs">
										<Divider orientation="vertical" flexItem />
									</Hidden>
								}
							>
								<Select
									className={styles["search__select"]}
									variant="standard"
									id="dropdown-basic-id"
									labelId="label"
									defaultValue={initValues.scope}
									onChange={handleChange}
									inputProps={{
										id: "dropdown-basic-id",
									}}
									sx={{
										".MuiSvgIcon-root ": {
											fill: "#005CA9",
										},
										pt: { xs: "15px", sm: "0" },
										pb: { xs: "15px", sm: "0" },
									}}
								>
									{menuScope.map((option, index) => (
										<MenuItem key={index} value={option.id}>
											{option.label}
										</MenuItem>
									))}
								</Select>
								<FormControl fullWidth>
									<FieldWithSuggester
										suggestionsAdvanced={suggestionsAdvanced[0]}
										id={0}
										scope={scope}
										value={text}
										handelSearchTexChange={handelSearchTexChange}
										setOpen={setOpen}
										open={open}
									/>
									{advItems.length == 0 && (
										<Hidden smUp>
											<Button
												type="submit"
												variant="search"
												disabled={disabledSubmit}
												disableFocusRipple={true}
												disableRipple={true}
												className={styles["search__button--higher"]}
												sx={{ ml: { xs: "-13px", sm: "0" }, mr: { xs: "-13px", sm: "0" } }}
											>
												<span>{t("search:search")}</span>
											</Button>
										</Hidden>
									)}
								</FormControl>
							</Stack>

							{advItems.length == 0 && (
								<>
									<Hidden smDown>
										<Button
											type="submit"
											variant="search"
											disabled={disabledSubmit}
											disableFocusRipple={true}
											disableRipple={true}
											className={styles["search__button--higher"]}
											sx={{ marginLeft: "4px" }}
										>
											<span>{t("search:search")}</span>
										</Button>
									</Hidden>
									<Hidden lgDown>
										<Button
											variant="secondaryInv"
											className={styles["search__button--add"]}
											size="large"
											sx={{ ml: "30px", minWidth: "310px", whiteSpace: "nowrap" }}
											disableRipple={true}
											disableFocusRipple={true}
											onClick={addNewItem}
										>
											{t("search:advanced_add")}
										</Button>
									</Hidden>
								</>
							)}
						</div>

						{advItems.length == 0 && (
							<Hidden lgUp>
								<Button
									variant="secondary"
									className={styles["search__button--add"]}
									disableRipple={true}
									disableFocusRipple={true}
									onClick={addNewItem}
								>
									{t("search:advanced_add")}
								</Button>
							</Hidden>
						)}
						{advItems.map((item, index) => (
							<AdvancedSearchItem
								key={index}
								item={item}
								menuScope={advSearchTypes}
								onRemove={removeItem}
								updateQuery={updateAdvItems}
								getAutocompleteList={getAutocompleteList}
								suggestionsAdvanced={suggestionsAdvanced[index + 1]}
							/>
						))}

						{advItems.length > 0 && (
							<Stack
								className={styles["search__actions-area"]}
								direction={{ xs: "column", sm: "row" }}
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
							>
								<Button
									variant="primary"
									className={styles["search__button--higher"]}
									disableRipple={true}
									disableFocusRipple={true}
									sx={{ padding: "25px 50px", border: "3px solid #FFFFFF", "&:hover": { boxShadow: 0 } }}
									onClick={addNewItem}
								>
									{t("search:advanced_add")}
								</Button>
								<Stack
									direction="row"
									spacing={2}
									justifyContent="space-between"
									sx={{ width: { xs: "100%", sm: "auto" } }}
								>
									<>
										<Button
											type="button"
											variant="primary"
											disableFocusRipple={true}
											disableRipple={true}
											className={styles["search__button--clear"]}
											sx={{ padding: "25px 50px", border: "3px solid #FFFFFF", "&:hover": { boxShadow: 0 } }}
											onClick={clearAll}
										>
											<span>{t("search:clear")}</span>
										</Button>

										<Button
											type="submit"
											variant="search"
											disabled={disabledSubmit}
											disableFocusRipple={true}
											disableRipple={true}
											className={styles["search__button--higher"]}
											sx={{ border: "3px solid #FFFFFF" }}
										>
											<span>{t("search:search")}</span>
										</Button>
									</>
								</Stack>
							</Stack>
						)}
					</Stack>
				) : (
					<Stack spacing={2.5}>
						<div className={styles["search__searcharea__themes"]}>
							{topics &&
								topics.map(el => (
									<Button
										className={styles["search__searcharea__themes--item"]}
										variant="primaryInv"
										sx={{ px: "25px" }}
										key={el.id}
										component={Link}
										to={`/topics/${el.id}?${el.acf.ids.replace(/^\?+/, "")}`}
									>
										{limitText(
											language === "pl" ? el.title.rendered : el.acf[`${language}_title`] || el.title.rendered,
											TOPICS_CHARACTER_LIMIT
										)}{" "}
										({el.count})
									</Button>
								))}
						</div>
						<Button
							variant="secondaryInv"
							sx={{ width: "fit-content" }}
							disableFocusRipple={true}
							disableRipple={true}
							component={Link}
							to={"/allTopics"}
						>
							{t("search:searchAllTopics")}
						</Button>
					</Stack>
				)}
			</div>
		</form>
	);
}

export default withRouter(
	reduxForm({
		initialValues: {
			q: "",
			scope: "",
		},
	})(SearchForm)
);
