import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import wordpressApi from "services/wordpress";

import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import SearchMain from "@/components/Search/SearchMain";

import styles from "./NewsList.module.sass";

import { NewsListItem } from "./NewsListItem";

const PAGE_SIZE = 20;

/**
 * News list
 * @memberof Containers
 * @function NewsList
 */

const NewsList = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [newsCount, setNewsCount] = useState(0);
	const [pagesCount, setPagesCount] = useState(0);
	const [newsList, setNewsList] = useState([]);

	const getNewsList = () => {
		wordpressApi
			.get(`wp/v2/posts?page=${page}&per_page=${PAGE_SIZE}&_embed`)
			.then(response => {
				setNewsCount(+response.headers["x-wp-total"]);
				setPagesCount(+response.headers["x-wp-totalpages"]);
				setNewsList(response.data);
			})
			.catch(() => {})
			.then(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getNewsList();
	}, [page]);

	if (loading) return <Loader />;

	const path = [
		{
			name: t("breadcrumbs.news"),
		},
	];

	const setPageByNum = newPage => {
		setPage(newPage);
	};
	return (
		<div className={styles["newsList"]}>
			<SearchMain className={styles["newsList__search"]} />
			<div className={`${styles["newsList__container"]} container`}>
				<div className={styles["newsList__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["newsList__head"]}>
					<h1 className={styles["newsList__text--title"]}>{t("breadcrumbs.news")}</h1>
				</div>

				{newsCount > 0 && (
					<div className={styles["newsList__pagination"]}>
						<Pagination setPageByNum={setPageByNum} page={page} totalPages={pagesCount} />
					</div>
				)}

				<div className={styles["newsList__content"]}>
					{newsList.length ? (
						<>
							{newsList.map(item => (
								<NewsListItem item={item} key={item.id} />
							))}
						</>
					) : (
						<>{t("details:collections.none")}</>
					)}
				</div>

				{newsCount > 0 && (
					<div className={styles["newsList__pagination"]}>
						<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
					</div>
				)}
			</div>
		</div>
	);
};

export default NewsList;
