import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import wordpressApi from "services/wordpress";
import url from "router/urls";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import SearchMain from "@/components/Search/SearchMain";

import styles from "./NewsItem.module.sass";

import moment from "moment/moment";

const DATE_FORMAT = "DD.MM.YYYY";

/**
 * News item
 * @memberof Containers
 * @function NewsItem
 */

const NewsItem = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [item, setItem] = useState({});

	const getNewsItem = () => {
		wordpressApi
			.get(`wp/v2/posts/${id}?_embed`)
			.then(({ data }) => {
				setLoading(false);
				setItem(data);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getNewsItem();
	}, []);

	if (loading) return <Loader />;

	if (Object.keys(item).length === 0 && item.constructor === Object) return <Loader />;

	let obrazek = null;
	// eslint-disable-next-line no-prototype-builtins
	if (item?._embedded?.hasOwnProperty("wp:featuredmedia") && Array.isArray(item._embedded["wp:featuredmedia"])) {
		if (item._embedded["wp:featuredmedia"].length > 0) {
			obrazek = item._embedded["wp:featuredmedia"][0]?.source_url;
		}
	}

	const title =
		item.acf[`${language}_title`] && item.acf[`${language}_title`] !== ""
			? item.acf[`${language}_title`]
			: item.title?.rendered;
	const content =
		item.acf[`${language}_full`] && item.acf[`${language}_full`] !== ""
			? item.acf[`${language}_full`]
			: item.content?.rendered;

	const path = [
		{
			name: t("breadcrumbs.news"),
			url: url.news.list,
		},
		{
			name: title,
		},
	];

	return (
		<div className={styles["newsItem"]}>
			<SearchMain className={styles["newsItem__search"]} />

			<div className={`${styles["newsItem__container"]} container`}>
				<div className={styles["newsItem__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["newsItem__head"]}>
					<h1 className={styles["newsItem__head--title"]}>{t("home:news.more")}</h1>
				</div>

				<div className={styles["newsItem__content"]}>
					<div className={styles["newsItem__content__left"]}>
						<div className={styles["newsItem__content--image"]}>{<img src={obrazek} />}</div>
					</div>

					<div className={styles["newsItem__content__right"]}>
						<div>
							<h3 className={styles["newsItem__content--text__title"]}>{title}</h3>
						</div>

						<div className={styles["newsItem__content--text__date"]}>
							<p>
								{t("home:news.item.date")}:
								<span className={styles["newsItem__content--text__date-value"]}>
									{moment(new Date(item.date)).format(DATE_FORMAT)}
								</span>
							</p>
						</div>

						<div className={styles["newsItem__content--text__content"]}>
							<>{ReactHtmlParser(content)}</>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsItem;
