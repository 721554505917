import moment from "moment";

export const initialValues = (currentFacet, facet) => {
	if (currentFacet && currentFacet[`f.${facet}`]) {
		const facetValue = currentFacet[`f.${facet}`].reduce((obj, key) => {
			return {
				...obj,
				[key.replace(/\./g, replacer)]: true,
			};
		}, {});

		return facetValue;
	}

	return null;
};

export const initialValuesDate = (currentFacet, facet, timeline) => {
	if (currentFacet && currentFacet[`f.${facet}`]) {
		const splited = currentFacet[`f.${facet}`][0].split("TO");
		const from = splited[0].substring(1).trim();
		const to = splited[1].substring(1, splited[1].length - 1).trim();
		const formatFrom = "YYYY-MM-DD";
		const formatTo = "DD/MM/YYYY";

		const fromValue =
			from && from !== "*"
				? from.startsWith("-")
					? moment(from.substr(1), formatFrom).format(formatTo)
					: moment(from, formatFrom).format(formatTo)
				: "";

		const toValue =
			to && to !== "*"
				? to.startsWith("-")
					? moment(to.substr(1), formatFrom).format(formatTo)
					: moment(to, formatFrom).format(formatTo)
				: "";

		return {
			from: fromValue,
			fromEra: from.startsWith("-") ? "bc" : "ad",
			to: toValue,
			toEra: to.startsWith("-") ? "bc" : "ad",
			timeline: timeline,
		};
	}

	return {
		timeline: timeline,
	};
};

export const getCurrentFacet = (searchFacets, facet) => {
	return searchFacets ? searchFacets.filter(item => Object.keys(item)[0] === `f.${facet}`)[0] : null;
};

export const getCurrentFacetValue = (currentFilter, value) => {
	return value.reduce((array, item) => {
		if (!currentFilter || item.label.toLowerCase().includes(currentFilter.toLowerCase())) {
			return [
				...array,
				{
					...item,
					name: item.label.replace(/\./g, replacer),
				},
			];
		}
		return array;
	}, []);
};

export const getDateValues = value => {
	const from = value.from;
	const to = value.to;
	const fromEra = value.fromEra;
	const toEra = value.toEra;
	const format = "Y-MM-DD";

	const valueDates = {
		from: from ? `${fromEra === "bc" ? "-" : ""}${moment(from, format).format(format)}` : "*",
		to: to ? `${toEra === "bc" ? "-" : ""}${moment(to, format).format(format)}` : "*",
	};

	return valueDates;
};

export const replacer = "|$|%";
export const replacerReg = "\\|\\$\\|\\%";
