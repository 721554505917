import React from "react";
import PropTypes from "prop-types";
import "./Button.sass";
import ButtonBootstrap from "react-bootstrap/Button";
import { Link } from "react-router-dom";

/**
 * Breadcrumbs
 * @memberof Components
 * @function Button
 */

const Button = ({
	disabled = false,
	active = false,
	variant = "primary",
	children,
	href,
	to,
	className,
	onClick,
	type = "button",
	...rest
}) => {
	if (to) {
		return (
			<ButtonBootstrap
				as={Link}
				variant={variant}
				type={type}
				disabled={disabled}
				active={active}
				to={to}
				className={className}
				onClick={onClick}
				{...rest}
			>
				{children}
			</ButtonBootstrap>
		);
	} else {
		return (
			<ButtonBootstrap
				variant={variant}
				type={type}
				disabled={disabled}
				active={active}
				href={href}
				className={className}
				onClick={onClick}
				{...rest}
			>
				{children}
			</ButtonBootstrap>
		);
	}
};
Button.propTypes = {
	disabled: PropTypes.bool,
	active: PropTypes.bool,
	variant: PropTypes.string,
	type: PropTypes.string,
	href: PropTypes.string,
	to: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default Button;
