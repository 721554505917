import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import url from "router/urls";

import { useTranslation } from "react-i18next";

import { isWpAdminRole, isWpMinAuthorEditorRole } from "@/scripts/getRole";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import useToken from "@/components/hooks/useToken";
import Loader from "@/components/Loader/Loader";

import styles from "./Account.module.sass";

import iconMenu1 from "assets/images/account/AccountMenu-1.svg";
import iconMenu2 from "assets/images/account/AccountMenu-2.svg";
import iconMenu3 from "assets/images/account/AccountMenu-3.svg";
import iconMenu4 from "assets/images/account/AccountMenu-4.svg";
import iconMenu5 from "assets/images/account/AccountMenu-5.svg";
import iconMenu6 from "assets/images/account/AccountMenu-6.svg";

/**
 * @namespace Containers
 */

/**
 * Account page
 * @memberof Containers
 * @function Account
 */

const Account = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);

	const keycloakUrl = useSelector(state => state.app.keycloakUrl);
	const wordpressUrl = useSelector(state => state.app.config.reactAppApiWp);

	const ariadnaWwwMySubjectsUrl = url.topics.my;
	const ariadnaWwwMyCollectionsUrl = url.collections.my;

	const ariadnaBadMyProjectsUrl = url.bad.myProjects;
	const ariadnaBadLimitsUrl = url.bad.limits;
	const ariadnaEduMyProjectsUrl = url.edu.myProjects;
	const ariadnaWolMyProjectsUrl = url.wol.myProjects;
	const url_userManagement = keycloakUrl + url.userManagement;
	const userProfile = keycloakUrl + url.userProfile;
	const wordpressPage = wordpressUrl + url.wordpressAdmin;

	const { roles, isLoggedIn } = useToken(true);

	useEffect(() => {
		isLoggedIn && setLoading(false);
	}, [isLoggedIn]);

	const isAdmin = isWpAdminRole(roles);
	const isMinAuthor = isWpMinAuthorEditorRole(roles);

	const path = [
		{
			name: t("account.my"),
		},
	];

	if (loading) return <Loader />;

	return (
		<div className={`${styles["account"]} first-block`}>
			<Container className={styles["container"]}>
				<Breadcrumbs path={path} />

				<h1 className={styles["account__header--title"]}>{t("account.my")}</h1>

				<div className={styles["account__grid"]}>
					<div className={styles["account__grid--item"]}>
						<div>
							<img src={iconMenu1} />
						</div>
						<div>
							<h4>{t("account.main")}</h4>
							<div>
								<a href={userProfile} target="_blank" rel="noreferrer">
									{t("account.profile")}
								</a>
							</div>
						</div>
					</div>
					<div className={styles["account__grid--item"]}>
						<div>
							<img src={iconMenu2} />
						</div>
						<div>
							<h4>{t("modules.www")}</h4>
							<div>
								<Link to={ariadnaWwwMySubjectsUrl}>
									{t("header:menu.topics.items.myTopics")} <i className="icon-external" />
								</Link>
							</div>
							<div>
								<Link to={ariadnaWwwMyCollectionsUrl}>
									{t("header:menu.collections.items.myCollections")} <i className="icon-external" />
								</Link>
							</div>
						</div>
					</div>
					<div className={styles["account__grid--item"]}>
						<div>
							<img src={iconMenu3} />
						</div>
						<div>
							<h4>{t("modules.bad")}</h4>
							<div>
								<a href={ariadnaBadMyProjectsUrl}>{t("account.myProjects")}</a>
							</div>
							{isAdmin && (
								<div>
									<a href={ariadnaBadLimitsUrl}>{t("default:account.limitManagement")}</a>
								</div>
							)}
						</div>
					</div>
					<div className={styles["account__grid--item"]}>
						<div>
							<img src={iconMenu4} />
						</div>
						<div>
							<h4>{t("modules.agr")}</h4>
							<div>
								<a href={ariadnaEduMyProjectsUrl}>
									{t("account.myProjects")} <i className="icon-external" />
								</a>
							</div>
						</div>
					</div>
					<div className={styles["account__grid--item"]}>
						<div>
							<img src={iconMenu5} />
						</div>
						<div>
							<h4>{t("modules.vol")}</h4>
							<div>
								<a href={ariadnaWolMyProjectsUrl}>
									{t("account.myProjects")} <i className="icon-external" />
								</a>
							</div>
						</div>
					</div>
					{isMinAuthor && (
						<div className={styles["account__grid--item"]}>
							<div>
								<img src={iconMenu6} />
							</div>
							<div>
								<h4>{t("account.management")}</h4>
								<div>
									<a href={wordpressPage} target="_blank" rel="noreferrer">
										{t("default:account.serviceManagement")}
									</a>
								</div>
								{isAdmin && (
									<div>
										<a href={url_userManagement} target="_blank" rel="noreferrer">
											{t("account.userManagement")} <i className="icon-external" />
										</a>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};

export default Account;
