import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Actions from "@/actions";
import SearchFormContainer from "./SearchFormContainer";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TextField, InputAdornment } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import styles from "./Search.module.sass";

/**
 * main search
 * @memberof Components
 * @function SearchMain
 */

const SearchMain = ({ isOpen = false, className }) => {
	const matches = useMediaQuery("(max-width: 991.98px)");

	const [open, setOpen] = useState(isOpen);
	const [mobileOpened, setMobileOpened] = useState(false);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClick = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (!open) {
			dispatch(Actions.resetSearchParams());
			setOpen(true);
		}
	});

	return (
		<div className={className}>
			{matches && mobileOpened && (
				<div className={styles["search__close"]}>
					<Button
						variant="linkInv"
						disableFocusRipple={true}
						disableRipple={true}
						onClick={() => {
							setMobileOpened(false);
						}}
						sx={{ fontSize: "16px", pr: "5px", fontWeight: "normal" }}
					>
						<span className={styles["search__close__text"]}>{t("search:close")}</span>
						<i className="fw-icon-close"></i>
					</Button>
				</div>
			)}
			<section className={`${styles["search"]} ${styles["search--main"]}`}>
				{open &&
					(matches ? (
						mobileOpened ? (
							<div className={styles["search__popup"]}>
								<SearchFormContainer onClose={handleClick} />
							</div>
						) : (
							<div className={styles["search__input__mobile"]} onClick={() => setMobileOpened(true)}>
								<TextField
									className="js-advSearchText"
									id="search-mobile"
									variant="standard"
									label={t("search:searchMobile")}
									autoFocus={false}
									placeholder={t("search:searchMobile")}
									sx={{
										backgroundColor: "#FFFFFF",
										py: "25px",
										px: "20px",
										mx: "25px",
										width: "calc(100% - 90px)",
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchOutlinedIcon />
											</InputAdornment>
										),
									}}
								/>
							</div>
						)
					) : (
						<div className={styles["search__popup"]}>
							<SearchFormContainer onClose={handleClick} />
						</div>
					))}
			</section>
		</div>
	);
};

export default SearchMain;
