import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";

import { hideNotification } from "@/actions";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";

import styles from "./Notification.module.sass";

/**
 * Notification
 * @memberof Components
 * @function Notification
 */

const Notification = () => {
	const mount = document.body;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const shown = useSelector(state => state.notification.shown);
	const text = useSelector(state => state.notification.text);
	const lastChange = useSelector(state => state.notification.lastChange);

	const notificationDiv = (
		<div className={styles["notification"]}>
			<p className={styles["notification__text-box"]}>{text}</p>
			<Button
				variant="primary"
				classes={{ root: "notification__ok-button" }}
				onClick={() => {
					dispatch(hideNotification());
				}}
			>
				{t("default:buttons.ok")}
			</Button>
		</div>
	);

	useEffect(() => {
		const hideTimer = setTimeout(() => {
			dispatch(hideNotification());
		}, 10000);

		return () => clearTimeout(hideTimer);
	}, [shown, lastChange]);

	return shown ? createPortal(notificationDiv, mount) : null;
};

export default Notification;
