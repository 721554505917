import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Box, Modal } from "@mui/material";
import styles from "./Modals.module.sass";

/**
 * Modal - confirm
 * @memberof Components
 * @function ConfirmModal
 */

const ConfirmModal = ({ isOpen, onClose, header, message, action }) => {
	const { t } = useTranslation();
	const isContrast = useSelector(state => state.app.isContrast);
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState(false);

	// const customStyles = {
	//     content: {
	//       top: '50%',
	//       left: '50%',
	//       right: 'auto',
	//       bottom: 'auto',
	//       marginRight: '-50%',
	//       transform: 'translate(-50%, -50%)',
	//       padding: '40px 50px',
	//       width: '400px'
	//     },
	//     overlay: {
	//         background: "#FFFFFF",
	//         zIndex: 9999
	//     }
	//   };

	useEffect(() => {}, [isOpen]);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: isContrast ? "#000" : "background.paper",
		border: isContrast ? "2px solid #FFF" : "2px solid #000",
		boxShadow: 0,
		padding: "40px 50px",
		width: "400px",
	};

	const onOK = () => {
		if (action) action();

		onClose();
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...style,
					"@media (max-width: 767.98px)": {
						width: "300px",
					},
				}}
			>
				<a href="#" className={styles["modal__btn--close"]}>
					<i className="icon-cancel" onClick={onClose}></i>
				</a>
				<h2 className={styles["modal__title"]}> {header}</h2>
				<form className={styles["modal__form"]}>
					{message}
					{error && <p className={styles["modal__error"]}>{t("details:collections.addObjectError")}</p>}
					<div className={styles["modal__footer"]}>
						<Button variant="secondary" size="small" onClick={onClose}>
							{t("default:buttons.no")}
						</Button>
						<Button variant="primary" size="small" onClick={onOK}>
							{t("default:buttons.yes")}
						</Button>
					</div>
				</form>
			</Box>
		</Modal>
	);
};

export default ConfirmModal;
