import * as Actions from "@/actions";

let keycloakInstance = null;

/**
 * check logged in
 * @function
 */

export const CheckLoggedIn = (keycloak, dispatch) => {
	keycloakInstance = keycloak;

	keycloakInstance
		.init({
			onLoad: "check-sso",
			pkceMethod: "S256",
			checkLoginIframe: true,
			silentCheckSsoRedirectUri: window.location.origin + "/silent.html",
		})
		.then(function (authenticated) {
			if (authenticated) {
				dispatch(Actions.setAuthIsLoggedIn(true));
				dispatch(Actions.setAuthToken(keycloak.tokenParsed));
				dispatch(Actions.setBearerToken(keycloak.token));
			} else {
				dispatch(Actions.setAuthIsLoggedIn(false));
			}
		})
		.catch(function () {
			dispatch(Actions.setAuthIsLoggedIn(false));
		});
	keycloak.onTokenExpired = () => {
		keycloak
			.updateToken(30)
			.then(() => {
				dispatch(Actions.setAuthIsLoggedIn(true));
				dispatch(Actions.setAuthToken(keycloak.tokenParsed));
				dispatch(Actions.setBearerToken(keycloak.token));
			})
			.catch(() => {
				dispatch(Actions.setAuthIsLoggedIn(false));
			});
	};
};

/**
 * login
 * @function
 */

export const LogIn = () => {
	keycloakInstance
		.init({ onLoad: "login-required", pkceMethod: "S256", checkLoginIframe: false })
		.then(() => {})
		.catch(() => {});
};

/**
 * logout
 * @function
 */

export const LogOut = () => {
	let url = window.location.origin;
	keycloakInstance.logout({ redirectUri: url });
};

/**
 * register
 * @function
 */

export const Register = () => {
	keycloakInstance.register();
};
