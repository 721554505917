import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import debounce from "@/scripts/debounce.js";
import SearchForm from "@/components/Search/SearchForm.js";
import * as Actions from "@/actions";
import { dataScope } from "@/scripts/utils";

/**
 * search form wrapper
 * @memberof Components
 * @function SearchFormContainer
 */

const SearchFormContainer = ({
	params,
	history,
	location,
	actions,
	suggestions,
	suggestionsAdvanced,
	variantClass,
	additionalClass,
	formName = "searchMain",
	// eslint-disable-next-line no-unused-vars
	onClose,
}) => {
	const submitSearchForm = searchQuery => {
		actions.resetSearchParams();
		history.push({
			pathname: "/results",
			search: "?" + queryString.stringify(searchQuery),
			state: {
				prevPath: location.pathname,
			},
		});
	};

	const getAutocompleteList = debounce((field, prefix, index) => {
		if (!dataScope.includes(field)) {
			if (prefix && prefix.length > 2) {
				actions.getSuggestions(field, prefix, index);
			}
		}
	}, 500);

	return (
		<>
			<div className={additionalClass}>
				<SearchForm
					getAutocompleteList={getAutocompleteList}
					onSubmit={submitSearchForm}
					suggestionsAdvanced={suggestionsAdvanced}
					resetSearchSuggestions={actions.resetSearchSuggestions}
					resetAdvancedSearchSuggestions={actions.resetAdvancedSearchSuggestions}
					variantClass={variantClass}
					form={formName}
					params={params}
				></SearchForm>
			</div>
		</>
	);
};

SearchFormContainer.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

const mapStateToProps = state => ({
	suggestionsAdvanced: state.advancedSearch.suggestions,
	params: state.search.params,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchFormContainer));
