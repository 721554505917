import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { Button } from "@mui/material";
import Loader from "@/components/Loader/Loader";
import { useHistory } from "react-router-dom";
import url from "router/urls";
import styles from "./Publications.module.sass";
import { WordpressAcfContext } from "@/contexts/WordpressAcfContext";
import storage from "scripts/storage";
import useMediaQuery from "@mui/material/useMediaQuery";
import PublicationItem from "./PublicationItem";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../Modules/carousel.sass";

/**
 * RecomendedPublications (homepage)
 * @memberof Components
 * @function RecomendedPublications
 */

const RecomendedPublications = () => {
	const { t } = useTranslation();
	const [publications, setPublications] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const wordpressAcf = useContext(WordpressAcfContext);
	const history = useHistory();

	const matches = useMediaQuery("(max-width: 991.98px)");

	const getPublication = id => {
		api
			.get(`/records/${id}`)
			.then(({ data }) => {
				const newPubs = [...publications];
				const found = newPubs.find(element => element.id == data.record.id);

				found.dcTitle = data.record.original.dcTitle && data.record.original.dcTitle.toString();
				found.dcContributor = data.record.original.dcContributor && data.record.original.dcContributor.toString();
				found.dcType = data.record.original.dcType && data.record.original.dcType.toString();
				found.dcSubject = data.record.original.dcSubject && data.record.original.dcSubject.toString();
				found.dcDate = data.record.original.dcDate && data.record.original.dcDate[0];
				found.techUrl = data.record.originaltechUrl && data.record.originaltechUrl;
				found.dcCreator = data.record.original.dcCreator && data.record.original.dcCreator;
				setPublications(newPubs);
			})
			.catch(() => {});
	};

	useEffect(() => {
		const recommended = wordpressAcf?.value?.recommendedObjects;
		if (recommended) {
			const objectIds = recommended.split(",");
			const pubs = objectIds.slice(0, 3).map(x => {
				return { id: x, dcTitle: x };
			});
			setPublications(pubs);
			const from = window.location.pathname + window.location.search;
			storage.saveObject(from, objectIds);
		}
	}, [wordpressAcf]);

	useEffect(() => {

		if (publications && publications.find(e => e.id != e.dcTitle) == undefined)
			publications.forEach(el => {
				getPublication(el.id);
			});
	}, [publications]);

	const showAll = e => {
		e.preventDefault();

		history.push(url.allRecomended);
	};

	const sliderSettings = {
		arrows: true,
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: publications.length,
		slidesToScroll: publications.length,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 3,
				},
			},
		],
	};

	if (loading) return <Loader />;

	return (
		<>
			<div className={styles["publications__header"]}>
				<h2 className={styles["publications__text--header"]}>{t(`home:publications.title.recommend`)}</h2>
				<div className={styles["publications__description"]}>
					<p className={styles["publications__text--content"]}>{t("home:publications.description")}</p>
				</div>
			</div>

			{publications &&
				publications.length > 0 &&
				(matches ? (
					<Slider className={styles["publications__list__slider"]} {...sliderSettings}>
						{publications.map((item, index) => (
							<PublicationItem item={item} t={t} index={index} key={item.id} />
						))}
					</Slider>
				) : (
					<div className={styles["publications__list"]}>
						{publications.map((item, index) => (
							<PublicationItem item={item} t={t} index={index} key={item.id} />
						))}
					</div>
				))}
			<div className={styles["publications__footer"]}>
				<Button variant="primary" onClick={showAll}>
					{t("home:publications.showAllRecomended")}
				</Button>
			</div>
		</>
	);
};

export default RecomendedPublications;
