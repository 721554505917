const initialState = {
	isAppError: false,
	langs: [
		{
			id: "pl",
			name: "pl",
			active: true,
		},
		{
			id: "en",
			name: "en",
		},
	],
	isContrast: false,
	fontSize: "font-normal",
	questionnairePage: {},
	config: {
		reactAppApi: null,
		reactAppApiWp: null,
	},
	keycloakUrl: null,
};

/**
 * App reducers
 *
 * @function app
 * @memberof Reducers
 *
 */

export default function app(state = initialState, action) {
	switch (action.type) {
		case "SET_CURRENT_LANGUAGE":
			return {
				...state,
				langs: state.langs.map(lang => {
					return { ...lang, active: lang.id === action.lang.id };
				}),
			};
		case "SET_APP_ERROR":
			return {
				...state,
				isAppError: true,
			};
		case "RESET_APP_ERROR":
			return {
				...state,
				isAppError: false,
			};
		case "SET_CONTRAST":
			return {
				...state,
				isContrast: action.isContrast,
			};
		case "SET_FONT_SIZE":
			return {
				...state,
				fontSize: action.fontSize,
			};
		case "RECEIVE_QUESTIONNAIRE":
			return {
				...state,
				questionnairePage: action.data.acf,
			};
		case "REQUEST_QUESTIONNAIRE":
			return {
				...state,
				isFetching: true,
				isLoadingError: false,
			};
		case "SET_APP_CONFIG":
			return {
				...state,
				config: action.config,
			};
		case "SET_KEYCLOAK_URL":
			return {
				...state,
				keycloakUrl: action.keycloakUrl,
			};
		default:
			return state;
	}
}
