import React from "react";
import storage from "scripts/storage";
import { useTranslation } from "react-i18next";

import { Select, MenuItem } from "@mui/material";

import styles from "@/components/ItemPerPage/ItemPerPage.module.sass";

const pageSizes = [25, 50, 100];

/**
 * Item per page (sorting)
 * @memberof Components
 * @function ItemPerPage
 */

const ItemPerPage = ({ setPageSize, searchParams: { pageSize } }) => {
	const { t } = useTranslation();

	const onItemPerPageChange = event => {
		setPageSize(event.target.value);
		storage.save("pageSize", event.target.value); //ugly hack na wyszukiwanie ze strony głównej
	};

	return (
		<div className={styles["ipp"]}>
			<div className={styles["ipp__title"]}>
				<span>{t("itemPerPage.title")}:</span>
			</div>
			<label>
				<h5>{pageSize}</h5>
				<Select onChange={onItemPerPageChange} value={pageSize} variant="outlined">
					{pageSizes.map((item, i) => (
						<MenuItem key={i} value={item}>
							{item}
						</MenuItem>
					))}
					;
				</Select>
			</label>
		</div>
	);
};

export default ItemPerPage;
