import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import moment from "moment/moment";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatDate } from "scripts/utils";

import styles from "./TopicsList.module.sass";

/**
 * Topic list item
 * @memberof Containers
 * @function TopicsListItem
 */

export const TopicsListItem = ({ isExpanded, onChange, data }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const matches = useMediaQuery("(max-width: 991.98px)");

	return (
		<Accordion
			expanded={isExpanded}
			onChange={onChange}
			className={styles["topicsList__item"]}
			sx={{
				width: "100%",
				color: "#005CA9",
				boxShadow: "0px 3px 6px #00000029",
				// "&:hover": {
				// 	backgroundColor: "#005CA9",
				// 	color: "#FFFFFF",
				// 	"& p, & a": {
				// 		color: "#FAFAFA",
				// 	},
				// 	"& span": {
				// 		color: "#FFFFFF",
				// 	},
				// 	[`& .${styles["topicsList__button--details"]}`]: {
				// 		backgroundColor: "#FFFFFF",
				// 		color: "#005CA9",
				// 	},
				// },
			}}
		>
			<AccordionSummary
				classes={{
					content: styles["topicsList__item__content"],
					expandIconWrapper: styles["topicsList__text--control"],
				}}
				sx={{
					px: "50px",
					".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
						transform: "none",
					},
					"@media (max-width: 991.98px)": {
						px: "15px",
					},
				}}
				expandIcon={
					<p className={`${styles["topicsList__text--control"]} ${styles["topicsList__text--details"]}`}>
						{matches ? "" : t("topics:expand")}{" "}
						{isExpanded ? <i className="icon-arrow-up" /> : <i className="icon-arrow-down" />}
					</p>
				}
				aria-controls="panel1bh-content"
			>
				<p className={styles["topicsList__text--head"]}>
					{data.acf[`${language}_title`] || data.title?.rendered} ({data.count})
				</p>
				{!isExpanded && (
					<Link
						to={`/topics/${data.id}?${data.acf.ids.replace(/^\?+/, "")}`}
						className={styles["topicsList__text--link"]}
					>
						<p className={styles["topicsList__text--control"]}>{t("topics:details")}</p>
					</Link>
				)}
			</AccordionSummary>
			<AccordionDetails sx={{ display: "flex", px: "50px", pb: "60px" }}>
				<div className={`${styles["topicsList__details"]} ${styles["topicsList__text--normal"]}`}>
					{matches && (
						<div className={styles["topicsList__details__content__info"]}>
							<p>
								{t("topics:creationDate")}:{" "}
								<span className={styles["topicsList__text--accent"]}>{formatDate(data.date)}</span>
							</p>
							<p>
								{t("topics:author")}:{" "}
								<span className={styles["topicsList__text--accent"]}>{data._embedded.author[0].description}</span>
							</p>
						</div>
					)}
					<div>
						{data._embedded["wp:featuredmedia"] && data._embedded["wp:featuredmedia"].length > 0 && (
							<img
								src={data._embedded["wp:featuredmedia"][0].source_url}
								alt=""
								className={styles["topicsList__image"]}
							/>
						)}
					</div>
					<div className={styles["topicsList__details__content"]}>
						{!matches && (
							<div className={styles["topicsList__details__content__info"]}>
								<p>
									{t("topics:creationDate")}:{" "}
									<span className={styles["topicsList__text--accent"]}>{formatDate(data.date)}</span>
								</p>
								<p>
									{t("topics:author")}:{" "}
									<span className={styles["topicsList__text--accent"]}>{data._embedded.author[0].description}</span>
								</p>
							</div>
						)}
						<div className={styles["topicsList__details__content__description"]}>
							<div>{ReactHtmlParser(data.excerpt?.rendered)}</div>
							<div>
								<Button
									disableFocusRipple={true}
									disableRipple={true}
									variant="primary"
									// className={`${styles["topicsList__button--details"]} ${styles["topicsList__details__content__description__button"]}`}

									// sx={{
									// 	border: "2px solid",
									// 	borderColor: "#005CA9",
									// 	boxShadow: 0,
									// 	background: "#005CA9",
									// 	color: "#FFFFFF",
									// 	padding: "15px 50px",
									// }}
									component={Link}
									to={`/topics/${data.id}?${data.acf.ids.replace(/^\?+/, "")}`}
								>
									{t("topics:details")}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
