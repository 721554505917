import React, { createContext, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "@/services/theme";
import { contrastTheme } from "@/services/contrastTheme";

export const ThemeContext = createContext(null);

/**
 *
 * Theme (MaterialUI) context
 *
 * @function ThemeContextProvider
 * @memberof Context
 *
 */

const ThemeContextProvider = ({ contrast, children }) => {
	const [isContrastTheme, setIsContrastTheme] = useState(contrast);

	const currentTheme = React.useMemo(() => {
		if (isContrastTheme) return contrastTheme;
		return theme;
	}, [isContrastTheme]);

	return (
		<ThemeContext.Provider value={setIsContrastTheme}>
			<ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export default ThemeContextProvider;
