import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { useTranslation } from "react-i18next";

import "./Error.sass";

/**
 * Error
 * @memberof Components
 * @function Error
 */

const Error = () => {
	const { t } = useTranslation();

	return (
		<Container className={"first-block"}>
			<Row>
				<div className="error">
					<h3 className="error__header">{t("error:header")}</h3>
					<h2 className="error__code">{t("error:code")}</h2>
					<p className="error__causes">{t("error:causes")}</p>
					<ul className="error__causes-list">
						<li className="error__causes-list--item">{t("error:cause.first")}</li>
						<li className="error__causes-list--item">{t("error:cause.second")}</li>
						<li className="error__causes-list--item">{t("error:cause.third")}</li>
					</ul>
					<p className="error__apologize">{t("error:apologize")}</p>
					<div>
						<Link to={"/"} className="error__back">
							{t("error:back")}
						</Link>
					</div>
				</div>
			</Row>
		</Container>
	);
};

export default Error;
