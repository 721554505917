import React from "react";
import { useTranslation } from "react-i18next";

import styles from "@/components/Pagination/Pagination.module.sass";

/**
 * Pagination next button
 * @memberof Components
 * @function PaginationNext
 */

const PaginationNext = ({ setNextPage }) => {
	const { t } = useTranslation();
	return (
		<button className={styles["pagination__next"]} onClick={() => setNextPage()} title={t("pagination:nextTitle")}>
			<span>{t("pagination:next")}</span>
		</button>
	);
};

export default PaginationNext;
