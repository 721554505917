import React, { useState, useEffect, useRef } from "react";
import styles from "@/components/Search/Search.module.sass";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DatePickerField from "@/components/datePicker/DatePickerField";

/**
 * Date - advanced field
 * @memberof Components
 * @function AdvancedDateField
 */

const AdvancedDateField = ({ handelSearchTexChange, valueDate }) => {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [eraStartDate, setEraStartDate] = useState("");
	const [eraEndDate, setEraEndDate] = useState("");
	const [startDateMask, setStartDateMask] = useState(null);
	const [endDateMask, setEndDateMask] = useState(null);
	const [error, setError] = useState(null);

	const formatDate = "DD-MM-YYYY";

	const convertDate = () => {
		const splited = valueDate.split("TO");
		const from = splited[0].substring(1).trim() === "*" ? null : splited[0].substring(1).trim();
		const to =
			splited[1].substring(1, splited[1].length - 1) === "*"
				? null
				: splited[1].substring(1, splited[1].length - 1).trim();
		setStartDateMask(moment(from).format(formatDate));
		setStartDate(from);
		setEndDate(to);
		setEndDateMask(moment(to).format(formatDate));
		setEraEndDate(to && to.startsWith("-") ? "-" : "");
		setEraStartDate(from && from.startsWith("-") ? "-" : "");
	};

	useEffect(() => {
		const textStartDate = startDate ? moment(startDate, formatDate).format("YYYY-MM-DD") : "*";
		const textEndDate = endDate ? moment(endDate, formatDate).format("YYYY-MM-DD") : "*";

		const text = `[${eraStartDate}${textStartDate} TO ${eraEndDate}${textEndDate}]`;
		handelSearchTexChange(text);
	}, [startDate, endDate, eraStartDate, eraEndDate]);

	useEffect(() => {
		valueDate && convertDate();
	}, []);

	useEffect(() => {
		setError(null);
		if (startDate && endDate && startDate.length === 10 && endDate.length === 10) {
			validateDate();
		}
	}, [startDate, endDate, eraStartDate, eraEndDate]);

	const validateDate = () => {
		const startMoment = moment(startDate, formatDate);
		const endMoment = moment(endDate, formatDate);

		if (eraStartDate === "-" && eraEndDate === "") {
			return;
		} else if (eraStartDate === "" && eraEndDate === "-") {
			setError(t("advanced_search:form.errors.to_exceeds_from"));
		} else if (eraStartDate === "-" && eraEndDate === "-") {
			if (startMoment.isBefore(endMoment)) {
				setError(t("advanced_search:form.errors.to_exceeds_from"));
			}
		} else if (startMoment.isAfter(endMoment)) {
			setError(t("advanced_search:form.errors.to_exceeds_from"));
		}
	};

	return (
		<div>
			<div className={`${styles["search__advsearch-date-container"]}`}>
				<DatePickerField
					setEra={setEraStartDate}
					setDate={setStartDate}
					setInput={setStartDateMask}
					value={startDate}
					valueInput={startDateMask}
					valueEra={eraStartDate}
				/>
				<DatePickerField
					setEra={setEraEndDate}
					setDate={setEndDate}
					setInput={setEndDateMask}
					value={endDate}
					valueInput={endDateMask}
					valueEra={eraEndDate}
				/>
			</div>
			<div className={`${styles["search__advsearch-date-error"]}`}>{error}</div>
		</div>
	);
};

export default AdvancedDateField;
