import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Modal, FormControl, TextField } from "@mui/material";
import { useSelector } from "react-redux";

import api from "services/api";
import useToken from "@/components/hooks/useToken";

import styles from "./Modals.module.sass";

/**
 * Modal - create collection
 * @memberof Components
 * @function CreateCollectionModal
 */

const CreateCollectionModal = ({ uid, onCreate, buttonVariant, buttonText }) => {
	const { t } = useTranslation();
	const isContrast = useSelector(state => state.app.isContrast);
	const [open, setOpen] = useState(false);
	const name = useRef(null);
	const description = useRef(null);
	const { bearer } = useToken();

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const n = name.current.value;
		const d = description.current.value;
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};

		const url = `/users/${uid}/static-collections`;
		const data = {
			name: n,
			description: d,
			type: "static",
		};

		api
			.post(url, data, config)
			.then(() => {
				handleClose();
				if (onCreate) onCreate();
			})
			.catch(error => {
				console.error(error);
			});
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: isContrast ? "#000" : "background.paper",
		border: isContrast ? "2px solid #FFF" : "2px solid #000",
		boxShadow: 0,
		padding: "40px 50px",
		width: "400px",
	};

	return (
		<>
			<div>
				<Button
					variant={buttonVariant}
					size="small"
					disableFocusRipple={true}
					disableRipple={true}
					onClick={handleOpen}
					sx={{
						"@media (max-width: 767.98px)": {
							width: "100%",
						},
					}}
				>
					{buttonText}
				</Button>
			</div>
			<Modal
				hideBackdrop
				open={open}
				onClose={handleClose}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Box
					sx={{
						...style,
						width: "600px",
						"@media (max-width: 767.98px)": {
							width: "300px",
						},
					}}
				>
					<a href="#" className={styles["modal__btn--close"]} onClick={handleClose}>
						<i className="icon-cancel"></i>
					</a>
					<h2 className={styles["modal__title"]}>{t("details:collections.create")} </h2>
					<form className={styles["modal__form"]} onSubmit={handleSubmit}>
						<div className={styles["modal__form--group"]}>
							<label>{t("details:collections.name")}</label>

							<FormControl fullWidth>
								<TextField
									id="nameInput"
									inputRef={name}
									className={`${styles["modal__input"]} ${styles["modal__input--short"]} `}
									sx={{ borderBottom: "2px solid #6F6F6F" }}
									variant="standard"
									autoFocus={true}
								/>
							</FormControl>
						</div>
						<div className={styles["modal__form--group"]}>
							<label>{t("details:collections.description")}</label>
							<p>{t("details:collections.subDescription")}</p>

							<FormControl fullWidth>
								<TextField
									id="descInput"
									inputRef={description}
									className={styles["modal__input"]}
									sx={{ borderBottom: "2px solid #6F6F6F" }}
									variant="standard"
								/>
							</FormControl>
						</div>
						<div className={styles["modal__footer"]}>
							<Button
								variant="secondary"
								onClick={handleClose}
								sx={{
									"@media (max-width: 767.98px)": {
										width: "100%",
									},
								}}
							>
								{t("buttons.cancel")}
							</Button>

							<Button
								variant="primary"
								type="submit"
								sx={{
									"@media (max-width: 767.98px)": {
										width: "100%",
									},
								}}
							>
								{t("details:collections.create")}
							</Button>
						</div>
					</form>
				</Box>
			</Modal>
		</>
	);
};

export default CreateCollectionModal;
