import Home from "../containers/Home/Home.js";
import Details from "../containers/Details/Details.js";
import SearchResults from "../containers/ResultsPages/SearchResults";
import NewsList from "../containers/NewsList/NewsList.js";
import RecomendedResults from "../containers/ResultsPages/RecomendedResults";
import TopicResults from "../containers/ResultsPages/TopicResults";
import Account from "../containers/Account/Account";
import Map from "../containers/Map/Map";
import NewsItem from "../containers/NewsItem/NewsItem.js";
import CollectionsList from "../containers/CollectionsList/CollectionsList.js";
import MyCollectionsList from "../containers/MyCollections/MyCollectionsList.js";
import CollectionResults from "../containers/ResultsPages/CollectionResults";
import MyCollectionResults from "../containers/ResultsPages/MyCollectionResults";
import SharedCollectionResults from "../containers/ResultsPages/SharedCollectionResults";
import StaticPage from "../containers/StaticPage/StaticPage.js";
import TopicsList from "../containers/TopicsList/TopicsList";
import MyTopicsList from "../containers/MyTopics/MyTopicsList";
import Error from "@/components/error/Error.js";
import TabbedPage from "../containers/StaticPage/TabbedPage.js";
import url from "./urls";

/**
 * 	@module router/routes
 *	@description
 * 	Router routes declarations
 */

export default [
	{
		id: "main",
		path: url.home,
		exact: true,
		component: Home,
	},
	{
		id: "results",
		path: url.results,
		exact: true,
		component: SearchResults,
	},
	{
		id: "details",
		path: url.details,
		exact: true,
		component: Details,
	},
	{
		id: "recomended",
		path: url.recomended,
		exact: true,
		component: RecomendedResults,
	},
	{
		id: "newsList",
		path: url.news.list,
		exact: true,
		component: NewsList,
	},
	{
		id: "newsItem",
		path: url.news.item,
		exact: true,
		component: NewsItem,
	},
	{
		id: "collectionsList",
		path: url.collections.list,
		exact: true,
		component: CollectionsList,
	},
	{
		id: "myCollectionsList",
		path: url.collections.my,
		exact: true,
		component: MyCollectionsList,
	},
	{
		id: "topicsList",
		path: url.topics.all,
		exact: true,
		component: TopicsList,
	},
	{
		id: "myTopicsList",
		path: url.topics.my,
		exact: true,
		component: MyTopicsList,
	},
	{
		id: "topicDetails",
		path: url.topics.item,
		exact: true,
		component: TopicResults,
	},
	{
		id: "account",
		path: url.account,
		exact: true,
		isProtected: true,
		component: Account,
	},
	{
		id: "sitemap",
		path: url.sitemap,
		exact: true,
		component: Map,
	},
	{
		id: "collectionsItem",
		path: url.collections.item,
		exact: true,
		component: CollectionResults,
	},
	{
		id: "myCollectionsItem",
		path: url.collections.myItem,
		exact: true,
		component: MyCollectionResults,
	},
	{
		id: "sharedItem",
		path: url.collections.sharedItem,
		exact: true,
		component: SharedCollectionResults,
	},
	{
		id: "page",
		path: url.page,
		exact: true,
		component: StaticPage,
	},
	{
		id: "about",
		path: url.about,
		exact: true,
		component: TabbedPage,
	},
	{
		id: "error",
		component: Error,
	},
];
