import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import wordpressApi from "services/wordpress";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error.js";
import TabbedStaticPage from "containers/StaticPage/TabbedStaticPage.js";
import SearchMain from "@/components/Search/SearchMain";

import styles from "./StaticPage.module.sass";
import useStorageState from "scripts/useStorageState";
import menus from "router/menus";
import storage from "scripts/storage";
import { reverse } from "named-urls";
import url from "router/urls";
import moment from "moment/moment";

function isEmptyObject(obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const DATE_FORMAT = "DD.MM.YYYY";

function TabPanel(props) {
	const { slug, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <TabbedStaticPage slug={slug} />}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TabbedPage = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const menu = menus.about;
	const { slug } = useParams();
	let history = useHistory();
	const [value, setValue] = useState(0);
	const [lastEdit, setLastEdit] = useState("");

	function getSlug(index) {
		if (wordpressMenuAbout?.items && wordpressMenuAbout?.items[index]) {
			return wordpressMenuAbout.items[index].slug;
		}
		return null;
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
		let newSlug = getSlug(newValue);
		if (newSlug) {
			history.push(reverse(url.about, { slug: newSlug.slice(3) }));
		}
	};

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [wordpressMenuAbout, setWordpressMenuAbout] = useStorageState(`${language}-${menus.about}`, {});

	useEffect(() => {
		if (slug && !isEmptyObject(wordpressMenuAbout)) {
			wordpressMenuAbout?.items?.map((item, index) => {
				if (item.slug.slice(3) == slug) {
					
					setValue(index);
				}
			});
		}
	}, [slug]);

	function getMax(prev, item) {
		const current = item.post_modified || item.post_date;
		return current > prev ? current : prev;
	}
	function getLastEdit(menu) {
		const findLastEdit = menu?.items?.reduce(getMax, "");
		setLastEdit(findLastEdit);
	}

	function getWordpressMenus() {
		setWordpressMenuAbout(storage.getObject(`${language}-${menu}`, {}));
		setLoading(isEmptyObject(wordpressMenuAbout));
		wordpressApi
			.get(`menus/v1/menus/${language}-${menu}`)
			.then(({ data }) => {
				setWordpressMenuAbout(data);
				getLastEdit(data);
				setError(false);
				setLoading(false);
			})
			.catch(() => {
				setWordpressMenuAbout({});
				setLoading(false);
				setError(true);
			});
	}

	useEffect(() => {
		getWordpressMenus();
	}, [language]);

	if (loading) return <Loader />;

	if (error) return <Error />;

	const path = [
		{
			name: t("footer:menu.about.title"),
		},
	];

	return (
		<>
			<SearchMain className={styles["staticPage__search"]} />

			<Box sx={{ width: "calc(100% - 40px)" }} className={"container"}>
				<div className={styles["staticPage__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>
				<div className={styles["staticPage__head"]}>
					<div>
						<span className={styles["staticPage__text--title"]}>{t("footer:menu.about.title")}</span>
					</div>
					<div>{lastEdit && <span>{t("results:lastEdit")}: {moment(new Date(lastEdit)).format(DATE_FORMAT)}</span>}</div>
				</div>
				<Box
					sx={{
						marginTop: 5,
						borderBottom: 1,
						borderColor: "divider",
						"@media (max-width: 767.98px)": {
							borderBottom: 0,
						},
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						sx={{
							"@media (max-width: 767.98px)": { "& .MuiTabs-flexContainer": { flexDirection: "column" } },
						}}
						aria-label="basic tabs example"
					>
						{wordpressMenuAbout?.items?.map((item, index) => (
							<Tab
								key={index}
								label={item.title}
								sx={{
									"@media (max-width: 767.98px)": { minWidth: "100%" },
								}}
								{...a11yProps(index)}
							/>
						))}
					</Tabs>
				</Box>
				{wordpressMenuAbout?.items?.map((item, index) => (
					<TabPanel key={index} value={value} slug={item.slug} index={index} />
				))}
			</Box>
		</>
	);
};

export default TabbedPage;
