import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

/**
 * Search facet input component
 * @memberof Components
 * @function FacetSearchInput
 */

const FacetSearchInput = ({ disabled = false, input, label, placeholder, facetItems, changeFieldValue, facetName }) => {
	return (
		// <Container>
		<Autocomplete
			id={input.name}
			placeholder={placeholder}
			freeSolo={true}
			options={facetItems.map(item => item.name)}
			onChange={e => {
				changeFieldValue(facetName, e.target.textContent, true);
			}}
			sx={{
				width: "100%",
			}}
			disabled={disabled}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					size={"small"}
					placeholder={placeholder}
					sx={{
						bgcolor: "#FFFFFF",
						// marginLeft: 1,
						width: "100%",
						// width: 9 / 10,
					}}
					InputProps={{
						...params.InputProps,
						type: "search",
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
		// </Container>
	);
};

export default FacetSearchInput;
