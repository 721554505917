/**
 * 	@module router/menus
 *	@description
 * 	Router menu declarations
 */

const menus = {
	about: "menu-about",
	contact: "menu-informations",
};

export default menus;
