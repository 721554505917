import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Modal } from "@mui/material";
import api from "services/api";
import useToken from "@/components/hooks/useToken";
import styles from "./Modals.module.sass";

/**
 * Modal - subscribe topic
 * @memberof Components
 * @function SubscribeTopicModal
 */

const SubscribeTopicModal = ({ isOpen, onClose, topicId }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { bearer, userId } = useToken();

	const [selectedInterval, setSelectedInterval] = useState("weekly");

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: "0px 3px 6px #00000029",
		border: "1px solid #E5E5E5",
		padding: "40px 50px",
		width: "800px",
	};

	const handleAccept = () => {
		subscribeTopic(selectedInterval);
		onClose();
	};

	const subscribeTopic = periodicity => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
				Accept: "application/json-patch+json",
				"Content-Type": "application/json-patch+json",
			},
		};
		api
			.patch(
				`/users/${userId}/dynamic-collections/${topicId}`,
				[
					{
						op: "replace",
						path: "/subscribed",
						value: "true",
					},
					{
						op: "add",
						path: "/subscriptionPeriodicity",
						value: periodicity,
					},
					{
						op: "add",
						path: "/subscriptionLanguage",
						value: language,
					},
				],
				config
			)
			.then(() => {
				window.location.reload(false);
			})
			.catch(() => {});
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...style,
					"@media (max-width: 767.98px)": {
						width: "300px",
					},
				}}
			>
				<a href="#" className={styles["modal__btn--close"]}>
					<i className="icon-cancel" onClick={onClose}></i>
				</a>
				<h2 className={styles["modal__title"]}> {t("topics:subscribeTopicModal.header")}</h2>
				<form className={styles["modal__form"]}>
					<div className={styles["modal__body"]}>
						{t("topics:subscribeTopicModal.intervalDescription")}:
						<div style={{ marginTop: "30px" }}>
							<label>
								<input
									type="radio"
									value="weekly"
									style={{ marginRight: "10px" }}
									checked={selectedInterval == "weekly"}
									onChange={() => {
										setSelectedInterval("weekly");
									}}
								/>
								{t("topics:subscribeTopicModal.intervalEveryWeek")}
							</label>
						</div>
						<div style={{ marginTop: "20px" }}>
							<label>
								<input
									type="radio"
									value="monthly"
									style={{ marginRight: "10px" }}
									checked={selectedInterval == "monthly"}
									onChange={() => {
										setSelectedInterval("monthly");
									}}
								/>
								{t("topics:subscribeTopicModal.intervalEveryMonth")}
							</label>
						</div>
						<div style={{ marginTop: "20px" }}>
							<label>
								<input
									type="radio"
									value="quarterly"
									style={{ marginRight: "10px" }}
									checked={selectedInterval == "quarterly"}
									onChange={() => {
										setSelectedInterval("quarterly");
									}}
								/>
								{t("topics:subscribeTopicModal.intervalEveryThreeMonths")}
							</label>
						</div>
					</div>
					<div className={styles["modal__footer"]}>
						<Button variant="secondary" size="small" onClick={onClose}>
							{t("topics:subscribeTopicModal.cancel")}
						</Button>
						<Button variant="primary" size="small" onClick={handleAccept}>
							{t("topics:subscribeTopicModal.accept")}
						</Button>
					</div>
				</form>
			</Box>
		</Modal>
	);
};

export default SubscribeTopicModal;
