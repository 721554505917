const initialState = {
	menuData: [],
	pageData: {},
	isFetching: false,
	isLoadingError: false,
};

/**
 * Main page reducers
 *
 * @function mainPage
 * @memberof Reducers
 *
 */

export default function mainPage(state = initialState, action) {
	switch (action.type) {
		case "RECEIVE_HEADER_MENU":
			return {
				...state,
				menuData: action.data.items,
			};

		case "RECEIVE_SELECTED_RESOURCES":
			return {
				...state,
				pageData: action.data.acf,
			};
		case "REQUEST_SELECTED_RESOURCES":
			return {
				...state,
				isFetching: true,
				isLoadingError: false,
			};
		default:
			return state;
	}
}
