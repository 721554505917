import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { isDevelopment } from "scripts/debug";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
	order: ["querystring", "localStorage", "navigator"],
	lookupQuerystring: "lang",
};

const isDebug = isDevelopment();

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: ["pl", "en", "de"],
		detection: options,
		debug: isDebug,
		defaultNS: "default",
		ns: [
			"default",
			"header",
			"search",
			"home",
			"footer",
			"advanced_search",
			"results",
			"metadata",
			"details",
			"pagination",
			"topics",
			"error",
			"cookies",
		],
		load: "languageOnly",

		backend: {
			loadPath: function (lng, ns) {
				return `/locales/${lng}/${ns}.json`;
			},
		},

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
