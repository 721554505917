import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeUrlToRequest } from "@/scripts/parseUrl";

import Results from "../Results/Results";

import * as Actions from "@/actions";
import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

/**
 * Search results page
 * @memberof Containers
 * @function SearchResults
 */

const SearchResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
	highlights,
}) => {
	const { t } = useTranslation();
	const locationstate = useLocation();
	const endpoint = location.pathname === "/results" ? "/api/results" : "/api/list";

	const path =
		searchParams.q === "*"
			? [
					{
						name: t("results:breadcrumbs.allObjects"),
					},
			  ]
			: [
					{
						name:
							locationstate.state && locationstate.state.latest
								? t("home:publications.title.lastAdded")
								: t("results:breadcrumbs.results"),
					},
			  ];

	useEffect(() => {
		loadResults(location.search, searchParams, actions, endpoint);
		return () => actions.resetSearchParams();
	}, []);

	useEffect(() => {
		if (!location) return;
		loadResults(location.search, searchParams, actions, endpoint);
	}, [location]);

	const loadResults = (searchLocation, searchParams, actions, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);
		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams(
			{
				...newSearchParams,
				params: {
					...newSearchParams.params,
					page: newSearchParams.params.page - 1,
				},
			},
			true
		);

		actions.loadResults(endpoint, changeUrlToRequest(searchUrl));
	};

	return (
		<Results
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching}
			location={location}
			history={history}
			highlights={highlights}
			path={path}
		></Results>
	);
};

SearchResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
	results: state.results.results,
	facets: state.results.facets,
	category: state.results.category,
	count: state.results.count,
	links: state.results.links,
	highlights: state.results.highlights,
	isFetching: state.results.isFetching,
	searchParams: state.search.params,
	searchCategory: state.search["f.category"],
	searchSort: state.search.sortBy,
	searchSortOrder: state.search.sortOrder,
	searchFacets: state.search.facets,
	totalPages: state.search.totalPages,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults));
