import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { reverse } from "named-urls";
import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";

import wordpressApi from "services/wordpress";
import url from "router/urls";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import SearchMain from "@/components/Search/SearchMain";

import { Button } from "@mui/material";
import collectionsImg from "@/assets/images/collections.svg";

import styles from "./CollectionsList.module.sass";

import { formatDate } from "scripts/utils";

/**
 * Collection list page
 * @memberof Containers
 * @function CollectionsList
 */

const CollectionsList = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const pageSize = 20;
	const [loading, setLoading] = useState(true);
	const [collectionCount, setCollectionCount] = useState(0);
	const [pagesCount, setPagesCount] = useState(0);
	const [page, setPage] = useState(1);
	const [collectionsList, setCollectionsList] = useState([]);

	const getCollectionsList = () => {
		wordpressApi
			.get(`wp/v2/collections?page=${page}&per_page=${pageSize}&_embed`)
			.then(response => {
				setLoading(false);
				setCollectionCount(+response.headers["x-wp-total"]);
				setPagesCount(+response.headers["x-wp-totalpages"]);
				setCollectionsList(response.data);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getCollectionsList();
	}, [page]);

	if (loading) return <Loader />;

	const path = [
		{
			name: t("breadcrumbs.collections"),
		},
	];

	const setPageByNum = page => {
		setPage(page);
	};

	return (
		<div className={styles["collectionsList"]}>
			<SearchMain className={styles["collectionsList__search"]} />
			<div className={`${styles["collectionsList__container"]} container`}>
				<div className={styles["collectionsList__breadcrumbs"]}>
					<Breadcrumbs path={path} />
				</div>

				<div className={styles["collectionsList__head"]}>
					<h1 className={styles["collectionsList__text--title"]}>
						<img src={collectionsImg} alt="collections" />
						<div>
							<span className={styles["collectionsList__text--title--bold"]}>{t("header:menu.collections.title")}</span>
							<span>{` (${collectionCount})`}</span>
							<p>{t("details:collections.endorsement")}</p>
						</div>
					</h1>
				</div>

				{pagesCount > 0 && (
					<div className={styles["collectionsList__pagination"]}>
						<Pagination setPageByNum={setPageByNum} page={page} totalPages={pagesCount} />
					</div>
				)}

				<div className={styles["collectionsList__content"]}>
					{collectionsList.length ? (
						<>
							{collectionsList.map(item => (
								<div
									key={item.id}
									className={` ${styles["collectionsList__item"]} ${styles["collectionsList__item--short"]} `}
								>
									<div
										className={styles["collectionsList__item--image"]}
										// eslint-disable-next-line no-prototype-builtins
										{...(item?._embedded?.hasOwnProperty("wp:featuredmedia") && {
											style: {
												backgroundImage: `url(${item._embedded["wp:featuredmedia"][0].source_url})`,
											},
										})}
									></div>
									<div className={styles["collectionsList__item--desc"]}>
										<div className={styles["collectionsList__item--header"]}>
											<span className={styles["collectionsList__text--counter"]}>
												{t("details:collections.objects")}{" "}
												<strong>{item.acf.ids ? item.acf.ids.split(",").length : 0}</strong>
											</span>
											<Link
												to={reverse(url.collections.item, { id: item.id })}
												className={styles["collectionsList__text--head"]}
											>
												{<>{item.acf[`${language}_title`] || item.title?.rendered}</>}
											</Link>
											<p className={styles["collectionsList__text--date"]}>
												<span>
													{t("home:news.item.date")}: <strong>{formatDate(item.date, language)}</strong>
												</span>
												{item._embedded?.author[0].description && (
													<span className={styles["collectionsList__text--author"]}>
														{t("home:collections.tile.author")}:{" "}
														<strong>{item._embedded?.author[0].description}</strong>
													</span>
												)}
											</p>
										</div>
										<div className={styles["collectionsList__item--content"]}>
											{
												<>
													{ReactHtmlParser(
														item.acf[`${language}_excerpt`] || item.acf[`${language}_full`] || item.excerpt?.rendered
													)}
												</>
											}
										</div>
										<div className={styles["collectionsList__item--footer"]}>
											<Button variant="primary" component={Link} to={reverse(url.collections.item, { id: item.id })}>
												{t("details:seeCollection")}
											</Button>
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<>{t("details:collections:none")}</>
					)}
				</div>

				{pagesCount > 0 && (
					<div className={styles["collectionsList__pagination"]}>
						<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
					</div>
				)}
			</div>
		</div>
	);
};

export default CollectionsList;
