import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { reverse } from "named-urls";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import url from "router/urls";
import slugs from "router/slugs";
import { WordpressAcfContext } from "@/contexts/WordpressAcfContext";

import { Button } from "@mui/material";

import styles from "./Sources.module.sass";

/**
 * Sources (homepage)
 * @memberof Components
 * @function Sources
 */

const Sources = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [sourcesContent, setSourcesContent] = useState({});
	const wordpressAcf = useContext(WordpressAcfContext);

	useEffect(() => {
		if (wordpressAcf) {
			setSourcesContent(wordpressAcf.value);
		}
	}, [wordpressAcf]);

	const getSourceHtml = s => {
		if (s) {
			return parse(s, {
				replace: domNode => {
					if (domNode.name == "a") {
						domNode.attribs["data-noicon"] = true;
						domNode.attribs["title"] = `${t("home:sources.tooltip")}: ${domNode.attribs["href"]}`;
					}
				},
			});
		} else if (!s) {
			return null;
		}
	};

	return (
		<div className={styles["sources"]}>
			<div className={` ${styles["sources__container"]} container`}>
				<div className={styles["sources__header"]}>
					<h2 className={styles["sources__text--header"]}>{t("home:sources.title")}</h2>
				</div>
				{sourcesContent && (
					<div className={styles["sources__list"]}>
						{/* i18next podaje w chromie inną wartość języka */}
						{language === "pl-PL" && <>{getSourceHtml(sourcesContent["pl-sources"])}</>}
						{sourcesContent[`${language}-sources`] !== "" ? (
							<>{getSourceHtml(sourcesContent[`${language}-sources`])}</>
						) : (
							<>{getSourceHtml(sourcesContent["pl-sources"])}</>
						)}
					</div>
				)}
				<div className={styles["sources__footer"]}>
					<Button variant="primary" component={Link} to={reverse(url.page, { slug: slugs.sources })}>
						{t("home:sources.showAll")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Sources;
