import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { Button } from "@mui/material";
import Loader from "@/components/Loader/Loader";
import { useHistory } from "react-router-dom";
import url from "router/urls";
import RecomendedPublications from "./RecomendedPublications";
import styles from "./Publications.module.sass";
import { WordpressAcfContext } from "@/contexts/WordpressAcfContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import PublicationItem from "./PublicationItem";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../Modules/carousel.sass";

const PublicationTypes = { RECOMMENDED: "recommend", LAST: "lastAdded" };

const PUBLICATIONS_LIMIT = 3;

/**
 * PublicationsBlock (homepage)
 * @memberof Components
 * @function PublicationsBlock
 */

const PublicationsBlock = ({ type }) => {
	const { t } = useTranslation();
	const [publications, setPublications] = useState([...Array(PUBLICATIONS_LIMIT).fill({})]);
	const [loading, setLoading] = useState(false);

	const matches = useMediaQuery("(max-width: 991.98px)");

	const getPublications = () => {
		const url = type === PublicationTypes.LAST ? "/records/latest" : "";
		if (url) {
			setLoading(true);
			api
				.get(url)
				.then(({ data }) => {
					if (data.documents) {
						const documents = data.documents;
						const lastPublications = Object.values(documents).flat();
						if (lastPublications.length > PUBLICATIONS_LIMIT) lastPublications.length = PUBLICATIONS_LIMIT;
						setPublications(lastPublications);
					}
				})
				.catch(() => {})
				.then(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		getPublications();
	}, []);

	const history = useHistory();

	const showAll = e => {
		e.preventDefault();

		history.push(url.results + "?q=*&sortBy=time&sortOrder=desc", { latest: true });
	};

	const sliderSettings = {
		arrows: true,
		centerMode: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: publications.length,
		slidesToScroll: publications.length,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 3,
				},
			},
		],
	};

	if (loading) return <Loader />;

	return (
		<>
			<div className={`${styles["publications__header"]} ${styles["publications__header--second"]}`}>
				<h2 className={styles["publications__text--header"]}>{t(`home:publications.title.${type}`)}</h2>
			</div>
			{publications &&
				publications.length > 0 &&
				(matches ? (
					<Slider className={styles["publications__list__slider"]} {...sliderSettings}>
						{publications.map((item, index) => (
							<PublicationItem item={item} t={t} index={index} key={item.id} />
						))}
					</Slider>
				) : (
					<div className={styles["publications__list"]}>
						{publications.map((item, index) => (
							<PublicationItem item={item} t={t} index={index} key={item.id} />
						))}
					</div>
				))}
			<div className={styles["publications__footer"]}>
				<Button variant="primary" onClick={showAll}>
					{t("home:publications.showAll")}
				</Button>
			</div>
		</>
	);
};

/**
 * Publications (homepage)
 * @memberof Components
 * @function Publications
 */

const Publications = () => {
	const wordpressAcf = useContext(WordpressAcfContext);

	return (
		<div className={styles["publications"]}>
			<div className={` ${styles["publications__container"]} container`}>
				<div className={styles["publications__blocks"]}>
					<div className={styles["publications__block"]}>
						<RecomendedPublications />
					</div>
					{!wordpressAcf?.value?.hideLatest && (
						<div className={styles["publications__block"]}>
							<PublicationsBlock type={PublicationTypes.LAST} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Publications;
