import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/Button.js";

import styles from "./Categories.module.sass";

const CategoriesInfo = ({ isActive, searchCategory, resetCategory }) => {
	const { t } = useTranslation();

	return (
		<div
			className={`${styles["categories__catalogue-top"]} 
                        ${
													isActive && searchCategory
														? `${styles["categories__catalogue-top--selected"]}`
														: `${styles["categories__catalogue-top--clear"]}`
												}`}
		>
			<span className={styles["categories__catalogue-title"]}>{t("results:facets.catalogue")}</span>
			<span className={styles["categories__catalogue-selected"]}>
				{t("results:facets.selectedCatalogue")}:
				<span className={styles["categories__catalogue-selected--span"]}>
					{t(`results:facets.fields.${searchCategory}`)}
				</span>
			</span>
			<Button
				variant="link"
				onClick={() => resetCategory()}
				disabled={!searchCategory}
				className={styles["categories__catalogue-button"]}
			>
				{t("results:facets.clear")}
			</Button>
		</div>
	);
};

export default CategoriesInfo;
