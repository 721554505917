import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import wordpressApi from "services/wordpress";

import Loader from "@/components/Loader/Loader";
import Error from "@/components/error/Error.js";

import useSessionState from "scripts/useSessionState";

import styles from "./StaticPage.module.sass";

/**
 * Tabbed static page
 * @memberof Containers
 * @function TabbedStaticPage
 */

const TabbedStaticPage = ({ slug }) => {
	const {
		i18n: { language },
	} = useTranslation();

	let currentLanguage = language;
	let currentSlug = slug;

	const parts = slug.split("-");
	if (parts.length > 1) {
		currentLanguage = parts[0];
		currentSlug = parts[1];
	}

	const [loading, setLoading] = useState(true);
	const [record, setRecord] = useSessionState(`${currentLanguage}-${currentSlug}`, {});
	const [error, setError] = useState(false);

	const getStaticPage = () => {
		setLoading(true);

		wordpressApi
			.get(`wp/v2/pages?slug=${currentLanguage}-${currentSlug}`)
			.then(({ data }) => {
				setLoading(false);
				if (data.length > 0) {
					setRecord(data[0]);
				} else {
					setLoading(true);

					if (language !== "pl") {
						wordpressApi.get(`wp/v2/pages?slug=pl-${currentSlug}`).then(({ data }) => {
							setLoading(false);
							if (data.length > 0) {
								setRecord(data[0]);
							} else {
								setError(true);
							}
						});
					} else {
						setLoading(false);
						setError(true);
					}
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getStaticPage();
	}, [language, slug]);

	if (loading) return <Loader />;

	if (error) return <Error />;

	if (Object.keys(record).length === 0 && record.constructor === Object) return <Loader />;

	return (
		<div className={styles["staticPage"]}>
			<div className={`${styles["staticPage__container"]}`}>
				<div className={styles["staticPage__content"]}>{ReactHtmlParser(record.content?.rendered)}</div>
			</div>
		</div>
	);
};

export default TabbedStaticPage;
