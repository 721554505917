import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import url from "router/urls";

import styles from "./Hero.module.sass";

import heroBg from "../../../assets/images/hero/home.png";

/**
 * Hero (homepage)
 * @memberof Components
 * @function Hero
 */

const Hero = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	let currentLanguage = language;
	return (
		<div className={styles["hero"]}>
			<div className={styles["hero__background"]}>
				<div></div>
			</div>
			<div className={` ${styles["hero__container"]} container`}>
				<div className={styles["hero__blocks"]}>
					<div className={styles["hero__block"]}>
						<h1 className={styles["hero__text--title"]}>{t("home:hero.title")}</h1>
						<img src={heroBg} className={styles["hero__image"]} alt="Obrazek Kulturalna strona Śląska" />
						<p className={styles["hero__text--content"]}>{t("home:hero.content")}</p>
						<hr className={styles["hero__hr"]} />
						<div className={styles["hero__more"]}>
							<Link to={url.aboutPage + "/" + currentLanguage + "-projectinfo"} className={styles["hero__text--more"]}>
								{t("home:hero.button")}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
