import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Breadcrumbs.module.sass";

/**
 * @namespace Components
 */

/**
 * Breadcrumbs
 * @memberof Components
 * @function Breadcrumbs
 */

const Breadcrumbs = ({ path }) => {
	const { t } = useTranslation();

	const cutName = name => {
		const nameLength = 30;
		return `${name.substring(0, nameLength)}${name.toString().length > nameLength ? "..." : ""}`;
	};

	return (
		<div className={`${styles["breadcrumbs"]}`}>
			<ul className={`${styles["breadcrumbs__list"]}`}>
				<li className={`${styles["breadcrumbs__item"]}`}>
					<Link to={"/"}>{t("breadcrumbs.home")}</Link>
				</li>
				{path?.map(item => {
					if (item.url)
						return (
							<li key={item.name} className={`${styles["breadcrumbs__item"]}`}>
								<Link to={item.url}>{cutName(item.name)}</Link>
							</li>
						);

					return (
						<li key={item.name} className={`${styles["breadcrumbs__item"]}`}>
							<span>{cutName(item.name)}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

Breadcrumbs.propTypes = {
	path: PropTypes.any,
};

export default Breadcrumbs;
