import React from "react";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import url from "router/urls";
import styles from "./Footer.module.sass";

/**
 * Wordpress footer box (layout)
 * @memberof Components
 * @function WordpressFooterBox
 */

const WordpressFooterBox = ({ menu, title, isAbout = false }) => {
	function hasChildren(prev, current) {
		return prev || !!current.child_items;
	}
	const page = isAbout ? url.about : url.page;
	let nestedMenus = false;
	if (menu?.items) {
		nestedMenus = menu.items.reduce(hasChildren, false);
	}

	return (
		<>
			{nestedMenus &&
				menu?.items &&
				menu.items.map((item, i) => (
					<React.Fragment key={i}>
						{item.child_items ? (
							<div className={styles["footer__menu--box"]}>
								<h2 className={styles["footer__text--title"]}>{item.title}</h2>
								<ul className={styles["footer__menu--list"]}>
									{item.child_items &&
										item.child_items.map((child, i) => (
											<li key={i} className={styles["footer__menu--item"]}>
												<Link to={reverse(page, { slug: child.slug.slice(3) })} className={styles["footer__text--menu"]}>
													{child.title}
												</Link>
											</li>
										))}
								</ul>
							</div>
						) : (
							<ul className={styles["footer__menu--list"]}>
								{" "}
								{item.title}
								<li className={styles["footer__menu--item"]}>
									<Link to={reverse(page, { slug: item.slug.slice(3) })} className={styles["footer__text--menu"]}>
										{item.title}
									</Link>
								</li>
							</ul>
						)}
					</React.Fragment>
				))}
			{!nestedMenus && menu?.items && (
				<div className={styles["footer__menu--box"]}>
					<h2 className={styles["footer__text--title"]}>{title}</h2>
					<ul className={styles["footer__menu--list"]}>
						{menu.items &&
							menu.items.map((child, i) => (
								<li key={i} className={styles["footer__menu--item"]}>
									<Link to={reverse(page, { slug: child.slug.slice(3) })} className={styles["footer__text--menu"]}>
										{child.title}
									</Link>
								</li>
							))}
					</ul>
				</div>
			)}
		</>
	);
};
export default WordpressFooterBox;
