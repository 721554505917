import React from "react";
import { Field } from "redux-form";

import Checkbox from "./fields/Checkbox";
import DateInput from "./fields/DateInput";

import styles from "./FacetFormDateFields.module.sass";
import { Select, Stack, MenuItem } from "@mui/material";

import { useTranslation } from "react-i18next";

const SelectField = ({ input, era }) => {
	const { t } = useTranslation();

	return (
		<Select
			{...input}
			className={styles["date__operator"]}
			variant="standard"
			id={`dropdown-${era}-date`}
			displayEmpty
			value={input.value}
			sx={{ pt: { xs: "5px", sm: "0" }, pb: { xs: "25px", sm: "0" } }}
			label={`dropdown-${era}-date`}
			onChange={newValue => {
				input.onChange(newValue.target.value);
			}}
		>
			<MenuItem value="ad">{t("search:dateEra.ad")}</MenuItem>
			<MenuItem value="bc">{t("search:dateEra.bc")}</MenuItem>
		</Select>
	);
};

/**
 * Facet date form fields component
 * @memberof Components
 * @function FacetFormDateFields
 */

const FacetFormDateFields = ({ missingValues, error, changeMissingTime, activeMissingTime }) => {
	const { t } = useTranslation();

	return (
		<div className={""}>
			{missingValues?.time > 0 && (
				<div>
					<div className={styles["date__container"]}>
						<Field
							name={`timeline`}
							component={Checkbox}
							type={`checkbox`}
							label={t("results:facets.dateFacet.timeline")}
							count={missingValues.time}
							actionChange={changeMissingTime}
						/>
					</div>
					<div className={!activeMissingTime ? styles["date__shadow"] : ""}>
						<div className={styles["date__container"]}>{t("results:facets.dateFacet.dateRange")}:</div>
					</div>
				</div>
			)}

			<div className={!activeMissingTime ? styles["date__shadow"] : ""}>
				<Stack
					direction={"column"}
					justifyContent="flex-start"
					alignItems="left"
					spacing={1}
					sx={{
						marginBottom: 1,
					}}
				>
					<div className={styles["date__container"]}>
						<label>
							<h5>{t("results:facets.dateFacet.dateFrom")}</h5>

							<Field
								// onChange={val => {
								// 	if (val) {
								// 		if (moment.isMoment(val)) {
								// 			const newYearFrom = val.year();
								// 			setYearRange([newYearFrom, yearRange[1]]);
								// 		} else if (val instanceof Date) {
								// 			const newYearFrom = val.getFullYear();
								// 			setYearRange([newYearFrom, yearRange[1]]);
								// 		}
								// 	}
								// }}
								name={`from`}
								label={t("results:facets.dateFacet.dateFrom")}
								placeholder={t("results:facets.dateFacet.dateFrom")}
								component={DateInput}
							/>
						</label>
						<label>
							<h5>{`fromEra`}</h5>
							<Field name={`fromEra`} component={SelectField} era={"from"} />
						</label>
					</div>
					<div className={styles["date__container"]}>
						<label>
							<h5>{t("results:facets.dateFacet.dateTo")}</h5>
							<Field
								// onChange={val => {
								// 	if (val) {
								// 		if (moment.isMoment(val)) {
								// 			const newYearTo = val.year();
								// 			setYearRange([yearRange[0], newYearTo]);
								// 		} else if (val instanceof Date) {
								// 			const newYearTo = val.getFullYear();
								// 			setYearRange([yearRange[0], newYearTo]);
								// 		}
								// 	}
								// }}
								name={`to`}
								type="text"
								label={t("results:facets.dateFacet.dateTo")}
								placeholder={t("results:facets.dateFacet.dateTo")}
								component={DateInput}
							/>
						</label>
						<label>
							<h5>{`toEra`}</h5>
							<Field name={`toEra`} component={SelectField} era={"to"} />
						</label>
					</div>
				</Stack>
			</div>

			{error && (
				<div className={styles["date__container"]}>
					<span className={styles["date__error"]}>{t(`results:facets.dateFacet.errors.${error}`)}</span>
				</div>
			)}
		</div>
	);
};

export default FacetFormDateFields;
