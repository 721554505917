import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import ReactHtmlParser from "react-html-parser";
import { Button, Hidden } from "@mui/material";
import imageNotFound from "@/assets/icons/svg/image-not-found.svg";

import url from "router/urls";
import storage from "scripts/storage";

import PageSize from "@/components/ItemPerPage/ItemPerPage";
import Sort from "@/components/Sort/Sort";
import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";

import styles from "@/containers/Results/parts/ResultsList/ResultsList.module.sass";

import NotFound from "assets/icons/svg/not-found.svg";

import { createThumbnailUrl } from "scripts/utils";

const ResultsList = ({
	title,
	results,
	totalResultNum,
	highlights,
	setPageByNum,
	setPageSize,
	searchParams,
	totalPages,
	searchSort,
	searchSortOrder,
	setSort,
	searchFacets,
	removeFunction,
	itemsList,
}) => {
	const { t } = useTranslation();

	const metadataDefaultList = ["dcCreator", "dcType", "dctermsCreated", "dctermsIssued"];
	const metadataArray = [
		...metadataDefaultList,
		...searchFacets.map(x => Object.keys(x)[0].substring(2)).filter(x => !metadataDefaultList.includes(x)),
	];
	const metadataStaticValues = ["techCanIUseIt", "bsaSimpleType", "techObjectAccessibility", "techType"];

	useEffect(() => {
		let objectsIds = results.map(obj => obj.id);
		const from = window.location.pathname + window.location.search;
		storage.saveObject(from, objectsIds);
	}, [results]);

	const findHighlights = id => {
		let result = null;
		// eslint-disable-next-line no-prototype-builtins
		if (highlights?.hasOwnProperty(id) && highlights[id]?.body && highlights[id]?.body[0]) {
			result = highlights[id]?.body[0];
			if (result) {
				result = result.replaceAll("::HL_START::", '<span style="font-weight: 600">');
				result = result.replaceAll("::HL_END::", "</span>");
			}
		}
		return result;
	};

	const isDateValid = dateStr => {
		return !isNaN(new Date(dateStr));
	};

	if (!results.length)
		return (
			<div className={styles["results-list"]}>
				<div className={styles["results-list__header"]}>
					<div className={styles["results-list__heading"]}>
						<h2 className={styles["results-list__text--heading"]}>{t("results:list.title")}</h2>
					</div>
				</div>
				<div className={styles["results-list__empty"]}>
					<img src={NotFound} alt={t("results:list.empty")} />
					<div>
						<p>{t("results:list.empty")}</p>
						<small>{t("results:list.change")}</small>
					</div>
				</div>
			</div>
		);

	return (
		<div className={styles["results-list"]}>
			<div className={styles["results-list__header"]}>
				<div className={styles["results-list__heading"]}>
					<h2 className={styles["results-list__text--heading"]}>{t("results:list.title")}</h2>
				</div>
				<div className={styles["results-list__nav"]}>
					<Sort searchSort={searchSort} searchSortOrder={searchSortOrder} setSort={setSort} />
					<PageSize setPageSize={setPageSize} searchParams={searchParams} />
				</div>
				<div>
					<Pagination
						className={styles["results-list__pagination"]}
						setPageByNum={setPageByNum}
						page={searchParams.page}
						totalPages={totalPages}
					/>
				</div>
			</div>
			<ul className={styles["results-list__list"]}>
				{results.map((result, i) => (
					<li className={styles["results-list__item"]} key={result.id}>
						<div className={styles["results-list__pic"]}>
							<div className={styles["results-list__pic--img"]}>
								<img
									className={styles["results-list__pic--img"]}
									alt={result?.original?.dcTitle ? result?.original?.dcTitle.toString() : t("results:item.emptyTitle")}
									src={createThumbnailUrl(result.id, "smallest")}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = imageNotFound;
									}}
								/>
							</div>
						</div>
						<div className={styles["results-list__content"]}>
							<div className={styles["results-list__details"]}>
								<div className={styles["results-list__title"]}>
									<Link
										to={{
											pathname: reverse(url.details, { id: result.id }),
											state: { from: window.location.pathname + window.location.search },
										}}
									>
										<h3 className={styles["results-list__text--title"]}>
											{result?.original?.dcTitle ? ReactHtmlParser(result?.original?.dcTitle.toString()) : t("results:item.emptyTitle")}
											
										</h3>
									</Link>
								</div>
								<div className={styles["results-list__metadata"]}>
									{metadataArray.map((item, i) => {
										const metadataLabel = t(`metadata:fields.${item}`);
										// const metadataValue = result[item] && result[item].toString();

										const metadataValue =
											typeof result.original[item] === "string" ? result.original[item] : result.original[item] && result.original[item].join("; ");

										

										if (item === "dctermsIssued" && result.original["dctermsCreated"] && result.original["dctermsIssued"]) {
											return false;
										}

										if (item === "techSourceName") {
											return false;
										}

										if (metadataValue)
											return (
												<div className={styles["results-list__metadata--item"]} key={i}>
													<span className={styles["results-list__text--metadata"]}>
														{metadataLabel}:{" "}
														<strong>
															{metadataStaticValues.includes(item)
																? t(`metadata:${item}.${metadataValue}`)
																: isDateValid(metadataValue)
																? new Date(metadataValue).toLocaleDateString()
																: metadataValue}
														</strong>
													</span>
												</div>
											);

										return false;
									})}
								</div>
								<div className={styles["results-list__description"]}>
									<div className={styles["results-list__text--description"]}>
										{ReactHtmlParser(findHighlights(result.id))}
									</div>
								</div>
								<Hidden mdUp>
									<div className={styles["results-list__more--mobile"]}>
										{removeFunction && (
											<Button
												variant="link"
												onClick={() => removeFunction(itemsList[i].id)}
												href="#"
												sx={{ padding: "12px", whiteSpace: "nowrap", mr: "20px" }}
											>
												{t("details:collections.deleteFromCollection")}
											</Button>
										)}
										<Button
											variant="primary"
											component={Link}
											to={{
												pathname: reverse(url.details, { id: result.id }),
												state: { from: window.location.pathname + window.location.search },
											}}
											sx={{
												width: 174,
												"@media (max-width: 991.98px)": {
													width: "100%",
												},
											}}
										>
											{t("results:item.more")}
										</Button>
									</div>
								</Hidden>
							</div>
						</div>
						<Hidden mdDown>
							<div className={styles["results-list__more"]}>
								{removeFunction && (
									<Button
										variant="link"
										onClick={() => removeFunction(itemsList[i].id)}
										href="#"
										sx={{ padding: "12px", whiteSpace: "nowrap", mr: "20px" }}
									>
										{t("details:collections.deleteFromCollection")}
									</Button>
								)}
								<Button
									variant="primary"
									component={Link}
									to={{
										pathname: reverse(url.details, { id: result.id }),
										state: { from: window.location.pathname + window.location.search, title: title },
									}}
									sx={{
										width: 174,
										"@media (max-width: 991.98px)": {
											width: "100%",
										},
									}}
								>
									{t("results:item.more")}
								</Button>
							</div>
						</Hidden>
					</li>
				))}
			</ul>
			<div className={styles["results-list__footer"]}>
				<div>
					<PaginationList setPageByNum={setPageByNum} searchParams={searchParams} totalPages={totalPages} />
				</div>
			</div>
		</div>
	);
};

ResultsList.propTypes = {
	title: PropTypes.string,
	results: PropTypes.any,
};

export default ResultsList;
