import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "@/containers/Results/ResultsSidebar/facets/Facet/FacetForm/FacetFormDateFields/FacetFormDateFields.module.sass";

import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IMaskInput, IMask } from "react-imask";
import moment from "moment/moment";
import { reduxForm, formValueSelector } from "redux-form";

/**
 * input date
 * @memberof Components
 * @function DateInput
 */

const DateInput = ({ input, label, placeholder, meta, value }) => {
	const { t } = useTranslation();
	const today = moment();

	const [maskValue, setMaskValue] = useState();
	const [dataPickerValue, setDataPickerValue] = useState();

	const mask = {
		d: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 31,
			maxLength: 2,
		},
		m: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 12,
			maxLength: 2,
		},
		Y: {
			mask: IMask.MaskedRange,
			from: parseInt("0000"),
			to: 9999,
		},
	};

	useEffect(() => {
		if (input.value) {
			moment.isMoment(input.value) ? "" : setMaskValue(input.value);
			moment.isMoment(input.value)
				? setDataPickerValue(input.value)
				: setDataPickerValue(moment(input.value, "DD/MM/YYYY"));
		}
	}, [input.value]);

	const min = moment([1, 0, 1]);
	const max = today;

	// const maskValue = moment(input.value).format("DD/MM/YYYY");

	return (
		<div>
			<p>{label}</p>
			<div className={styles["date__picker"]}>
				<DatePicker
					{...input}
					label={label}
					value={dataPickerValue}
					placeholder={placeholder}
					onChange={newValue => {
						if (newValue) {
							setMaskValue(moment(newValue).format("DD/MM/YYYY"));
							setDataPickerValue(newValue);
							input.onChange(newValue);
						}
					}}
					minDate={min}
					maxDate={max}
					renderInput={({ inputRef, InputProps }) => (
						<div className={styles["date__input--container"]}>
							{/* <TextField
								onChange={e => {
									input.onChange(e);
								}}
							/> */}
							<IMaskInput
								{...InputProps}
								mask={"d/m/Y"}
								pattern={"d.m.Y"}
								blocks={mask}
								value={maskValue}
								radix="/"
								unmask={false} // true|false|'typed'
								inputRef={inputRef}
								onAccept={
									// depending on prop above first argument is
									// `value` if `unmask=false`,
									// `unmaskedValue` if `unmask=true`,
									// `typedValue` if `unmask='typed'`
									(value, mask) => {
										if (value) {
											value.length === 10 && setDataPickerValue(moment(value, "DD/MM/YYYY"));
											value.length === 10 && input.onChange(moment(value, "DD/MM/YYYY"));
											setMaskValue(value);
										} else {
											setMaskValue("");
											input.onChange("");
										}
									}
								} // access to nested input
								// DO NOT USE onChange TO HANDLE CHANGES!
								// USE onAccept INSTEAD
								className={styles["date__input"]}
								placeholder="DD/MM/RRRR"
							/>
							{InputProps?.endAdornment}
						</div>
					)}
				/>
				<div className={styles["date__error"]}>{meta?.error ? t(meta?.error) : null}</div>
			</div>
		</div>
	);
};

export default DateInput;
