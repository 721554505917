import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { showNotification } from "@/actions";
import { useDispatch } from "react-redux";

import api from "services/api";
import ConfirmModal from "@/components/modals/ConfirmModal";
import SubscribeTopicModal from "@/components/modals/SubscribeTopicModal";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import useToken from "@/components/hooks/useToken";
import queryString from 'query-string';

import styles from "./MyTopicsList.module.sass";

/**
 * My topics list item (private route)
 * @memberof Containers
 * @function MyTopicsListItem
 */

export const MyTopicsListItem = props => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { bearer, userId } = useToken();

	const [confirmDeleteModalOpen, setConfirmDeleteOpenModal] = useState(false);
	const [subscribeModalOpen, setSubscribeOpenModal] = useState(false);

	const getTimestamp = timestamp => {
		let date = new Date(timestamp);
		return (
			date.getDate().toString().padStart(2, "0") +
			"." +
			(date.getMonth() + 1).toString().padStart(2, "0") +
			"." +
			date.getFullYear()
		);
	};

	const extractParameters = query => {
		const searchedUrl = new URL(query);
		const params = queryString.parse(searchedUrl.search);

		const metadataStaticValues = [
			"techCanIUseIt",
			"bsaSimpleType",
			"techObjectAccessibility",
			"techType",
		];

		return (
			<>
				{ Object.keys(params).length && Object.keys(params).map((item, i) => {
					return (
						<>{item.startsWith('f.') && 
							<span>
								<span className={` ${styles["topicsList__item__parameters__info--param-name"]} `}>
									{t(`metadata:fields.${item.slice(2)}`)}:{" "}
								</span>
								<span className={` ${styles["topicsList__item__parameters__info--param-value"]} `}>

									{ typeof params[item] === 'object' ? (
										<>
											{ params[item].map((value, j) => (
												<>{metadataStaticValues.includes(item.slice(2))
													? t(`metadata:${item.slice(2)}.${value}`)
													: value}{params[item].length !== j + 1 && ", "}</>
											))}
											
										</>
									) : (
										<>
										{metadataStaticValues.includes(item.slice(2))
											? t(`metadata:${item.slice(2)}.${params[item]}`)
											: params[item]}
										</>
									)}
									
								</span>
								{"; "}
							</span>
						}</> 
					);
				})
				}
			</>
		);
	};

	const copyLink = link => {
		navigator.clipboard.writeText(link);
	};

	const deleteTopic = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.delete(`/users/${userId}/dynamic-collections/${props.id}`, config)
			.then(() => {
				window.location.reload(false);
			})
			.catch(() => {});
	};

	const closeConfirmDeleteModal = () => {
		setConfirmDeleteOpenModal(false);
	};

	const openConfirmDeleteModal = () => {
		setConfirmDeleteOpenModal(true);
	};

	const closeSubscribeOpenModal = () => {
		setSubscribeOpenModal(false);
	};

	const openSubscribeOpenModal = () => {
		setSubscribeOpenModal(true);
	};

	return (
		<div key={props.id} className={` ${styles["topicsList__item"]} `}>
			<ConfirmModal
				isOpen={confirmDeleteModalOpen}
				onClose={closeConfirmDeleteModal}
				message={t("topics:deleteTopicModal.description")}
				header={t("topics:deleteTopicModal.header")}
				action={deleteTopic}
			/>
			<SubscribeTopicModal isOpen={subscribeModalOpen} onClose={closeSubscribeOpenModal} topicId={props.id} />

			<div className={` ${styles["topicsList__item__link"]} `}>
				<a href={props.queryString} className={` ${styles["topicsList__item__link--link"]} `}>
					{t("topics:link")}: {props.queryString}
				</a>
				<Button
					variant="primary"
					className={`${styles["topicsList__item__link--copy-link"]}`}
					onClick={() => {
						dispatch(showNotification(t("topics:linkWasCopied")));
						copyLink(props.queryString);
					}}
				>
					{t("topics:copyLink")}
				</Button>
			</div>

			<div className={` ${styles["topicsList__item__parameters"]} `}>
				<div className={` ${styles["topicsList__item__parameters__info"]} `}>
					<p className={` ${styles["topicsList__item__parameters__info--creation-date"]} `}>
						<span className={`${styles["topicsList__item__parameters__info--param-name"]}`}>
							{t("topics:creationDate")}:{" "}
						</span>
						<span className={`${styles["topicsList__item__parameters__info--param-value"]}`}>
							{getTimestamp(props.creationTimestamp)}
						</span>
					</p>
					<p className={` ${styles["topicsList__item__parameters__info--params"]} `}>
						{extractParameters(props.queryString)}
					</p>
				</div>

				<div className={` ${styles["topicsList__item__parameters__actions"]} `}>
					{props.subscribed && (
						<span className={` ${styles["topicsList__item__parameters__actions--periodicity"]} `}>
							{t("topics:youAreSubscribing")}
							{props.subscriptionPeriodicity == "weekly"
								? t("topics:subscribeTopicModal.intervalEveryWeek")
								: props.subscriptionPeriodicity == "monthly"
								? t("topics:subscribeTopicModal.intervalEveryMonth")
								: t("topics:subscribeTopicModal.intervalEveryThreeMonths")}
						</span>
					)}
					<Button
						variant="link"
						className={`${styles["topicsList__item__parameters__actions--delete-topic"]}`}
						onClick={() => {
							openConfirmDeleteModal();
						}}
					>
						{t("topics:deleteTopic")}
					</Button>
					{props.subscribed && (
						<span className={` ${styles["topicsList__item__parameters__actions--periodicity--mobile"]} `}>
							{t("topics:youAreSubscribing")}
							{props.subscriptionPeriodicity == "weekly"
								? t("topics:subscribeTopicModal.intervalEveryWeek")
								: props.subscriptionPeriodicity == "monthly"
								? t("topics:subscribeTopicModal.intervalEveryMonth")
								: t("topics:subscribeTopicModal.intervalEveryThreeMonths")}
						</span>
					)}
					<Button
						variant={props.subscribed ? "secondary" : "primary"}
						className={`${styles["topicsList__item__parameters__actions--cancel-subscription"]}`}
						onClick={() => {
							props.subscribed ? props.cancelSubscription(props.id) : openSubscribeOpenModal();
						}}
					>
						{props.subscribed ? t("topics:cancelSubscription") : t("topics:subscribe")}
					</Button>
					<Button
						variant="primary"
						className={`${styles["topicsList__item__link--copy-link--mobile"]}`}
						onClick={() => {
							dispatch(showNotification(t("topics:linkWasCopied")));
							copyLink(props.queryString);
						}}
						sx={{
							"@media (min-width: 767.99px)": {
								display: "none",
							},
						}}
					>
						{t("topics:copyLink")}
					</Button>
				</div>
			</div>
		</div>
	);
};
