import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Results from "./../Results/Results";

import * as Actions from "@/actions";
import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

import recommendedImg from "@/assets/images/recommended.svg";
import styles from "./ResultsPages.module.sass";

import { WordpressAcfContext } from "@/contexts/WordpressAcfContext";

/**
 * Recomended results page
 * @memberof Containers
 * @function RecomendedResults
 */

const RecomendedResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
}) => {
	const { t } = useTranslation();
	const wordpressAcf = useContext(WordpressAcfContext);
	const [publications, setPublications] = useState(null);
	const [firstFetchingState, setFirstFetchingState] = useState(true);

	const idsQuery = `&ids=${publications?.map(el => el.id).join(",")}`;

	const endpoint = location.pathname === "/results" ? "/api/results" : "/api/list";

	const path = [
		{
			name: t("breadcrumbs.recomended"),
		},
	];

	useEffect(() => {
		if (wordpressAcf && wordpressAcf.value) {
			const pubs = wordpressAcf.value.recommendedObjects.split(",").map(x => {
				return { id: x };
			});
			setPublications(pubs);
		}
	}, [wordpressAcf]);

	useEffect(() => {
		if (!location || !publications) return;
		loadResults(location.search, searchParams, endpoint);
	}, [location]);

	useEffect(() => {
		if (publications && publications.find(e => !!e.dcTitle) == undefined) {
			loadResults(location.search, searchParams, endpoint);
			setFirstFetchingState(false);
		}
	}, [publications]);

	const loadResults = (searchLocation, searchParams, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams({
			...newSearchParams,
			params: {
				...newSearchParams.params,
				page: newSearchParams.params.page - 1,
			},
		});

		actions.loadResults(endpoint, `${searchUrl}${idsQuery}`);
	};

	return (
		<Results
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching || firstFetchingState}
			actions={actions}
			location={location}
			history={history}
			path={path}
		>
			<div className={styles["recomendedList__head"]}>
				<img src={recommendedImg} />
				<div className={styles["recomendedList__head__info"]}>
					<span className={styles["recomendedList__text--title"]}>{t("results:recommended.title")}</span>
					<p>{t("results:recommended.desc")}</p>
				</div>
			</div>
		</Results>
	);
};

RecomendedResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		results: state.results.results,
		facets: state.results.facets,
		category: state.results.category,
		count: state.results.count,
		links: state.results.links,
		isFetching: state.results.isFetching,
		searchParams: state.search.params,
		searchCategory: state.search["f.category"],
		searchSort: state.search.sortBy,
		searchSortOrder: state.search.sortOrder,
		searchFacets: state.search.facets,
		totalPages: state.search.totalPages,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecomendedResults));
