import moment from "moment";

/**
 * Local storage managment util
 *
 * @function
 */

const storage = (function () {
	function getBool(key, defaultValue = false) {
		defaultValue = !!defaultValue;
		const item = localStorage.getItem(key);
		if (!item) return defaultValue;
		const result = JSON.parse(item) === true;
		return result;
	}

	function getInt(key, defaultValue = 0) {
		defaultValue = parseInt(defaultValue);
		if (isNaN(defaultValue)) defaultValue = 0;
		const item = localStorage.getItem(key);
		if (!item) return defaultValue;
		const result = parseInt(item);
		if (isNaN(result)) return defaultValue;
		return result;
	}

	function getNumber(key, defaultValue = 0.0) {
		defaultValue = parseFloat(defaultValue);
		if (isNaN(defaultValue)) defaultValue = 0.0;
		const item = localStorage.getItem(key);
		if (!item) return defaultValue;
		const result = parseFloat(item);
		if (isNaN(result)) return defaultValue;
		return result;
	}

	function getString(key, defaultValue = "") {
		const result = localStorage.getItem(key);
		if (!result) return defaultValue;
		return result;
	}

	function save(key, value) {
		localStorage.setItem(key, value);
	}

	const TemporaryObject = function (value, expirationDate) {
		this.value = value;
		this.expirationDate = expirationDate;
	};

	function saveTemporary(key, value, expirationValue, timeUnit) {
		const expirationDate = moment().add(moment.duration(expirationValue, timeUnit)).toDate();
		const obj = new TemporaryObject(value, expirationDate);
		localStorage.setItem(key, JSON.stringify(obj));
	}

	function getTemporary(key, defaultValue) {
		const item = localStorage.getItem(key);
		if (!item) return defaultValue;
		const obj = JSON.parse(item);
		if (typeof obj === "object") {
			const now = new Date();
			const expired = new Date(obj.expirationDate);
			if (expired < now) return defaultValue;
			return obj.value;
		}
		return defaultValue;
	}

	function saveObject(key, obj) {
		localStorage.setItem(key, JSON.stringify(obj));
	}

	function getObject(key, defaultValue) {
		const item = localStorage.getItem(key);
		if (!item) return defaultValue;
		return JSON.parse(item);
	}

	return {
		getBool: getBool,
		getInt: getInt,
		getNumber: getNumber,
		getString: getString,
		saveTemporary: saveTemporary,
		getTemporary: getTemporary,
		save: save,
		saveObject: saveObject,
		getObject: getObject,
	};
})();

export default storage;
