/**
 * debug
 * @function
 */

const debug = data => {
	if (isDevelopment()) {
		console.info(SafeStringify(data));
	}
};

const SafeStringify = (obj, indent = 2) => {
	let cache = [];
	let result = JSON.stringify(
		obj,
		function (_key, value) {
			if (typeof value === "object" && value !== null) {
				if (cache.indexOf(value) !== -1) {
					return;
				}
				cache.push(value);
			}
			return value;
		},
		indent
	);
	cache = null;
	return result;
};

export default debug;

export const isDevelopment = () => process.env.NODE_ENV === "development";
