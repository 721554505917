import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Select, MenuItem, Checkbox, Button } from "@mui/material";
import Loader from "@/components/Loader/Loader";
import api from "services/api";

import styles from "./ContactForm.module.sass";

/**
 * Contact form
 * @memberof Components
 * @function ContactForm
 */

const ContactForm = () => {
	const { t, i18n } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm();

	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);

	const onSubmit = data => {
		delete data.accept;

		setLoading(true);

		api
			.post(`/contact-us/send`, data)
			.then(() => {
				setLoading(false);
				setSent(true);
				reset();
			})
			.catch(() => {
				setLoading(false);
				setSent(false);
				reset();
			});
	};

	const subjectList = [
		{
			key: 0,
			value: t("contactform.select.select1"),
		},
		{
			key: 1,
			value: t("contactform.select.select2"),
		},
	];

	if (loading)
		return (
			<div className={`${styles["contactform"]} ${styles["contactform--loading"]}`}>
				<div>
					<Loader />
				</div>
			</div>
		);

	return (
		<div className={`${styles["contactform"]}`}>
			<div className={styles["contactform__text--title"]}>{t("contactform.title")}</div>

			{sent && <div className={styles["contactform__sent"]}>{t("contactform.sent")}</div>}

			<form className={styles["contactform__form"]} onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="replyTo" className={styles["contactform__row"]}>
					<div className={styles["contactform__label"]}>
						<span>{t("contactform.labels.email")}</span> *
					</div>
					<div className={styles["contactform__field"]}>
						<input
							className={`${styles["contactform__input"]} ${styles["contactform__input--small"]}`}
							id="replyTo"
							{...register("replyTo", { required: true })}
						/>
					</div>
					<div className={styles["contactform__error"]}>
						{errors.replyTo && <span>{t("contactform.required")}</span>}
					</div>
				</label>
				<label htmlFor="title" className={styles["contactform__row"]}>
					<div className={styles["contactform__label"]}>
						<span>{t("contactform.labels.subject")}</span> *
					</div>
					<div className={styles["contactform__field"]}>
						<Controller
							control={control}
							name="title"
							value={subjectList[0].value}
							render={({ field }) => (
								<Select
									className={styles["contactform__select"]}
									id="title"
									{...field}
									defaultValue={subjectList[0].value}
								>
									{subjectList.map(subject => (
										<MenuItem key={subject.key} value={subject.value}>
											{subject.value}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</div>
					<div className={styles["contactform__error"]}>{errors.title && <span>{t("contactform.required")}</span>}</div>
				</label>
				<label htmlFor="body" className={styles["contactform__row"]}>
					<div className={styles["contactform__label"]}>
						<span>{t("contactform.labels.message")}</span> *
					</div>
					<div className={styles["contactform__field"]}>
						<input className={styles["contactform__input"]} id="body" {...register("body", { required: true })} />
					</div>
					<div className={styles["contactform__error"]}>{errors.body && <span>{t("contactform.required")}</span>}</div>
				</label>
				<div className={styles["contactform__accept"]}>
					<div>
						<Controller
							control={control}
							name="accept"
							rules={{ required: true }}
							render={({ field }) => <Checkbox className="checkbox--light" id="accept" {...field} />}
						/>
					</div>
					<div>
						*{" "}
						<span>
							{t("contactform.accept1")}{" "}
							<Link to={`/page/${i18n.language}-privacypolicy`}>{t("contactform.accept2")}</Link>{" "}
							{t("contactform.accept3")}
						</span>
					</div>
				</div>
				<div className={styles["contactform__error"]}>{errors.accept && <span>{t("contactform.required")}</span>}</div>
				<div className={styles["contactform__notice"]}>
					<span>* {t("contactform.notice")}</span>
				</div>
				<div className={styles["contactform__buttons"]}>
					<Button type="reset" onClick={() => reset()} variant="secondary">
						{t("contactform.cancel")}
					</Button>
					<Button type="submit" variant="primary">
						{t("contactform.submit")}
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
