import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import Pagination from "@/components/Pagination/Pagination";
import PaginationList from "@/components/Pagination/PaginationList";

import api from "services/api";

import url from "router/urls";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Loader from "@/components/Loader/Loader";
import SearchMain from "@/components/Search/SearchMain";

import { Button } from "@mui/material";
import collectionsImg from "@/assets/images/collections.svg";

import styles from "./MyCollectionsList.module.sass";
import ConfirmModal from "@/components/modals/ConfirmModal";
import EditCollectionModal from "@/components/modals/EditCollectionModal";
import CreateCollectionModal from "@/components/modals/CreateCollectionModal";
import useToken from "@/components/hooks/useToken";
import { formatDate } from "scripts/utils";

/**
 * My collections list (private route)
 * @memberof Containers
 * @function MyCollectionsList
 */

const MyCollectionsList = () => {
	const { t } = useTranslation();
	const { bearer, userId } = useToken();
	const pageSize = 20;
	const [loading, setLoading] = useState(true);
	const [collectionCount, setCollectionCount] = useState(0);
	const [pagesCount, setPagesCount] = useState(0);
	const [page, setPage] = useState(1);
	const [collectionsList, setCollectionsList] = useState([]);
	const [modalConfirmIsOpen, setConfirmIsOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const breadcrumbs = [
		{ name: t("header:account.myAccount"), url: url.account },
		{ name: t("header:menu.collections.items.myCollections") },
	];

	const getCollectionsList = () => {
		if (bearer) {
			const config = {
				headers: {
					Authorization: "Bearer " + bearer,
				},
			};
			api
				.get(`/users/${userId}/static-collections?page=${page - 1}&pageSize=${pageSize}`, config)
				.then(response => {
					setLoading(false);
					if (+response.status == 204) {
						setCollectionCount(0);
						setPagesCount(0);
						setCollectionsList([]);
					} else {
						const pageCount =
							response.data.totalCount % pageSize === 0
								? Math.floor(response.data.totalCount / pageSize)
								: Math.floor(response.data.totalCount / pageSize) + 1;
						setCollectionCount(response.data.totalCount);
						setPagesCount(pageCount);
						setPage(page);
						setCollectionsList(response.data.collections);
					}
				})
				.catch(() => {
					setLoading(false);
				});
		} else setLoading(false);
	};

	useEffect(() => {
		getCollectionsList();
	}, [page, bearer]);

	if (loading) return <Loader />;

	const setPageByNum = page => {
		setPage(page);
	};

	const openDeleteConfirm = id => {
		setConfirmIsOpen(true);
		setSelectedItem(id);
	};
	const handleDelete = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};
		api
			.delete(`/users/${userId}/static-collections/${selectedItem}`, config)
			.then(() => {
				getCollectionsList();
			})
			.catch(() => {});
	};

	const closeConfirnModal = () => {
		setConfirmIsOpen(false);
	};

	const handleEdit = () => {
		getCollectionsList();
	};

	const collectionCreated = () => {
		getCollectionsList();
	};

	return (
		<div className={styles["collectionsList"]}>
			<ConfirmModal
				isOpen={modalConfirmIsOpen}
				onClose={closeConfirnModal}
				message={t("details:collections.areyousure")}
				header={t("details:collections.delete")}
				action={handleDelete}
			/>
			<SearchMain className={styles["collectionsList__search"]} />
			<div className={`${styles["collectionsList__container"]} container`}>
				<div className={styles["collectionsList__breadcrumbs"]}>
					<Breadcrumbs path={breadcrumbs} />
				</div>

				<div className={styles["collectionsList__head"]}>
					<div className={styles["collectionsList__head--button"]}>
						<CreateCollectionModal
							uid={userId}
							onCreate={collectionCreated}
							buttonVariant="primary"
							buttonText={t("details:collections.create")}
						/>
					</div>
					<h1 className={styles["collectionsList__text--title"]}>
						<img src={collectionsImg} alt="collections" />
						<div>
							<span>{t("header:menu.collections.items.myCollections")}</span>
							<span> {`(${collectionCount})`}</span>
						</div>
					</h1>
				</div>

				{pagesCount > 0 && (
					<div className={styles["collectionsList__pagination"]}>
						<Pagination setPageByNum={setPageByNum} page={page} totalPages={pagesCount} />
					</div>
				)}

				<div className={styles["collectionsList__content"]}>
					{collectionsList.length ? (
						<>
							{collectionsList.map(item => (
								<div
									key={item.id}
									className={` ${styles["collectionsList__item"]} ${styles["collectionsList__item--short"]} `}
								>
									<div className={styles["collectionsList__item--desc"]}>
										<div className={styles["collectionsList__item--header"]}>
											<span className={styles["collectionsList__text--counter"]}>
												{t("details:collections.objects")} {item.itemsCount}
											</span>
											<h3 className={styles["collectionsList__text--head"]}>{item.name}</h3>
											<p className={styles["collectionsList__text--date"]}>
												{t("home:news.item.date")}: {formatDate(item.creationTimestamp)}
											</p>
										</div>

										<div className={styles["collectionsList__item--content"]}>{item.description}</div>
										<div className={styles["collectionsList__item--footer"]}>
											<Button variant="link" onClick={() => openDeleteConfirm(item.id)}>
												{t("details:collections.delete")}
											</Button>
											<EditCollectionModal
												uid={userId}
												id={item.id}
												collName={item.name}
												collDesc={item.description}
												onOk={handleEdit}
											/>
											<Button
												variant="secondary"
												component={Link}
												to={reverse(url.collections.myItem, { id: item.id })}
												className={styles["collectionsList__show-more"]}
											>
												{t("details:seeCollection")}
											</Button>
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<>{t("details:collections.none")}</>
					)}
				</div>
				{pagesCount > 0 && (
					<div className={styles["collectionsList__pagination"]}>
						<PaginationList setPageByNum={setPageByNum} searchParams={{ page: page }} totalPages={pagesCount} />
					</div>
				)}
			</div>
		</div>
	);
};

export default MyCollectionsList;
