import React from "react";

import styles from "./Categories.module.sass";

const CategoriesFormField = ({ input, label, count }) => (
	<div className={styles["categories__catalogue-item--container"]}>
		<label className={styles["categories__catalogue-item--label"]}>
			<input type="radio" {...input} className={styles["categories__catalogue-item--input"]} />
			<span className={styles["categories__catalogue-item--text"]}>{label}</span>
		</label>
		<span className={styles["categories__catalogue-item--count"]}>
			{count.toLocaleString("en-US").replace(",", " ")}
		</span>
	</div>
);

export default CategoriesFormField;
