import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Box, Modal, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import api from "services/api";
import CreateCollectionModal from "./CreateCollectionModal";
import styles from "./Modals.module.sass";
import useToken from "@/components/hooks/useToken";

/**
 * Modal - add to collection
 * @memberof Components
 * @function AddToCollectionModal
 */

const AddToCollectionModal = ({ isOpen, onClose, id, uid }) => {
	const { t } = useTranslation();
	const isContrast = useSelector(state => state.app.isContrast);
	const { bearer, userId } = useToken();

	const [collections, setCollections] = useState([]);
	const [selectedColl, setSelectedColl] = useState(0);
	const [error, setError] = useState(false);

	// const customStyles = {
	//     content: {
	//       top: '50%',
	//       left: '50%',
	//       right: 'auto',
	//       bottom: 'auto',
	//       marginRight: '-50%',
	//       transform: 'translate(-50%, -50%)',
	//       padding: '40px 50px',
	//       width: '400px'
	//     },
	//     overlay: {
	//         background: "#FFFFFF",
	//         zIndex: 9999
	//     }
	//   };

	const getCollectionsList = () => {
		if (bearer) {
			const config = {
				headers: {
					Authorization: "Bearer " + bearer,
				},
			};
			api
				.get(`/users/${userId}/static-collections?page=0&pageSize=9999`, config)
				.then(response => {
					if (+response.status != 204) {
						setCollections(response.data.collections);
					}
				})
				.catch(() => {});
		}
	};

	useEffect(() => {
		getCollectionsList();
	}, [bearer]);

	useEffect(() => {
		if (isOpen) setSelectedColl(0);
	}, [isOpen]);

	const handleChange = event => {
		setSelectedColl(event.target.value);
		setError(false);
	};

	const collectionCreated = () => {
		getCollectionsList();
	};

	const addToCollection = () => {
		const config = {
			headers: {
				Authorization: "Bearer " + bearer,
			},
		};

		const url = `/users/${uid}/static-collections/${selectedColl}/items`;
		const data = {
			value: id,
		};

		api
			.post(url, data, config)
			.then(() => {
				onClose();
			})
			.catch(() => {
				setError(true);
			});
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: isContrast ? "#000" : "background.paper",
		border: isContrast ? "2px solid #FFF" : "2px solid #000",
		boxShadow: 0,
		padding: "40px 50px",
		width: "400px",
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					...style,
					"@media (max-width: 767.98px)": {
						width: "300px",
					},
				}}
			>
				<a href="#" className={styles["modal__btn--close"]}>
					<i className="icon-cancel" onClick={onClose}></i>
				</a>
				<h2 className={styles["modal__title"]}> {t("details:collections.addToCollection")}</h2>
				<form className={styles["modal__form"]}>
					<div>
						<label>{t("details:collections.selectCollection")}</label>
					</div>
					<Select
						className={styles["modal__select"]}
						variant="standard"
						id="dropdown-coll"
						defaultValue=""
						value={selectedColl}
						onChange={handleChange}
					>
						{collections.map((option, index) => (
							<MenuItem key={index} value={option.id}>
								{option.name}
							</MenuItem>
						))}
					</Select>
					{error && <p className={styles["modal__error"]}>{t("details:collections.addObjectError")}</p>}
					<div>
						<Button variant="primary" size="small" onClick={addToCollection} disabled={!selectedColl}>
							{t("details:collections.addObject")}
						</Button>
					</div>
				</form>
				<div className={styles["modal__addnew"]}>
					<CreateCollectionModal
						uid={uid}
						onCreate={collectionCreated}
						buttonVariant="link"
						buttonText={t("details:collections.createNew")}
					/>
				</div>
			</Box>
		</Modal>
	);
};

export default AddToCollectionModal;
