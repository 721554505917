import React, { useState } from "react";
import { Button, Menu } from "@mui/material";

/**
 * Dropdown menu
 * @memberof Components
 * @function MenuDropdown
 */

const MenuDropdown = ({ title, children }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant="nav"
			>
				{title}
				<i className={open ? "icon-arrow-up" : "icon-arrow-down"} />
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				disableScrollLock={true}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{children}
			</Menu>
		</>
	);
};
export default MenuDropdown;
