import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import api from "services/api";
import { changeUrlToRequest } from "@/scripts/parseUrl";

import Results from "./../Results/Results";
import WordpressContent from "@/components/WordpressContent/WordpressContent";

import * as Actions from "@/actions";
import { setSearchParams, setUrlFromParams } from "@/scripts/resultsService.js";

import topicsImg from "@/assets/images/topics.svg";
import styles from "./ResultsPages.module.sass";

import wordpressApi from "services/wordpress";

import { formatDate } from "scripts/utils";

import Loader from "@/components/Loader/Loader";

import moment from "moment/moment";

/**
 * Topics results page
 * @memberof Containers
 * @function TopicResults
 */

const TopicResults = ({
	results,
	searchParams,
	searchCategory,
	searchSort,
	searchSortOrder,
	searchFacets,
	totalPages,
	category,
	facets,
	count,
	links,
	isFetching,
	actions,
	location,
	history,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const [topicData, setTopicData] = useState(null);
	const [resultsCount, setResultsCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const params = useParams();

	const endpoint = location.pathname === "/recomended" ? "/api/list" : "/api/results";

	const getTimestamp = timestamp => {
		return moment(new Date(timestamp)).format(moment.localeData(language).longDateFormat("L"));
	};

	const title = topicData?.acf[`${language}_title`] || topicData?.title?.rendered || "";

	const path = [
		{
			name: t("breadcrumbs.topics"),
			url: "/allTopics",
		},
		{
			name: title,
		},
	];

	useEffect(() => {
		setLoading(true);
		wordpressApi
			.get(`wp/v2/topics/${params.id}?_embed`)
			.then(response => {
				setTopicData(response.data);
				setLoading(false);
				if (!location.search || location.search === "") {
				history.push(`${response.data.acf.ids}`);
				}
			})
			.catch(err => {
				console.error(err);
			});
	}, [params.id]);

	useEffect(() => {
		if (!location || location.search === "") return;
		loadResults(location.search, searchParams, actions, endpoint);
	}, [location]);

	useEffect(() => {
		if (!topicData) return;
		api
			.get(`/count?${changeUrlToRequest(topicData.acf.ids.replace(/^\?+/, ""))}`)
			.then(response => setResultsCount(response.data.count));
	}, [topicData]);

	const loadResults = (searchLocation, searchParams, actions, endpoint) => {
		const newSearchParams = setSearchParams(searchLocation, searchParams);

		actions.setSearchParams({ ...newSearchParams });

		const searchUrl = setUrlFromParams(
			{
				...newSearchParams,
				params: {
					...newSearchParams.params,
					page: newSearchParams.params.page - 1,
				},
			},
			true
		);

		actions.loadResults(endpoint, changeUrlToRequest(searchUrl));
	};

	if (loading) return <Loader />;

	return (
		<Results
			title={title}
			results={results}
			searchParams={searchParams}
			searchCategory={searchCategory}
			searchSort={searchSort}
			searchSortOrder={searchSortOrder}
			searchFacets={searchFacets}
			totalPages={totalPages}
			category={category}
			facets={facets}
			count={count}
			links={links}
			isFetching={isFetching}
			actions={actions}
			location={location}
			history={history}
			path={path}
		>
			{topicData && (
				<>
					<div className={styles["recomendedList__head"]}>
						<img src={topicsImg} />
						<div className={styles["recomendedList__head__info"]}>
							<span className={styles["recomendedList__text--title"]}>
								{language === "pl"
									? topicData.title.rendered
									: topicData.acf[`${language}_title`] || topicData.title.rendered}
							</span>
							<p>
								<Trans
									i18nKey={"details:topics.info"}
									components={{ span: <span className={styles["recomendedList__text--blue"]} /> }}
								/>
							</p>
						</div>
					</div>
					<div className={styles["recomendedList__banner"]}>
						{topicData._embedded["wp:featuredmedia"] && (
							<img
								className={styles["recomendedList__banner__logo"]}
								src={topicData._embedded["wp:featuredmedia"][0].source_url}
								alt=""
							/>
						)}
						<div className={styles["recomendedList__banner__content"]}>
							<div className={styles["recomendedList__banner__content__title"]}>
								<p className={styles["recomendedList__text--subtitle"]}>
									{language === "pl"
										? topicData.title.rendered
										: topicData.acf[`${language}_title`] || topicData.title.rendered}
								</p>
								<p className={styles["recomendedList__text--aside"]}>
									{t("details:topics.objects")}:{" "}
									<span className={styles["recomendedList__text--dark"]}>{resultsCount}</span>
								</p>
							</div>
							<p>
								{t("details:topics.creationDate")}: {formatDate(topicData.date, language)}
								<span className={styles["recomendedList__text--separator"]}>|</span> {t("details:topics.author")}:{" "}
								{topicData._embedded.author[0].description}
							</p>
							<div className={styles["recomendedList__banner__content__description"]}>
								<WordpressContent
									content={
										language === "pl"
											? topicData.content.rendered || topicData.excerpt.rendered
											: topicData.acf[`${language}_full`] || topicData.acf[`${language}_excerpt`]
									}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</Results>
	);
};

TopicResults.propTypes = {
	actions: PropTypes.object,
	location: PropTypes.object,
	searchParams: PropTypes.shape({
		q: PropTypes.string,
		page: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	isFetching: PropTypes.bool,
	searchCategory: PropTypes.string,
	searchFacets: PropTypes.arrayOf(PropTypes.object),
	totalPages: PropTypes.number,
	results: PropTypes.arrayOf(PropTypes.object),
	facets: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
	return {
		results: state.results.results,
		facets: state.results.facets,
		category: state.results.category,
		count: state.results.count,
		links: state.results.links,
		isFetching: state.results.isFetching,
		searchParams: state.search.params,
		searchCategory: state.search["f.category"],
		searchSort: state.search.sortBy,
		searchSortOrder: state.search.sortOrder,
		searchFacets: state.search.facets,
		totalPages: state.search.totalPages,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicResults));
