import React from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";
import { IMaskInput, IMask } from "react-imask";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./DatePickerField.module.sass";

/**
 * Date picker field
 * @memberof Components
 * @function DatePickerField
 */

const DatePickerField = ({ setDate, setEra, setInput, value, valueInput, valueEra }) => {
	const { t } = useTranslation();
	const today = moment();

	const mask = {
		d: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 31,
			maxLength: 2,
		},
		m: {
			mask: IMask.MaskedRange,
			from: 1,
			to: 12,
			maxLength: 2,
		},
		Y: {
			mask: IMask.MaskedRange,
			from: parseInt("0000"),
			to: 9999,
		},
	};

	const firstYearOfOurEra = moment([1, 0, 1]);
	const maxRange = today;

	return (
		<div className={styles["datepicker__input--container"]}>
			<DatePicker
				onChange={val => {
					if (val) {
						val.year() < 0 ? setEra("-") : setEra("");
						setDate(val);
						setInput(val.format("DD/MM/YYYY"));
					} else {
						setDate(val);
					}
				}}
				label={t("results:facets.dateFacet.dateTo")}
				minDate={firstYearOfOurEra}
				maxDate={maxRange}
				value={value}
				inputFormat="DD/MM/YYYY"
				renderInput={({ inputRef, InputProps }) => (
					<div className={styles["datepicker__data-input"]}>
						<IMaskInput
							mask={"d/m/Y"}
							pattern={"d/m/Y"}
							blocks={mask}
							radix="/"
							value={valueInput}
							unmask={false} // true|false|'typed'
							inputRef={inputRef} // access to nested input
							// DO NOT USE onChange TO HANDLE CHANGES!
							// USE onAccept INSTEAD
							className={`${styles["datepicker__input"]}`}
							onAccept={
								// depending on prop above first argument is
								// `value` if `unmask=false`,
								// `unmaskedValue` if `unmask=true`,
								// `typedValue` if `unmask='typed'`
								(value, mask) => {
									setDate(value);
									setInput(value);
									// change(value);
								}
							}
							// ...and more mask props in a guide

							// input props also available
							placeholder="DD/MM/RRRR"
						/>
						{InputProps?.endAdornment}
					</div>
				)}
			/>

			<Select
				className="js-advSearchOperator"
				variant="standard"
				id={`dropdown-start-date`}
				displayEmpty
				value={valueEra}
				sx={{ pt: { xs: "5px", sm: "0" }, pb: { xs: "25px", sm: "0" } }}
				onChange={e => setEra(e.target.value)}
				label={`dropdown-start-era`}
			>
				<MenuItem value="">{t("search:dateEra.ad")}</MenuItem>
				<MenuItem value="-">{t("search:dateEra.bc")}</MenuItem>
			</Select>
		</div>
	);
};

DatePickerField.propTypes = {};

export default DatePickerField;
