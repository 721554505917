const url_ariadnaBad = process.env.REACT_APP_MODULES_BAD;
const url_ariadnaEdu = process.env.REACT_APP_MODULES_EDU;
const url_ariadnaWol = process.env.REACT_APP_MODULES_WOL;

/**
 * 	@module router/urls
 *	@description
 * 	Router url declaration
 */

export default {
	home: "/",
	account: "/account",
	results: "/results",
	allObjects: "/all",
	allResults: "/results?q=%2A&scope=",
	last: "/last",
	lastAdded: "/results?q=%2A&sortBy=time&sortOrder=desc",
	details: "/details/:id",
	recomended: "/recomended",
	allRecomended: "/recomended?q=%2A&scope=",
	news: {
		list: "/news",
		item: "/news/:id",
	},

	collections: {
		my: "/myCollections",
		list: "/collections",
		item: "/collections/:id",
		myItem: "/myCollections/:id",
		sharedItem: "/sharedCollections/:id",
	},

	topics: {
		my: "/myTopics",
		all: "/allTopics",
		item: "/topics/:id",
	},

	page: "/page/:slug",
	aboutPage: "/about",
	about: "/about/:slug?",
	modules: {
		bad: "#",
		agr: "#",
		vol: "#",
	},
	bad: {
		myProjects: url_ariadnaBad + "/my-transcriptions",
		limits: url_ariadnaBad + "/admin/projects",
	},
	edu: {
		myProjects: url_ariadnaEdu + "/sciezki",
	},
	wol: {
		myProjects: url_ariadnaWol + "/my-account/my-projects",
	},
	userProfile: "/realms/BSA/account",
	userManagement: "/admin/BSA/console/#/realms/BSA/users",
	wordpressAdmin: "/wp-admin/",
	sitemap: "/sitemap",
};
