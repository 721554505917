import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";

// import Button from "@/components/button/Button.js";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import FacetFormItem from "./FacetFormItem";

import styles from "./FacetForm.module.sass";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/**
 * Facet advance form component
 * @memberof Components
 * @function FacetFormAdvence
 */

const FacetFormAdvence = ({
	// eslint-disable-next-line no-unused-vars
	isAllSelected,
	isAllUnselected,
	handleSubmit,
	// eslint-disable-next-line no-unused-vars
	selectAll,
	resetAll,
	// eslint-disable-next-line no-unused-vars
	changeVal,
	facetItem,
	pristine,
	submitting,
	isFacetSet,
	facetName,
	chosenFacet,
	// eslint-disable-next-line no-unused-vars
	setIsAdvenceFacetOpen,
	initialValues,
	initialize,
	sortingOption,
}) => {
	const isContrast = useSelector(state => state.app.isContrast);
	const [facetArr, setFacetArr] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [chosenList, setChosenList] = useState([]);

	const fillColor = isContrast ? "yellow" : "#005CA9";
	const adjurmentColor = isContrast ? "black" : "#FFFFFF";

	const { t } = useTranslation();

	useEffect(() => {
		facetItem.forEach(item => {
			setFacetArr(prev => [...prev, item]);
		});
	}, []);

	/***  Sorting mechanizm ***/

	useEffect(() => {
		if (sortingOption === "default") {
			setFacetArr([...facetItem]);
		}
		if (sortingOption === "alphabetic") {
			const tempArr = [...facetItem];
			tempArr.sort(compereFunc);
			setFacetArr([...tempArr]);
		}
	}, [sortingOption]);

	const compereFunc = (obj1, obj2) => {
		return obj1.name === obj2.name ? 0 : obj1.name > obj2.name ? 1 : -1;
	};

	/****************************************************************/

	useEffect(() => {
		if (!chosenFacet) return null;
		chosenFacet[`f.${facetName}`].map(value => {
			if (!facetArr) return null;
			facetArr.forEach(item => {
				if (item.name === value) {
					setChosenList(prev => [...prev, value]);
				}
			});
		});
	}, [chosenFacet, facetArr]);

	useEffect(() => {
		if (!initialValues) return null;
		initialize(initialValues);
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			{/* <div className={styles["form__head"]}>
				<FacetActions
					selectAll={selectAll}
					resetAll={resetAll}
					submitting={submitting}
					isAllSelected={isAllSelected}
					isAllUnselected={isAllUnselected}
				/>
			</div> */}
			<div className={styles["form__content"]} style={{ maxHeight: 1200 }}>
				<ul className={styles["form__list"]}>
					{facetArr.map(item => (
						<Field
							type="checkbox"
							name={item.name}
							component={FacetFormItem}
							label={item.label}
							count={item.value}
							key={item.label}

							// chosenFacet={chosenFacet}
							// facetName={facetName}
							// onClick={()=>{
							// 	changeFieldValue(facetName, item.name, !item.value)
							// 	console.log('onClick - działam!')
							// }}
						/>
					))}
				</ul>
			</div>
			<div className={styles["form__bottom"]}>
				<Button
					onClick={resetAll}
					disabled={(!isFacetSet && isAllUnselected && !pristine) || pristine || submitting}
					variant="contained"
					sx={{
						bgcolor: adjurmentColor,
						color: fillColor,
						fontSize: 16,
						border: 1,
						borderColor: fillColor,
						mr: 2,
						boxShadow: 0,
						"&:hover": {
							bgcolor: fillColor,
							color: adjurmentColor,
							boxShadow: 0,
						},
						"&.Mui-disabled": {
							color: "#7f7f7f",
							bgcolor: "#c0c0c0",
							borderColor: "#c0c0c0",
						},
					}}
				>
					{t("results:facets.clear")}
				</Button>
				<Button
					// className={styles["form__submit"]}
					type="submit"
					variant="contained"
					disabled={(!isFacetSet && isAllUnselected && !pristine) || pristine || submitting}
					sx={{
						bgcolor: fillColor,
						color: adjurmentColor,
						fontSize: 16,
						border: 1,
						borderColor: fillColor,
						boxShadow: 0,
						"&:hover": {
							bgcolor: adjurmentColor,
							color: fillColor,
							boxShadow: 0,
						},
						"&.Mui-disabled": {
							color: "#7f7f7f",
							bgcolor: "#c0c0c0",
							borderColor: "#c0c0c0",
						},
					}}
					// startIcon={<AddIcon />}
				>
					{t("results:facets.searchFacet.choose")}
				</Button>
			</div>
		</form>
	);
};

export default reduxForm({
	form: "facetFormAdvence",
	enableReinitialize: true,
	// onChange: (values, dispatch, props) => {
	// 	props.change(facet, )
	// }
})(FacetFormAdvence);
