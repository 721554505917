import React, { createContext } from "react";
import PropTypes from "prop-types";

/**
 * @namespace Context
 * @description Contexts
 */
export const AppContext = createContext();

/**
 *
 * Appliaction context
 *
 * @function AppContextProvider
 * @memberof Context
 *
 */

const AppContextProvider = props => {
	return <AppContext.Provider value={{ ...props }}>{props.children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
	children: PropTypes.any,
};

export default AppContextProvider;
