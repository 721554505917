import React from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";

import styles from "./WordpressContent.module.sass";

/**
 * Wordpress content component
 * @memberof Components
 * @function WordpressContent
 */

const WordpressContent = ({ content, className }) => {
	return <div className={[styles["container"], className].join(" ")}>{ReactHtmlParser(content)}</div>;
};

WordpressContent.propTypes = {
	content: PropTypes.string,
	className: PropTypes.string,
};

export default WordpressContent;
