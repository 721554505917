import React from "react";
import { useTranslation } from "react-i18next";

import styles from "@/components/Pagination/Pagination.module.sass";

/**
 * Pagination total pages information
 * @memberof Components
 * @function PaginationTotal
 */

const PaginationTotal = ({ totalPages, currentPage, setPageByNum }) => {
	const { t } = useTranslation();
	return (
		<div className={styles["pagination__total"]}>
			<span className={styles["pagination__total-label"]}>{t("pagination:from")}</span>
			<button
				className={`${styles["pagination__item-button"]} ${
					currentPage === totalPages ? styles["pagination__item-button--disabled"] : ""
				}`}
				onClick={() => setPageByNum(totalPages)}
				title={t("pagination:lastPage")}
			>
				{totalPages}
			</button>
		</div>
	);
};

export default PaginationTotal;
