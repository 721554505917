import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/Button.js";

import styles from "./Categories.module.sass";

const CategoriesHeader = ({ isActive, toggleActive }) => {
	const { t } = useTranslation();

	return (
		<div
			className={`${styles["categories__title-container"]} ${
				isActive ? `${styles["categories__title-container--hidden"]}` : ""
			}`}
		>
			<h3 className={styles["categories__title"]}>{t("results:facets.filters")}</h3>
			<Button
				onClick={() => toggleActive()}
				variant="link"
				className={`${styles["categories__catalogue-collapse"]} ${
					isActive ? `${styles["categories__catalogue-collapse--hidden"]}` : ""
				}`}
			>
				{isActive ? t("results:expand") : t("results:collapse")}
				<i className="icon-chevron-down"></i>
			</Button>
		</div>
	);
};

export default CategoriesHeader;
